import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom'
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import {
  Redirect
} from 'react-router-dom';
import cookie from 'react-cookies'

const styles = theme =>  ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    // paddingBottom: theme.spacing.unit * 8
  },
  card: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    borderRadius: "12px"
    // marginBottom: theme.spacing.unit * 8,
  },
  media: {
    height: 200,
    backgroundPosition: 'top',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      height: 350,
    },
  },
  logo: {
    width: "80%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  center:{
    textAlign: 'center',
    marginTop: 20,
    width: "100%"
  },
  social:{
    textAlign: 'center',
    marginTop: 40
  },
  footer:{
    textAlign: 'center',
    marginTop: 40,
    color: "#42859F",
    textTransform: "uppercase"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"90%",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"90%",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  linkButtom:{
    textDecoration: "none"
  }
});

function HomeScreen(props) {
  const { classes } = props;
  if(cookie.load('user')){
    return (<Redirect to="/training"/>)
  }
  else{
    return (
      <main className={classes.main}>
        <Card className={classes.card}>

            <CardMedia
              className={classes.media}
              image={imageHeader}
              title="Contemplative Reptile"
            />
            <CardContent>
              <img src={logo} alt="Logo" className={classes.logo}/>
              <div className={classes.center}>



                  <Link to="/register" className={classes.linkButtom}>
                    <Fab variant="extended" aria-label="Add" className={classes.buttonSecond}>
                    S'INSCRIRE
                    </Fab>
                  </Link>

              </div>
              <div className={classes.center}>
                <Link to="/login" className={classes.linkButtom}>
                  <Fab variant="extended" aria-label="Add" className={classes.button}>
                    SE CONNECTER
                  </Fab>
                </Link>
              </div>

              <div className={classes.social}>

                <SocialIcon network="instagram" bgColor="#723AB8" className={classes.margin} />
                <SocialIcon network="facebook" bgColor="#3B5998" className={classes.margin} />
                <SocialIcon network="youtube" bgColor="#E93F33" className={classes.margin}/>
              </div>

              <div className={classes.footer}>
               Eduquer - Proteger - Financer - Inspirer
              </div>
         </CardContent>
      </Card>
        </main>
    ); 
  }
  
}

HomeScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeScreen);
