import ApiService from '../../Shared/api.service';
class ChatService {
    getUsers(data) {
        return ApiService.post('users', data)
    }
        getUser(id) {
        return ApiService.get('user', id)
    }
    getGroup()
    {
    	let data = {
		}
    	return ApiService.post('groups',{"price": 150} )
    }
}

export default new ChatService();
