import React from 'react';
import PropTypes from 'prop-types';
import WebhookService from './webhook.service';
class Webhook extends React.Component {

    ///props_types
    static propTypes = {
        classes: PropTypes.object.isRequired,
        state: PropTypes.object,
      }

      constructor(props) {
        super(props);
        this.classes =  props.classes;
        this.state = {
          dataSource : [],
          previous : ""
        }
      }
      user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

	async getStatus(id) {
     try{
       const responseJson = await WebhookService.get(id);
       console.log(responseJson);
       if(responseJson){
         this.setState({
           dataSource: responseJson,
         }, function(){
         });
       }
     } catch (e) {
       console.error(e);
     }}


      async componentDidMount(){
      if(this.props.match.params.id)
      {
        try{
       const responseJson = await this.getStatus(this.props.match.params.id)
       alert(responseJson);
       if(responseJson){
         this.setState({
           dataSource: responseJson,
         }, function(){
         });
       }
     } catch (e) {
       console.error(e);
     }
        //alert("ok");
      }

    }

  render() {
    return (<h1>Bonjour</h1>);
  }
}
export default Webhook
