import ApiService from '../../Shared/api.service';
class ContactService {
    addContact(data) {
        return ApiService.post('contact', data)
    }
    deleteContact(id){
    	return ApiService.delete('contact',id)
    }
    updateContact(id,data){
    	return ApiService.put('contact',data,id)
    }
    getContacts(data) {
        return ApiService.post('contacts', data)
    }

    getContact(id){
      return ApiService.get("contact", id)
    }
}

export default new ContactService();
