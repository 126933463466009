
const initialState = { publicMessage: [], groupMessage : [], privateMessage : [], groupList : [], privateList : [], privateDiscus : [], selectPrivate : null, privateHeader: [],

                        privateDiscus: [], curPrivate : {}, curGroup : {}, curPublic : {} , users: [], showGroup : [] }

function setMessage(state = initialState, action) {

  let nextState
  switch (action.type) {
    case 'SET_PUBLIC': 
        nextState = {
          ...state,
          publicMessage: verifyPublic(action.value)
        }
      return nextState || state
    case 'SET_GROUP' : 
        nextState = {
          ...state,
          groupMessage: verifyGroup(action.value)
        }
      return nextState || state
    case 'SET_PRIVATE' : 
        nextState = {
          ...state,
          privateMessage: verifyPrivate(action.value)
        }
      return nextState || state
    case 'SET_GROUP_LIST' : 
        nextState = {
          ...state,
          groupList: setGroupList(action.value)

        }
      return nextState || state
    case 'SET_PRIVATE_LIST' : 
        nextState = {
          ...state,
          privateList: setPrivateList(action.value)

        }
      return nextState || state
    case 'SET_PRIVATEHEADER' : 
        nextState = {
          ...state,
          privateHeader: verifyPrivateHeader(action.value)
        }
      return nextState || state
    case 'SET_PRIVATEDISCUS' : 
        nextState = {
          ...state,
          privateDiscus: verifyPrivateDiscus(action.value)
        }
      return nextState || state
    case 'SET_CURPRIVATE' : 
        nextState = {
          ...state,
          curPrivate: action.value
        }
      return nextState || state
    case 'SET_CURGROUP' : 
        nextState = {
          ...state,
          curGroup: action.value
        }
      return nextState || state
    case 'SET_CURPUBLIC' : 
        nextState = {
          ...state,
          curPublic: action.value
        }
      return nextState || state
    case 'SET_USERS' : 
        nextState = {
          ...state,
          users: action.value
        }
      return nextState || state
    case 'SHOW_GROUP_MESSAGE' : 
      nextState = {
        ...state,
        showGroup: action.value
      }
    return nextState || state
  default:
    return state
  }
}

function verifyPublic(message) {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  let array = [];
  for(var item in message)
  {
    if(message[item].user && message[item].user.id)
    {
      if(!message[item].archived || message[item].archived == false)
      {
        if(message[item].isTestUser == false)
        {
         message[item].id = item;
          message[item].type = "public"
          array.push(message[item])
        }
        else
        {
          if(message[item].user._id == user._id)
          {
             message[item].id = item;
            message[item].type = "public"
            array.push(message[item])
          }
        }
      }
    }
  }
  return array.sort(function(a,b){
          let _a = Date.parse(a.createdAt);
          let _b = Date.parse(b.createdAt)
          return _b - _a;
        });
}
function verifyGroup(message) {
  let array = [];
  for(let item in message)
  {
    if(message[item].user && message[item].user.id)
    {
      if(!message[item].archived || message[item].archived == false)
      {
        message[item].id = item;
        message[item].type = "group"
        array.push(message[item])
      }
    }
  }
  return array;
}
function verifyPrivate(message) {
  let array = [];
  for(let item in message)
  {
    if(message[item].user._id)
    {
      if(!message[item].archived || message[item].archived == false)
      {
        message[item].id = item;
        message[item].type = "private"
        array.push(message[item])
      }
    }
  }
  return array;
}
function verifyGroupName(groupList) {
  let array = [];

  for(let item in groupList)
  {
    groupList[item].idGroup = item;
    array.push(groupList[item]);
  }
  return array;
} 
function verifyPrivateHeader(privateHeader) {
  let array = [];
  for(let item in privateHeader)
  {
    array.push(privateHeader[item].header);
  }
  return array;
} 
function verifyPrivateDiscus(privateDiscus)
{
  return privateDiscus
}
function setGroupList(groupList)
{
  
  let array = [];
  for(let i in groupList)
  {
    array.push(groupList[i])
  }

  return array;
}
function setPrivateList(privateList)
{
  let array = [];
  for(var i in privateList)
  {
    array.push(privateList[i])
  }
  return array;
}
export default setMessage;