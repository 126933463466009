
const config = {

  // URL_API_base: 'https://strong-keyword-229315.appspot.com/api/v1',
    URL_API_base: 'https://acheter-pour-lou-1549867295132.appspot.com/api/v1',




    // URL_API_base: 'http://192.168.1.4:3000/api/v1',

}
export default config;
