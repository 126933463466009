import * as firebase from "firebase";
import '@firebase/firestore';
import Moment from 'moment';
import ApiService from '../../Shared/api.service';

//import * as admin from "firebase-admin";
//const functions = require('firebase-functions');
//const notifyUsers = require('./notify-users');

class Backend {
  uid = "";
  messagesRef = null;
  nameTable = '';
  nameTableExist = '';
  messagesRef0 = null;
  nameTableGroup = null;
  nameTableGroupUSER = null;
  nameUserGroup = null;

  // initialize Firebase Backend
  constructor() {

    /*firebase.initializeApp({
         //apiKey: "AIzaSyBb98_2S5kDmC3v9QUNnwBpJm_fZjBxPw0",
         //authDomain: "projetapl-67dd4.firebaseapp.com",
         //databaseURL: "https://projetapl-67dd4.firebaseio.com",
         //projectId: "projetapl-67dd4",
         //storageBucket: "projetapl-67dd4.appspot.com",
         //messagingSenderId: "89963579276"

          apiKey: "AIzaSyAAVROIXtOT4dDvzexY1GNkImWmavOXUbU",
          authDomain: "apl-web-897ae.firebaseapp.com",
          databaseURL: "https://apl-web-897ae.firebaseio.com",
          projectId: "apl-web-897ae",
          storageBucket: "apl-web-897ae.appspot.com",
          messagingSenderId: "641666235426",
          appId: "1:641666235426:web:6e2eb7409bd17007"

         //apiKey: "AIzaSyCJifohAWO4ZQqAc_rQQ72iGC-M0Sr7LPQ",
         //authDomain: "strong-keyword-229315.firebaseapp.com",
         //databaseURL: "https://strong-keyword-229315.firebaseio.com/",
         //projectId: "strong-keyword-229315",
         //storageBucket: "strong-keyword-229315.appspot.com",
         //messagingSenderId: "240684049853",
         //pushServerKey: "AAAAOAnjJb0:APA91bGdFuikPGUUS-BfY47hGw9j3HTydbsAQpHsCC1G4ahckw_3hoekOAPy6sPpVVV4zS_1q8d_bvWUz2LadAD0tMcXkPDBVbcc008B1wxh6FHz7p9Y7zz-FWFXpldD9-tpSwaFvoqX"


    });
    /*firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //this.setUid(user.uid);
      } else {
        firebase
          .auth()
          .signInWithEmailAndPassword('acheterpourlouerapp@gmail.com','acheter0123')//.signInAnonymously()
          .catch(error => {
            alert(error.message);
          });
      }
    });*/
  }


  createTable(data1, data2, nameTableCreate){
    //this.messagesRefCreate = firebase.database().ref(nameTableCreate);
    //this.messagesRefCreate.off(); //Detaches a callback previously attached with on()

    this.nameTable = 'MESS'+data2;
    let nameTable = 'MESS'+data2;
    this.messagesRefCreateTable = firebase.database().ref(nameTable);
    this.messagesRefCreateTable.off(); //Detaches a callback previously attached with on()

    this.messagesRefCreateTable.child(nameTable);

    var today = new Date();
    var timestamp = today.toISOString();

    this.messagesRefCreateTable.push({
      user1: data1,
      user2: data2,
      idMessage : 'MESS'+data2,
      createdAt: timestamp,
    });

  }



  // Send message PRIVATE
  sendMessagePrivateLoad(message){
    var today = new Date();
    var timestamp = today.toISOString();

    for (let i = 0; i < message.length; i++) {
      this.messagesRefCreateTable.push({
        text: message[i].text,
        user: message[i].user,
        createdAt: timestamp
      });

      console.log("message[i].idPhone");
      console.log(message[i].user.idPhone);

      fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'key=AAAAFPI_R4w:APA91bEadsb8UlTZhWmottNlhg0naFiMsoY8s6p5zoOFu2a6c9fMVUy6eJmouyog5_Phc6ByDgm86u3u9AS0xAs8dXIgfJJIjwtUYUIEzFoD5mRQHPtF9re0cOlK8vwHZnJlDk9X1FFt'
        },
        body: JSON.stringify({
            "to":message[i].user.idPhone,
            "notification": {
              "body": message[i].text,
              "title": "APL - nouveau message",
              "sound" : "default",
              "priority":"high",
            },
            "data":{
            "body": "your order is okay",
                    "title": "Brg - Your order",
                    "type":"newentry"
            }
        })
      })
    }
  }




  // Send message PRIVATE
  sendMessagePrivate(message){
    var today = new Date();
    var timestamp = today.toISOString();

    for (let i = 0; i < message.length; i++) {
      this.messagesRefExist.push({
        text: message[i].text,
        user: message[i].user,
        createdAt: timestamp
      });

      fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'key=AAAAFPI_R4w:APA91bEadsb8UlTZhWmottNlhg0naFiMsoY8s6p5zoOFu2a6c9fMVUy6eJmouyog5_Phc6ByDgm86u3u9AS0xAs8dXIgfJJIjwtUYUIEzFoD5mRQHPtF9re0cOlK8vwHZnJlDk9X1FFt'
        },
        body: JSON.stringify({
            "to":message[i].user.idPhone,
            "notification": {
              "body": message[i].text,
              "title": "APL - nouveau message",
              "sound" : "default",
              "priority":"high",
            },
            "data":{
            "body": "your order is okay",
                    "title": "Brg - Your order",
                    "type":"newentry"
            }
        })
      })
    }
  }



  sendNameTable(nameTableExist){
    this.nameTableExist = nameTableExist;
  }



  loadMessagesPrivateExist(callback) {
    this.messagesRefExist = firebase.database().ref(this.nameTableExist);
    this.messagesRefExist.off(); //Detaches a callback previously attached with on()
    const onReceive = data => {
      const message = data.val();
      callback({
        _id: data.key,
        text: message.text,
        //createdAt: new Date(message.createdAt),
        createdAt: message.createdAt,
        user: {
          _id: message.user._id,
          name: message.user.name
        }
      });
    };

    var d = this.getLimit();
    console.log(d);
    this.messagesRefExist
      .orderByChild("createdAt")
      .on("child_added", onReceive);
  }





  loadMessagesPrivate(callback) {
    this.messagesRef0 = firebase.database().ref(this.nameTable);
    this.messagesRef0.off(); //Detaches a callback previously attached with on()
    const onReceive = data => {
      const message = data.val();
      callback({
        _id: data.key,
        text: message.text,
        //createdAt: new Date(message.createdAt),
        createdAt: message.createdAt,
        user: {
          _id: message.user._id,
          name: message.user.name
        }
      });
    };

    var d = this.getLimit();
    this.messagesRef0
      .orderByChild("createdAt")
      .on("child_added", onReceive);
  }


  archiveMesssage(id) {
    return new Promise(async (resolve,reject) => {
      try {
        let messagesRef = firebase.database().ref("messages/"+id).update({
          archived: true
        })
      }
      catch (err)
      {
        reject(err)
      }
    })
  }

  archiveMessageGroup(id,group) {
    console.log("mgr : ",id)
    console.log("group : ",group)
    return new Promise(async (resolve,reject) => {
      try {
        let messagesRef = firebase.database().ref(group+'/'+id).update
        ({
          archived: true
        })
        }
      catch(err)
      {
        reject(err);
      }
    })
  }

  archiveMessagePrivate(id,idUser,idDest)
  {
    return new Promise(async (resolve,reject) => {
      try {
        let messagesRef = firebase.database().ref(idUser+"/"+idDest+"/messageDiscus/"+id).update
        ({
          archived: true
        })
        }
      catch(err)
      {
        reject(err);
      }
    })
  }

  answerTo(message,mention)
  {
    console.log("send sigle");
    var today = new Date();
    let timestamp = today.toISOString();
    console.log(message);
    let messagesRef = firebase.database().ref("messages").push({
      text:message.text,
      user:message.user,
      createdAt:timestamp,
      archived: false,
      mention: mention
    })
  }



  // ####

  initFire(nameTableIn_Id) {
    this.nameTableIn_Id = nameTableIn_Id;
  }

  loadFireDiscus(_idUser) {
    this._idUser = _idUser;
  }


  loadDiscussion() {

    return new Promise(async (resolve, reject) => {
      try {
        this.fireRefDiscuss = firebase.database().ref(this.nameTableIn_Id)
        this.fireRefDiscuss.off(); //Detaches a callback previously attached with on()
        var dataResponse = [];
        const onReceive = data => {
          var temp = {};
          const message = data.val();

          if(message.header !== undefined){

            temp = {
              idUser: message.header.idUser,
              createdAt: message.header.createdAt,
              lastMessage: message.header.lastMessage,
              userName : message.header.userName
            }

          }
          dataResponse.push(temp);

          return false;
        };

        this.fireRefDiscuss
        .orderByChild("header/createdAt")
        .on("child_added", onReceive);

        setTimeout(() => {
          console.log('dataResponse');
          console.log(dataResponse);
          resolve(dataResponse);
        }, 2000)

      } catch(err) {
        reject(err);
      }
    })
  }

  // retrieve the messages from the Backend
  loadMessagesFePriv(callback) {
    this.fireRef = firebase.database().ref(this.nameTableIn_Id+'/'+this._idUser+'/messageDiscus')//.child('-Lbskt8XICU1aWfzS0Qh').child('messageContent').child('messageDiscus');
    this.fireRef.off(); //Detaches a callback previously attached with on()
    const onReceive = data => {
      const message = data.val();
      console.log("message backend: ",message)
      if(!message.archived)
      {
        if(message.read == false)
        {
          callback({
            _id: data.key,
            id: data.key,
            text: message.message,
            createdAt: message.createdAt,
            archived: message.archived,
            read: message.read,
            user: {
              _id: message.user._id,
              id: message.user._id,
              name: message.user.name
            }
          });
        }
        else
        {
          callback({
            _id: data.key,
            id: data.key,
            text: message.message,
            createdAt: message.createdAt,
            archived: message.archived,
            user: {
              _id: message.user._id,
              id: message.user._id,
              name: message.user.name
            }
          });
        }
      }
    };

    this.fireRef
      .orderByChild("createdAt")
      .on("child_added", onReceive);
  }

  // send the message Priv FE -----------------
  sendMPBy(userId,destinataireId,message){
    return new Promise(async(resolve,reject)=>{
      try{
        var today = new Date();
        var timestamp = today.toISOString();

        let fireRef = firebase.database().ref(userId+'/'+destinataireId+'/messageDiscus');
        fireRef.push({
          createdAt:timestamp,
          message:message.text,
          user:{
            id: message.user._id,
            _id:message.user._id,
            name:message.user.name
          },
          archived: false,
          
        })

          let fireRef01 = firebase.database().ref(destinataireId+'/'+userId+'/messageDiscus');
          fireRef01.push({
              createdAt: timestamp,
              message : message.text,
              archived: false,
              read: false,
              user: {
                id: message.user._id,
                _id: message.user._id,
                name: message.user.name
              }
          });


        let fireRef1 = firebase.database().ref(userId+'/'+destinataireId+'/header');
        fireRef1.set({
          idUser : destinataireId, // koto01
          lastMessage : message.text,
          userName : message.user.nameUserChat,
          createdAt: timestamp,
        })

        let fireRef2 = firebase.database().ref(destinataireId+'/'+userId+'/header');
        fireRef2.set({
          idUser : userId, // koto01
          lastMessage : message.text,
          userName : message.user.name,
          createdAt: timestamp,
        })
        resolve({succes:true})
      }
      catch(err){
        reject(err)
      }
    })

  }


  getMessages(){
    return firebase.database().ref("messages");
  }
  // retrieve the messages from the Backend
  loadMessages(callback,user) {
    this.messagesRefPublic = firebase.database().ref("messages");
    this.messagesRefPublic.off(); //Detaches a callback previously attached with on()
    const onReceive = data => {
      const message = data.val();
      if(!message.user){
        message.user=user
      }
      if(!message.archived)
      {
        callback({
          id: data.key,
          text: message.text,
          createdAt: message.createdAt,
          archived: message.archived,
          mention: message.mention,
          user: {
            id: message.user._id,
            name: message.user.name
          }
        });
      }
    };

    var d = this.getLimit();
    this.messagesRefPublic
      .orderByChild("createdAt")
      .on("child_added", onReceive);
  }

  // send the message PUBLIC

  sendSingle(message,mention,mentions){
    var today = new Date();
    let timestamp = today.toISOString();
    if(mention)
    {
      let messagesRef = firebase.database().ref("messages").push({
        text:message.text,
        user:message.user,
        createdAt:timestamp,

        archived: false, 
        read: false,
        mentions: mentions,
        mention : {
          idMessage : mention.id,
          createdAt: mention.createdAt,
          text: mention.text,
          read: false,
          user: mention.user
        }
      })
    }
    else
    {
      let messagesRef = firebase.database().ref("messages").push({
        text:message.text,
        user:message.user,
        createdAt:timestamp,
        mentions: mentions,
        archived: false,
        read : false
      })
    }
  }

  archiveSingle(id)
  {
    console.log("archive single");
    let messageRef = firebase.database().ref("messages/"+id);

  }

  sendMessage(message) {
    console.log("send message");
    var today = new Date();
    var timestamp = today.toISOString();
    for (let i = 0; i < message.length; i++) {

      this.messagesRefPublic.push({
        text: message[i].text,
        user: message[i].user,
        createdAt: timestamp,
        archived: false
      });
    }
  }

  // close the connection to the Backend
  closeChat() {
    if (this.messagesRef) {
      this.messagesRef.off();
    }
  }

  getLimit() {
    var today = new Date();
    today.setDate(today.getDate() - 31); // last 30 Days
    var changedISODate = new Date(today).toISOString();
    return changedISODate;
  }




// ********************************************************
// ********************************************************
// ********************************************************
// ********************************************************
// GROUP MESSAGE


loadTablesMessageGroup(callback) {

  var ref = firebase.database().ref(this.nameUserGroup);
  ref.once("value")
  .then(function(snapshot){
    var data = snapshot.val();
    callback({
      data
    });
  })
}


loadTablesMessage() {
  console.log("loadTablesMessageGroup %o", this.nameUserGroup);
  return new Promise(async (resolve, reject) => {
    try {
      const ref = firebase.database().ref(this.nameUserGroup);
      const snapshot = await ref.once("value");
      console.log('### snapchot ###', this.nameUserGroup)

      var data = [];
      snapshot.forEach(item => {
        Moment.locale('en');
        var date = item.val().createdAt;
        var newDate = Moment(date).format('D-M-YYYY h:m');

        var temp = {
          createdAt: newDate,
          message: item.val().message,
          nameGroup : item.val().nameGroup,
          users : item.val().users,
          idcreata: item.val().idcreata,
          lastMess : item.val().lastMessage,
          vue : item.val().vue,
        };

        data.push(temp);
        return false;
      })

      resolve(data)

    }
    catch(err){
      console.log(err)
      reject(err);
    }
  })
}

loadTablesMessageold(callback) {
  var ref = firebase.database().ref(this.nameUserGroup);
  ref.once("value")
  .then(function(snapshot){
    //console.log('### snapchot ###', snapshot)
    var data = [];
    snapshot.forEach(item => {
      Moment.locale('en');
      var date = item.val().createdAt;
      var newDate = Moment(date).format('D-M-YYYY h:m');
      /*const databasename = item.val().message;
      const refTemp = firebase.database().ref(databasename);
      var temp = {};
      this.lastMess = '';
      Moment.locale('en');
      var date = item.val().createdAt;
      var newDate = Moment(date).format('D-M-YYYY h:m');

      refTemp.limitToLast(1).once("child_added")
      .then(snapshot2 => {
        if (snapshot2.val()) {
          this.lastMess = snapshot2.val().text;
          temp = {
              createdAt: newDate,
              message: item.val().message,
              nameGroup : item.val().nameGroup,
              users : item.val().users,
              lastMess : this.lastMess,
          };
          data.push(temp);
          return false;
        }
      })   */

      var temp = {
          createdAt: newDate,
          message: item.val().message,
          nameGroup : item.val().nameGroup,
          users : item.val().users,
          idcreata: item.val().idcreata,
          lastMess : item.val().lastMessage,
      };

      data.push(temp);
      return false;
    })
    callback(data);
  })
}

/*  loadTablesMessage() {
    return new Promise(async (resolve, reject) => {
      try {
        const ref = firebase.database().ref(this.nameUserGroup);
        const snapshot = await ref.once("value");
        console.log('### snapchot ###', snapshot)
        const data = [];
        Promise.all(snapshot.forEach(this._subLoadTablesMessage)).then(() => {
          console.log("DATA ##", data);
          resolve(data);
        });

      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }*/
  _subLoadTablesMessage(item) {
      return new Promise(async (resolve, reject) => {
        const databasename = item.val().message;
        const refTemp = firebase.database().ref(databasename);
        try {
          const snapshot2 = await refTemp.limitToLast(1).once("child_added");
        if (snapshot2.val()) {
          const temp = {
            createdAt: item.val().createdAt,
            message: item.val().message,
            nameGroup : item.val().nameGroup,
            users : item.val().users,
            lastMess : snapshot2.val().text,
          };
          console.log('data push', temp.nameGroup)
          resolve(temp);
        }
        } catch (e) {
          console.error(e)
          reject(e);
        }
      });

  }


  sendNameMessageGroup(idUserToken){
    return new Promise(async (resolve, reject)=>{
      try{
        resolve (this.nameUserGroup = 'GR_'+idUserToken);
      }
      catch(err){
        reject(err)
      }
    })
  }

  sendNameMessageGroupExist(nameMessage, users){
    //console.log("------------------------------------- "+nameMessage+"-----------------------------------------------")
    this.nameTableGroup = nameMessage;
    this.usersTab = users;
  }

  /*
  loadTablesMessageGroup(callback) {
    var ref = firebase.database().ref(this.nameUserGroup);
    ref.once("value")
    .then(function(snapshot){
      var data = snapshot.val();
      callback({
        data
      });
    })
  }*/




  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }


  addUserMessageGroupExist(idTokenMe, nameGroup, users,idGroup){
    var nameTableAddUser = 'GR_'+idTokenMe;
    console.log("-------name Table----------------------",nameTableAddUser)
    var today = new Date();
    var timestamp = today.toISOString();

    var ref = firebase.database().ref(nameTableAddUser);
    ref.once("value")
      .then(snapshot=>{
        snapshot.forEach(item => {
          //console.log("======================================Item namba Tal suer",item.val())
          if(item.val().nameGroup === nameGroup){


            // var tabOld = item.val().users;
            var tabOld = users;

            var tabOldUser = item.val().users;

            /*for (let i = 0; i < users.length; i++) {
              tabOld.push(users[i].text);
            }*/

            for (let i = 0; i < tabOldUser.length; i++) {

              var nameUserUp = 'GR_'+tabOldUser[i];
              var ref = firebase.database().ref(nameUserUp);
              ref.once("value")
                .then(snapshotUp=>{
                  snapshotUp.forEach(itemUp => {

                    if(itemUp.val().nameGroup === nameGroup){
                      snapshotUp.ref.child(itemUp.key).update({users : tabOld});
                    }
                  })
                })
            }

            snapshot.ref.child(item.key).update({users : tabOld});

            /*for(let k=0; k<tabOld.length; k++)
            {
              if(!tabOldUser.some(tabOld[k]))
              {
                console.log("not inside: ",tabOld[k])
              }
              else
              {
                console.log("inside : ",tabOld[k])
              }
            }*/
            this.nameTableGroup = item.val().message;
          }
        })
      })
  }
  addUserToGroup(group, idUserCreat,userObject,idUser)
  {
    let groupTable = firebase.database().ref('GR_'+idUserCreat);
    groupTable.push(
    {
      nameGroup : group.nameGroup,
      users : userObject,
      idcreata: group.idcreata,
      message : group.message,
      createdAt : group.createdAt,
      lastMessage : group.lastMessage ? group.lastMessage : ""
    })
    for(let i=0; i<userObject.length; i++)
    {
      if(userObject[i] != idUserCreat)
      {
        groupTable = firebase.database().ref('GR_'+userObject[i]);
        groupTable.on('value',(snapshot)=> {
          let table = snapshot.val();
          for(var index in table)
          {
            if(table[index].idcreata == group.idcreata)
            {
              let query = firebase.database().ref('GR_'+userObject[i]+'/'+index);
              query.update({users:userObject})
            }
          }
        });

      }
    }
    groupTable = firebase.database().ref('GR_'+idUser);
    groupTable.on('value',(snapshot)=> {
      let table = snapshot.val();
      for(var i in table)
      {
        if(table[i].idcreata == group.idcreata)
        {
          let query = firebase.database().ref('GR_'+idUser+'/'+i);
          query.update({users:userObject})
        }
      }
    });
  }
  createTableGroup(nameGroup, idUserCreat, userObject){
    this.nameTableGroup = 'MGR_'+this.makeid();

    this.usersTab = userObject;

    this.messagesCreateTableGroup = firebase.database().ref(this.nameTableGroup);
    // this.messagesCreateTableGroup.off(); //Detaches a callback previously attached with on()

    // create Table groupe User Id
    this.nameTableGroupUSER = 'GR_'+idUserCreat;

    this.CreateTableGroupUser = firebase.database().ref(this.nameTableGroupUSER);
    // this.CreateTableGroupUser.off(); //Detaches a callback previously attached with on()

    var today = new Date();
    var timestamp = today.toISOString();

    this.CreateTableGroupUser.push({
      nameGroup: nameGroup,
      users: userObject,
      idcreata : idUserCreat,
      message : this.nameTableGroup,
      createdAt: timestamp,
      lastMessage : '',
    });

    for (let i = 0; i < userObject.length; i++) {
      this.nameTableGroupUSERUniq = 'GR_'+userObject[i];
      this.messagesCreateTableGroupU = firebase.database().ref(this.nameTableGroupUSERUniq);
      // this.messagesCreateTableGroupU.off(); //Detaches a callback previously attached with on()
      this.messagesCreateTableGroupU.push({
        nameGroup: nameGroup,
        users: userObject,
        idcreata : idUserCreat,
        message : this.nameTableGroup,
        createdAt: timestamp,
      });
    }

  }




  loadMessagesGroup(callback) {
    console.log("---------------------"+this.nameTableGroup+"--------------------------------------------")
    this.messagesCreateTableGroup = firebase.database().ref(this.nameTableGroup);
    //console.log("nom Table Groupe", this.nameTableGroup)
    this.messagesCreateTableGroup.off(); //Detaches a callback previously attached with on()
    const onReceive = data => {
      const message = data.val();
      if(!message.archived)
      {
        if(message.vue == false && message.mention)
        {
          callback({
            id: data.key,
            _id: data.key,
            text: message.text,
            createdAt: message.createdAt,
            archived: message.archived,
            vue: false,
            user: {
              _id: message.user._id,
              id: message.user._id,
              name: message.user.name
            },
            mention: message.mention
          });
        }
        else
        {
          callback({
          id: data.key,
          _id: data.key,
          text: message.text,
          createdAt: message.createdAt,
          archived: message.archived,
          image: message.image,
          vue: false,
          user: {
            _id: message.user._id,
            id: message.user._id,

            name: message.user.name
          }

        });
        }
      }
    };

    var d = this.getLimit();
    this.messagesCreateTableGroup
      .orderByChild("createdAt")
      .on("child_added", onReceive);
  }


  // send the message Group
  sendMessageGroup(group,message,mention) {
    var today = new Date();
    var timestamp = today.toISOString();
    let refTableName = firebase.database().ref(group.firebaseMessageId);

      if(mention)
      {
        refTableName.push({
          text:message.text,
          user:message.user,
          createdAt:timestamp,
          archived: false,
          vue: false, 
          mention : {
            idMessage : mention.id,
            createdAt: mention.createdAt,
            text: mention.text,
            vue: false,
            user: mention.user
          }
        })
      }
      else
      {
        refTableName.push({
          text:message.text,
          user:message.user,
          createdAt:timestamp,
          archived: false,
          vue: false
        })
      }

    /*var usersTabN = [];
    usersTabN = group.users;
    //usersTabN.push(message[0].user._id);
    console.log(usersTabN);

    for (let i = 0; i < usersTabN.length; i++) {
    var nameTab = 'GR_'+usersTabN[i];
    var ref = firebase.database().ref(nameTab);
    ref.once("value")
      .then(snapshot=>{
        snapshot.forEach(item => {
          if(item.val().message === this.nameTableGroup) {
            snapshot.ref.child(item.key).update({ lastMessage: message[0].text, vue : false})
          }
        })
      })

    var ref2 = firebase.database().ref('GR_'+message[0].user._id);
    ref2.once("value")
      .then(snapshot => {
        snapshot.forEach(item => {
          if(item.val().message === this.nameTableGroup) {
            snapshot.ref.child(item.key).update({ lastMessage: message[0].text, vue : true})
          }
        })
      })
    }*/
    /*for(let i=0; i<group.users.length; i++)
    {
      let refUser = firebase.database().ref("GR_"+group.users[i]);
      refUser.once('value', snapshot => {
        let userGroup = snapshot.val();
        for(var item in userGroup)
        {
          if(userGroup[item].message == group.message)
          {

            let refUserMessage = firebase.database().ref("GR_"+group.users[i]+"/"+item);
            refUserMessage.update({lastMessage: message.text})
          }
        }
      })
    }

    let refCreata = firebase.database().ref("GR_"+group.idcreata); 
    refCreata.once('value', snapshot => {
      let userGroup = snapshot.val();
      for(var item in userGroup)
      {
        if(userGroup[item].message == group.message)
        {
          let query = firebase.database().ref("GR_"+group.idcreata+"/"+item);
          query.update({lastMessage : message.text})
        }
      }
    })*/
  }



  exitMessageGroup(idTokenMe, nameGroup){
    var nameGroupN = 'GR_'+idTokenMe;
    const ref = firebase.database().ref(nameGroupN);
    ref.once("value")
    .then(function(snapshot){
        snapshot.forEach(item => {
          if(item.val().nameGroup === nameGroup){
            console.log(item.key);
            snapshot.ref.child(item.key).remove();
          }
      })
    })
  }


  deleteMessageGroup(idTokenMe, nameGroup){
    console.log("deleted")
    return new Promise((resolve,reject)=>{
      try{
        var nameGroupN = 'GR_'+idTokenMe;
        const ref = firebase.database().ref(nameGroupN);
        ref.once("value")
        .then(function(snapshot){
            snapshot.forEach(item => {
              if(item.val().nameGroup === nameGroup){

                const refSuppr = firebase.database().ref(item.val().message);
                refSuppr.remove();

                console.log(item.val().message);

                var usersTab = item.val().users;

                usersTab.push(idTokenMe);

                for (let i = 0; i < usersTab.length; i++) {
                  var nameTab = 'GR_'+usersTab[i];
                  var ref = firebase.database().ref(nameTab);
                  ref.once("value")
                    .then(snapshot2=>{
                      snapshot2.forEach(itemT2 => {

                        if(itemT2.val().nameGroup === nameGroup){
                          console.log("deleted this groupe",itemT2.key);
                          snapshot2.ref.child(itemT2.key).remove();
                        }
                      })
                    })
                  }

              }
          })
        })
        resolve({success:true})
      }
      catch(e){
        console.log(e)
      }
    })

  }
  user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  onValue()
  {
    return this.messagesRefPublic = firebase.database().ref("messages");
  }
  getMessageDiscus(idUser)
  {
    
    return this.messageDiscus = firebase.database().ref(this.user._id+"/"+idUser+"/messageDiscus")
  }
  readPublicMessage(id)
  {
    this.messageRefPublic = firebase.database().ref("messages/"+id);
    this.messageRefPublic.update({read: true})
  }
  getPrivateMessage()
  {
    return this.messageRefPrivate = firebase.database().ref(this.user._id);
  }
  readPrivateMessage(item)
  {
    let data = firebase.database().ref(this.user._id+"/"+item.user.id+"/messageDiscus/"+item.id);
    data.update({read: true})
  }
  getPrivateMessage()
  {
    return this.messageRefPrivate = firebase.database().ref(this.user._id+"/");
  }
  readGroupMessage(group,item)
  {
    let data = firebase.database().ref(group.message+"/"+item.id);
    data.update({read: true})
  }
  addMentions(mentions)
  {
    let mentionRef = firebase.database().ref()
  }
  sendPublicPhoto(message)
  {
    let user = {
      id : message.user._id,
      _id : message.user._id,
      name : message.user.firstname + " " + message.user.lastname
    }
    var today = new Date();
    let timestamp = today.toISOString();
    let messagesRef = firebase.database().ref("messages").push({
      image: message.image,
      user:user,
      createdAt:timestamp,
      archived: false,
      read : false
    })
  }
  sendPrivatePhoto(userId,destinataireId,message)
  {
    return new Promise(async(resolve,reject)=>{
      try{
        var today = new Date();
        var timestamp = today.toISOString();

        let fireRef = firebase.database().ref(userId+'/'+destinataireId+'/messageDiscus');
        fireRef.push({
          createdAt:timestamp,
          image: message.image,
          user:{
            id: message.user._id,
            _id:message.user._id,
            name:message.user.name
          },
          archived: false,
          
        })

          let fireRef01 = firebase.database().ref(destinataireId+'/'+userId+'/messageDiscus');
          fireRef01.push({
              createdAt: timestamp,
              image: message.image,
              archived: false,
              read: false,
              user: {
                id: message.user._id,
                _id: message.user._id,
                name: message.user.name
              }
          });


        let fireRef1 = firebase.database().ref(userId+'/'+destinataireId+'/header');
        fireRef1.set({
          idUser : destinataireId, // koto01
          lastMessage : "Photo",
          userName : message.user.nameUserChat,
          createdAt: timestamp,
        })

        let fireRef2 = firebase.database().ref(destinataireId+'/'+userId+'/header');
        fireRef2.set({
          idUser : userId, // koto01
          lastMessage : "Photo",
          userName : message.user.name,
          createdAt: timestamp,
        })
        resolve({succes:true})
      }
      catch(err){
        reject(err)
      }
    })
  }
  sendGroupPhoto(group,message,mention)
  {
    var today = new Date();
    var timestamp = today.toISOString();
    let refTableName = firebase.database().ref(group.firebaseMessageId);
        refTableName.push({
          text: "",
          image: message.image,
          user:message.user,
          createdAt:timestamp,
          archived: false,
          vue: false
        })
  }



}

export default new Backend();