import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import ContactService from "./Contact.service.js";
import Fab from '@material-ui/core/Fab';

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import Icon from '@material-ui/core/Icon';
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    '&$cssFocused': {
      color: purple[500],
    },
  },

  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: purple[500],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: purple[500],
    },
  },
  notchedOutline: {},
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: { useNextVariants: true },
});
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function Transition(props) {

  return <Slide direction="left" {...props} />;
}

class UpdateDialogSlide extends React.Component {
  state = {
    open: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      nom: this.props.contact.lastname,
      prenom:this.props.contact.firstname,
      email:this.props.contact.email,
      gsm:this.props.contact.phone,
      societe:this.props.contact.company,
      note:this.props.contact.note,
      position:this.props.contact.position
  };

    this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  saveContact = ()=>{
    let retour = true;
    for (var key in this.state) {
        // code...
        if(this.state[key] == '' && key!="note"){
          retour = false;
          break;
        }
    }
    console.log(retour)
    if(retour){
      //save contact
      let toSave={
          "userId": localStorage.getItem('user')?JSON.parse(localStorage.getItem('user'))._id:null,
          "firstname": this.state.prenom,
          "lastname": this.state.nom,
          "phone": this.state.gsm,
          "email": this.state.email,
          "position": this.state.position,
          "company": this.state.societe,
          "note": this.state.note
      }
      let self = this;
      ContactService.updateContact(self.props.contact._id,toSave).then(function(){
        self.handleClose()
      }).catch(function(){
        self.handleClose()
      });
    }
  }

  handleChange(event) {
    let key = event.target.name
    this.setState({[event.target.name]: event.target.value},function(){
      console.log(this.state[key])
    });
  }
  resetState =()=>{
    for(var key in this.state){
       this.setState({
         [key]:''
       })
    }
  }
  render() {
    console.log(this.props.label);
    console.log(this.props.contact);
    console.log("user %o",localStorage.getItem('user'));
    const classes={
      input:{
      minHeight: "32px",
      fontSize: "14px",
      borderRadius: "0.5em",
      border: "solid #F4F5F9 2px",
      background: "#F4F5F9",
      marginBottom: "20px"

    },
    label:{
      color: "#828B88"
    },
     button:{
       color:"white",
       background: "linear-gradient(60deg,#439598, #6CD898)",
       width:"100%",
       fontWeight: "bold",
       borderRadius: "2em"
     },
     buttonSimple:{
       color:"white",
       background: "#D5D5D5",
       width:"100%",
       fontWeight: "bold",
       borderRadius: "2em"
     }
    }
    return (
      <div>

        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth="true" >
                    <label style={classes.label} >Nom*:</label>
                    <input style={classes.input} name="nom" value={this.state.nom} onChange={this.handleChange} fullWidth="true"/>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth="true">
                        <label style={classes.label} >Prenom*:</label>
                        <input style={classes.input} name="prenom" value={this.state.prenom} onChange={this.handleChange}/>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl  fullWidth="true">
                        <label style={classes.label} >GSM*:</label>
                        <input  style={classes.input} name="gsm" value={this.state.gsm} onChange={this.handleChange}/>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth="true" >
                        <label style={classes.label} >Email*:</label>
                        <input style={classes.input} name="email" value={this.state.email} onChange={this.handleChange}/>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl  fullWidth="true">
                        <label style={classes.label} >Position*:</label>
                        <select style={classes.input} name="position" value={this.state.position} onChange={this.handleChange}>
                            <option value="owner">owner</option>
                            <option value="realtor">realtor</option>
                            <option value="goodMerchand">goodMerchand</option>
                            <option value="banker">banker</option>
                            <option value="expert">expert</option>
                            <option value="architect">architect</option>
                            <option value="entrepreneur">entrepreneur</option>
                            <option value="other">other</option>
                        </select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl  fullWidth="true">
                        <label style={classes.label} >Société*:</label>
                        <input style={classes.input} name="societe" value={this.state.societe} onChange={this.handleChange}/>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth="true" >
                        <label style={classes.label} >Note:</label>
                        <textarea style={classes.input} name="note" value={this.state.note} onChange={this.handleChange}>
                        </textarea>
                    </FormControl>
                    </Grid>
                </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={classes.buttonSimple}
             onClick={()=>{this.handleClose();this.resetState()}} color="primary">
              Annuler
            </Button>
            <Button
             style={classes.button}
             disabled={
               !this.state.gsm ||
                !this.state.nom ||
                !this.state.prenom ||
                !this.state.position ||
                !re.test(this.state.email)  ||
                !this.state.societe
             }
             onClick={this.saveContact} color="primary">
              Modifier
            </Button>

          </DialogActions>
        </Dialog>

           <Icon onClick={this.handleClickOpen}>edit</Icon>


      </div>
    );
  }
}

export default UpdateDialogSlide;
