import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import VideocamIcon from '@material-ui/icons/Videocam';
import StarIcon from '@material-ui/icons/Star';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ReactPlayer from 'react-player';
import Rating from 'material-ui-rating';

import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import femme from './../../assets/images/video/femme.jpeg';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import config from '../../path';
import TrainingContentService from "./TrainingContent.service.js";
import { Player } from 'video-react';
import Header from '../Layout/newHeader';
import "../../assets/css/video-react.css";
import ReactPixel from 'react-facebook-pixel';
// import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';




const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const styles = theme =>  ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    //marginLeft: theme.spacing.unit * 3,
    //marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up('800.001')]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('800')]: {
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    position: "relative",
    top: "-20px"
    // paddingBottom: theme.spacing.unit * 8
  },
  card: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    borderRadius: "12px"
    // marginBottom: theme.spacing.unit * 8,
  },
  media: {
    // height: 200,
    // backgroundPosition: 'top',
    // [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
    //   height: 350,
    // },
  },
  logo: {
    width: "80%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  center:{
    textAlign: 'center',
    marginTop: 20
  },
  social:{
    textAlign: 'center',
    marginTop: 40
  },
  footer:{
    textAlign: 'center',
    marginTop: 40,
    color: "#42859F",
    textTransform: "uppercase"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"300px",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"300px",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  titlePrimary:{
    color: "#3B808E",
    fontSize: "1.5em",
    marginBottom: "10px",
    fontWeight: "bold"
  },
  textPrimary:{
    color: "#3B808E",
    fontSize: "1em",
    marginBottom: "10px",
    marginTop: "10px",
    fontSize: "1.2em"
  },
  text:{
    color:"#777777",
    fontSize: "1.2em"
  },
  buttonPlay:{
    backgroundColor: "#65C66A",
    color:"white",
    display: "relative",
    float: "right"
  },
  textAccent:{
    color:"#72E297",
    fontSize:"1em",
  },
  icon:{
    position: "relative",
    top: "5px",
    fontSize:"1.2em"
  },
  divider:{
    marginTop: "30px",
    marginBottom: "20px",
  },
  video:{
    width:"40%" ,
    overflow: "hidden",
    display:"block",
    margin:"auto"
  },
  [theme.breakpoints.down('sm')]: {
    video:{
      width:"100%",
      overflow: "hidden",
      display:"block"
    }
  },
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: "black",
    },

    link:{
      textDecoration: "none",
      fontSize: "1.2em",
      color: "#808986",
      fontWeight: "500",

    }

});

class TrainingContent extends React.Component {
 user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
              isLoading: true,
              videoHeader: "",
              dataSource:[],
              training: {},
              totalVideo: 0,
              score: 0,
              prevPath:'',
              haveRate:false,
              entryVideoEmbedCode : null

        };
    console.log("main %o",this.classes);




  }

  /**
   * list TrainingContent init
   */


 componentDidMount(){
    ReactPixel.init("714205238765459");
    ReactPixel.pageView();          // For tracking page view
    
    let self = this;
        this.setState({
              isNotation : 3, //listHead[0].notation,
              headTypeActif : 'video', // video | text
              videoUrlState :  'https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4', //listHead[0].videoUrl,
              itemId : self.props.match.params.id,

              textTitle :'Text descriptif',
              isSubscribed: false
          })
          console.log(" self.props.match.params.id %o",  self.props.match.params.id);
          this.listModules(
              {   "searchValue": self.props.match.params.id,
                  "searchFields": [
                    "trainingId"
                  ]
              }
           );
          //this.TrainingContentService.getUser(this.user.id).then(_user => {console.log(_user)});
         

          this.listTraining(
              {   "searchValue": self.props.match.params.id,
                  "searchFields": [
                    "trainingId"
                  ]
              }
          );
  }

  /** <ReactPlayer style={{"min-height": "200px"}} url={this.state.videoHeader} playing width="100%" />
     * list Training
     * @param {*} data
     */

    async listTraining(data){
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
      const responseJson = await TrainingContentService.getTraining(data);
      console.log("training score",responseJson)
      if(responseJson){
          let score = 0;
          for(let i in responseJson.rows){
            score += responseJson.rows[i].rating;
            if(responseJson.rows[i].userId == user._id){
              console.log("hade rated --------------------------------------------")
              this.setState({
                haveRate:true
              })
            }
            console.log("hade not rated --------------------------------------------",responseJson.rows[i].userId == user._id)
          }
          this.setState({
            score:score/responseJson.rows.length
          })

      }

    }
    async listModules(data){
        let self = this;
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        let _user = await TrainingContentService.getUser(user._id);
        let isSubscribed = false;
        try{
          const responseJson = await TrainingContentService.getModule(self.props.match.params.id);
          console.log("responseJson %o", responseJson);
          if(_user.subscriptions && _user.subscriptions.some(e => e.trainingId === self.props.match.params.id))
          {
            console.log("parms id : ",self.props.match.params.id);
            console.log("_user subscriptions : ",_user.subscriptions);
            console.log("is subscriptions : ",_user.subscriptions.some(e => e.trainingId === self.props.match.params.id))
            let data = _user.subscriptions.filter(e => e.trainingId === self.props.match.params.id);
            if(data[0].expireAt<Date.now())
              isSubscribed = false;
            else
              isSubscribed = true;
          }
          else
          {
            isSubscribed = false;
          }
          if(responseJson){
            let totalVideo = 0;
            let score = 0;

            for(let i in responseJson.modules){
              totalVideo += responseJson.modules[i].videos.length;
              responseJson.modules[i].isSubscribed = isSubscribed;
            }
            score = responseJson.averageRating;

              this.setState({
                  totalVideo: totalVideo,
                  dataSource: responseJson.modules,
                  // score: score/responseJson.rows.length
                  }, function(){
                    // if(this.state.dataSource.length >0){
                    //   this.setState({
                    //       videoHeader: this.state.dataSource[0].entryVideoUrl
                    //   }, function(){
                    //       console.log("this.state.videoHeader %o", this.state.videoHeader);
                    //   });
                    //
                    // }


                    console.log("TRAINING with videos %o", responseJson);

              });
          }
        } catch (e) {
        console.error(e);
        }

        try{
          const training = await TrainingContentService.getTrainingById(this.props.match.params.id);
          console.log("result training : ", training);
          localStorage.setItem("lastTraining",training.title)
          if(training)
          {
            if(training.entryVideoEmbedCode)
            {
              if(training.entryVideoEmbedCode[0] == '<')
              {
                var regex = /<iframe.*?src='(.*?)'/
                var src = regex.exec(training.entryVideoEmbedCode)[1];
                this.setState({entryVideoEmbedCode: src})
                //this.state.entryVideoEmbedCode = src;
              }
              else
              {
                this.setState({entryVideoEmbedCode: training.videoEmbedCode})
                //this.state.entryVideoEmbedCode = responseJson.videoEmbedCode;
              }
            }
            
            this.setState({
                training: training,
                videoHeader: training.entryVideoUrl
                }, function(){
                });
          }
        }catch (e) {
        console.error(e);
        }


    }

    /**
     * list Training
     * @param {*} data
     */
    async listVideos(data){
      return await TrainingContentService.getVideos(data);
    }



  render(){
    let self =this;
    console.log("user %o",localStorage.getItem('user'));
    console.log("score %o",parseInt(this.state.score));
    let rate = parseInt(this.state.score);
    // const matches = useMediaQuery('(max-width:375px)');
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    let num = 0;
    return (
      <div>
      {/* <link rel="stylesheet"
            href="https://video-react.github.io/assets/video-react.css"
          /> */}
      <Header search={false} actualLink={"/trainingContent"} previous={'/training'} contact={[]}/>


      <div className={this.classes.video}>
                {this.state.entryVideoEmbedCode ?
                (
                  <iframe src={this.state.entryVideoEmbedCode} width="100%" height="400px" frameborder="0" allowFullScreen></iframe>
                )
               :
               (
               <Player
                playsInline
                poster="/assets/poster.png"
                src={this.state.videoHeader}
                style={{"minHeight": "200px"}}
              />
              )}
      </div>
      <main className={this.classes.main}>


        <Card className={this.classes.card}>

            <CardContent>

              <Typography align="right" className={this.classes.textPrimary}>
                <VideocamIcon className={this.classes.icon} /> {this.state.totalVideo} videos
              </Typography>
              <Typography align="right" className={this.classes.textPrimary}>
                <Rating

                  value={rate}
                  max={5}
                  onChange={(value) => {
                    TrainingContentService.postUserTraining({
                      "userId":user._id ,
                      "trainingId": self.props.match.params.id,
                      "rating": value
                    }).then(()=>{
                      self.listTraining(
                          {   "searchValue": self.props.match.params.id,
                              "searchFields": [
                                "trainingId"
                              ]
                          }
                      );
                    })
                    console.log(`Rated with value ${value}`)
                  }}
                  readOnly={this.state.haveRate}
                />
              </Typography>

              <Typography align="right" className={this.classes.titlePrimary}>
                {this.state.training.title}
              </Typography>
              <Typography align="right" className={this.classes.text}>
                {this.state.training.description}
              </Typography>

              <Divider variant="inset" />
              
              {this.state.dataSource.map( function(module, index){

                return <div>
                <Typography align="left" className={this.classes.textPrimary}>
                  {module.title.toUpperCase()}
                </Typography>

                {module.videos ? module.videos.map(function(video,index){
                  num+=1;
                  return  <div>
                  <Grid container spacing={24}>
                      <Grid item container xs={12}>
                        <Grid item sm={7}>
                           <Typography align="left" className={this.classes.text}>
                              
                              {num}-  {video.title}
                              {/* </Link> */}
                            </Typography>
                        </Grid>
                        <Grid item sm={5}>
                          <Typography align="left" className={this.classes.text}>
                            <StarIcon className={this.classes.icon} /> <span className={this.classes.textAccent}> {secondsToHms(video.duration)} </span>
                          </Typography>
                          
                        </Grid>
                      </Grid>
                      <Grid xs={12}>
                      <Link className={this.classes.link}  to={"/trainingModule/"+video._id+"/"+module._id+"/"+num+"/"+this.props.match.params.id}>
                       {module.isSubscribed ? (<Fab className={this.classes.buttonPlay} style={{marginRight: "10px"}}>
                            <PlayArrowIcon />
                        </Fab>) : (<div></div>)}
                       </Link>
                       </Grid>

                  </Grid>
                  <Divider className={this.classes.divider}/>


                </div>
              },  this) : ""}

              </div>
            },  this)}










            </CardContent>



        </Card>
        </main>
      </div>
    );
  }
}

function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
}

export default withStyles(styles)(TrainingContent);
