import * as firebase from "firebase";
import '@firebase/firestore';

//import * as admin from "firebase-admin";
//const functions = require('firebase-functions');
//const notifyUsers = require('./notify-users');

class Backend {

  
  user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;

  idToken = localStorage.getItem('idToken');


	constructor() {
  
    firebase.initializeApp
    ({
         /*apiKey: "AIzaSyBb98_2S5kDmC3v9QUNnwBpJm_fZjBxPw0",
         authDomain: "projetapl-67dd4.firebaseapp.com",
         databaseURL: "https://projetapl-67dd4.firebaseio.com",
         projectId: "projetapl-67dd4",
         storageBucket: "projetapl-67dd4.appspot.com",
         messagingSenderId: "89963579276"*/

          // apiKey: "AIzaSyAAVROIXtOT4dDvzexY1GNkImWmavOXUbU",
          // authDomain: "apl-web-897ae.firebaseapp.com",
          // databaseURL: "https://apl-web-897ae.firebaseio.com",
          // projectId: "apl-web-897ae",
          // storageBucket: "apl-web-897ae.appspot.com",
          // messagingSenderId: "641666235426",
          // appId: "1:641666235426:web:6e2eb7409bd17007"

         apiKey: "AIzaSyCJifohAWO4ZQqAc_rQQ72iGC-M0Sr7LPQ",
         authDomain: "strong-keyword-229315.firebaseapp.com",
         databaseURL: "https://strong-keyword-229315.firebaseio.com/",
         projectId: "strong-keyword-229315",
         storageBucket: "strong-keyword-229315.appspot.com",
         messagingSenderId: "240684049853",
         pushServerKey: "AAAAOAnjJb0:APA91bGdFuikPGUUS-BfY47hGw9j3HTydbsAQpHsCC1G4ahckw_3hoekOAPy6sPpVVV4zS_1q8d_bvWUz2LadAD0tMcXkPDBVbcc008B1wxh6FHz7p9Y7zz-FWFXpldD9-tpSwaFvoqX"

    });
  }
  ///////////////////////////////-- PUBLIC MESSAGE --///////////////////
  getPublicMessage() {
    return this.messageRefPublic = firebase.database().ref("messages");
  }
  readPublicMessage(id)
  {
    this.messageRefPublic = firebase.database().ref("messages/"+id);
    this.messageRefPublic.update({read: true})
  }
  
  //////////////////////////////-- GROUPS MESSAGE --////////////////////
  getGroup()
  {
    console.log("GETTING GROUPE REF %o",'GR_'+this.user._id);
    return this.messageRefGroup = firebase.database().ref('GR_'+this.user._id);
  }
  getGroupMessage(id)
  {
    return this.messageRefGroup = firebase.database().ref(id);
  }
  readGroupMessage(item)
  {
    this.messageRefGroup = firebase.database().ref(item.group.message+"/"+item.id);
    this.messageRefGroup.update({read: true})
  }

  //////////////////////////////-- PRIVATE MESSAGE --////////////////////
  getPrivateMessage()
  {
    return this.messageRefPrivate = firebase.database().ref(this.user._id+"/");
  }
  readPrivateMessage(item)
  {
    this.messageRefGroup = firebase.database().ref(this.user._id+"/"+item.user._id+"/"+item.idMessage);
    this.messageRefGroup.update({read: true})
  }

  //////////////////////////////-- GROUP NAME --////////////////////
  getGroupName()
  {
    return this.refGroupName = firebase.database().ref('GR_'+this.idToken);
  }

  
}
export default new Backend();