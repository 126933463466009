import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Rating from 'material-ui-rating';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import teal from '@material-ui/core/colors/teal';

const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const types = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
];
const styles = theme =>  ({


  center:{
    textAlign: 'center',
    marginTop: "20px",
    width: "100%"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"95%",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"95%",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    borderColor: "white",
    borderRadius: "15px",
    backgroundColor: '#F4F5F9',
    width: "95%",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "60%"
    },


  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: teal["A700"],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: teal["A700"],
    },
  },
  notchedOutline: {

    borderColor:'#FFFFFF !important',

  },
  cssLabel: {
    '&$cssFocused': {
      color: teal["A700"],
    },
  },
  menu: {
    width: 200,
  },
});

class StepTwo extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.register = props.register

    console.log("register %o",this.register);
    this.state = {
            rateImmo: 0,
        };
  }


  render(){
    return (
      <main>

        <div  className={this.classes.center}>
            <TextField
              id="outlined-select-currency"
              select
              label="Type de bien"
              className={this.classes.textField}

              SelectProps={{
                MenuProps: {
                  className: this.classes.menu,
                },
              }}
              InputProps={{
                classes: {
                  root: this.classes.cssOutlinedInput,
                  focused: this.classes.cssFocused,
                  notchedOutline: this.classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: this.classes.cssLabel,
                  focused: this.classes.cssFocused,
                },
              }}

              margin="normal"
              variant="outlined"

              name="typeBien"
              value={this.register.state.data.typeBien}


              onChange={this.register.handleChange}

            >
              {types.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div  className={this.classes.center}>
            <Typography align="center" className={this.classes.text}>
                Province / Area
            </Typography>
            <TextValidator

              InputProps={{
                classes: {
                  root: this.classes.cssOutlinedInput,
                  focused: this.classes.cssFocused,
                  notchedOutline: this.classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: this.classes.cssLabel,
                  focused: this.classes.cssFocused,
                },
              }}

              label="Province / Area"
              className={this.classes.textField}
              type="text"


              margin="normal"
              variant="outlined"
              name="provence"
              value={this.register.state.data.provence}



              onChange={this.register.handleChange}
            />
          </div>

          <div  className={this.classes.center}>
              <Typography align="center" className={this.classes.text}>
                  Small description
              </Typography>

                <TextValidator
                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}
                  id="outlined-multiline-static"
                  label="Small description"
                  multiline
                  rows="4"
                  defaultValue="Small description"
                  className={this.classes.textField}
                  margin="normal"
                  variant="outlined"

                  name="description"
                  value={this.register.state.data.description}

                  onChange={this.register.handleChange}
                />
          </div>
          <div  className={this.classes.center}>
              <Typography align="center" className={this.classes.text}>
                  Niveau de connaissance Immo
              </Typography>

                  <Rating

                    value={this.register.state.data.score}
                    max={5}
                    onChange={(value) => this.register.rate(value)}
                    name="score"
                  />

          </div>

          <div  className={this.classes.center}>
            <Typography align="center" className={this.classes.text}>
                Cashflow NET / Rente NET
            </Typography>
            <TextValidator

              InputProps={{
                classes: {
                  root: this.classes.cssOutlinedInput,
                  focused: this.classes.cssFocused,
                  notchedOutline: this.classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: this.classes.cssLabel,
                  focused: this.classes.cssFocused,
                },
              }}


              placeholder="0.00"
              className={this.classes.textField}
              type="number"


              margin="normal"
              variant="outlined"

              name="cashflow"
              value={this.register.state.data.cashflow}
              onChange={this.register.handleChange}
            />
          </div>




      </main>

    );
  }
}

export default withStyles(styles)(StepTwo);
