import config from '../path';
import { AsyncStorage } from 'react-native';
import * as firebase from "firebase";



class ApiService {


    
    hasNoError(response) {
        if( response && response.status === 200 || response && response.status === 201 ) {
            return true;
        } else {
            return false;
        }
    }
    get(entity, id) {
        return new Promise(async (resolve, reject) => {

            const URL = `${config.URL_API_base}/${entity}/${id}`;

            try {
                const TokenAwait = localStorage.getItem('userToken');
                const Token = `Bearer ${TokenAwait}`;
                const response = await fetch(URL, {
                    method: "GET",
                    headers: {
                        "Authorization": Token,
                        Accept: 'application/json',
                        "Content-Type": "application/json"
                    },
                })
                console.log('> response log ::  ', response)
                if (!this.hasNoError(response)) {
                    reject('server error')
                } else {
                    resolve(response.json());
                }
            } catch (error) {
            }
        });
    }
    post(entity, data) {
        return new Promise(async (resolve, reject) => {
            const URL = `${config.URL_API_base}/${entity}`;
            console.log("data post %o", JSON.stringify(data));
            try {
                const TokenAwait = localStorage.getItem('userToken');
                const Token = `Bearer ${TokenAwait}`;
                const response = await fetch(URL, {
                    method: "POST",
                    headers: {
                        "Authorization": Token,
                        Accept: 'application/json',
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                })
                console.log('> response log ::  ', response)
                if (!this.hasNoError(response)) {
                    resolve(response.json());
                } else {
                    resolve(response.json());
                }
            } catch (e) {
                reject(e);
            }
        });
    }
    delete(entity, id) {
        return new Promise(async (resolve, reject) => {
            const URL = `${config.URL_API_base}/${entity}/${id}`;
            try {
                const TokenAwait = localStorage.getItem('userToken');
                const Token = `Bearer ${TokenAwait}`;
                const response = await fetch(URL, {
                    method: "DELETE",
                    headers: {
                        "Authorization": Token,
                        Accept: 'application/json',
                        "Content-Type": "application/json"
                    },
                })
                console.log('> response log ::  ', response)
                if (!this.hasNoError(response)) {
                    reject('server error')
                } else {
                    resolve(response.json());
                }
            } catch (e) {
                reject(e);
            }
        });
    }
    put(entity, data, id) {
        return new Promise(async (resolve, reject) => {
            const URL = `${config.URL_API_base}/${entity}/${id}`;

            try {
                const TokenAwait = localStorage.getItem('userToken');
                const Token = `Bearer ${TokenAwait}`;
                const response = await fetch(URL, {
                    method: "PATCH",
                    headers: {
                        "Authorization": Token,
                        Accept: 'application/json',
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data),
                })
                console.log('> response log ::  ', response)
                if (!this.hasNoError(response)) {
                    reject('server error')
                } else {
                    resolve(response.json());
                }
            } catch (e) {
                reject(e);
            }
        });
    }
    uploadImage(entity,data) {
        return new Promise(async (resolve, reject) => {
            const URL = `${config.URL_API_base}/${entity}`;
            console.log("data post %o", JSON.stringify(data));
            try {
                const TokenAwait = localStorage.getItem('userToken');
                const Token = `Bearer ${TokenAwait}`;


                const response = await fetch(URL, {
                    method: "POST",
                    headers: {
                       "Authorization": Token,
                        Accept: 'application/json',
                        "Content-Type": "multipart/form-data",
                        "zumo-api-version" : "2.0.0", 
                    },

                    body: data,
                })
                console.log('> response log ::  ', response)
                if (!this.hasNoError(response)) {
                    reject('server error')
                } else {
                    resolve(response.json());
                }
            } catch (e) {
                reject(e);
            }
        });
    }
    postDocs(entity, data) {
        return new Promise(async (resolve, reject) => {
            const URL = `${config.URL_API_base}/${entity}`;                        
            try {
                console.log('> URL ::  ', URL)
                const TokenAwait = await AsyncStorage.getItem('userToken');
                const Token = `Bearer ${TokenAwait}`;
                const response = await fetch(URL, {
                    method: "POST",    
                    headers: {
                        "Authorization": Token,
                        Accept: 'application/json',

                        "zumo-api-version" : "2.0.0",                     
                    },
                    body: data,//JSON.stringify(data),
                })
                console.log('> response log ::  ', response)
                if (!this.hasNoError(response)) {
                    reject('server error')
                } else {
                    resolve(response.json());
                }
            } catch (e) {
                reject(e);
            }            
        });
    }
    saveImage(entity, data){
        //return firebase.storage().ref(entity).putString(data, 'data_url');
    }

    getImage(entity){
        return firebase.storage().ref(entity).getDownloadURL();
    }
}

export default new ApiService();
