import React, { Component } from 'react';
import HomeScreen from './Auth/HomeScreen/HomeScreen';
import Register from './Auth/Register/Register';
import Login from './Auth/Login/Login';
import Training from './MainMenu/Training/Training';
import Contact from './MainMenu/Contact/Contact';
import TrainingContent from './MainMenu/TrainingContent/TrainingContent';
import TrainingModule from './MainMenu/TrainingModule/TrainingModule';
import Chat from './MainMenu/Chat/Chat';
import ChatGroup from './MainMenu/ChatWeb/ChatGroup';
import ChatPrivate from './MainMenu/ChatWeb/ChatPrivate';
import Webhook from './MainMenu/Webhook/webhook';
import {Provider} from 'react-redux';
import MessageStore from './MainMenu/ReduxStore/messageStore'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Backend from './notification.service';
import ApiService from './Shared/api.service';
import ReactPixel from 'react-facebook-pixel';

import Profil from './MainMenu/NewProfil/Profil'

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
  Switch
} from 'react-router-dom'

import "./assets/css/style.css";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  Animated,
  Easing,
} from 'react-native';
import logo from './logo.png';
import cookie from 'react-cookies'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") 
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

const mapStateToProps = (state) => {

  return {publicMessage: state.publicMessage, groupMessage: state.groupMessage, showGroup : state.showGroup, groupList : state.groupList, users : state.users};

}

class App extends Component {
  user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  static propTypes = {
    classes: PropTypes.object,
    state: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
      idToken: "",
      publicMessage : [],
      groupMessage : [],
      firstnameToken: "",
      lastnameToken : "",
      previousState : {},
    }
    
  }

  async componentDidMount() {

    if(this.user)
    {
      let users = await ApiService.post('users', {tabIds: []});
      Backend.getPublicMessage().on('value', (response) => {
        let messages = response.val();
        let tabIds = []

        // this._setPublicMessage(messages);
          for(var item in messages)
          {
            let user = users.rows.find(i => i._id == messages[item].user._id);
            if(user && user.avatarUrl && messages[item].user)
            {
              messages[item].user.avatar = user.avatarUrl;             
            }
            if(user && user.isTestUser)
            {
              messages[item].isTestUser = user.isTestUser
            }
            else
            {
              messages[item].isTestUser = false;
            }
          }
          this._setPublicMessage(messages);
        })

      ////////////GROUP ///////////////////
      let groupList = await ApiService.post('groups',{"price": 150} );
      groupList = groupList.rows;
      let groupArray = [];
      if(groupList && groupList.length>0)
      {
        for(let i=0; i<groupList.length; i++)
        {
          if(groupList[i].firebaseMessageId)
          {
            // 
          }
          /*if(groupList[i].members && groupList[i].members.some(e => e == this.user._id))
          {
            groupArray.push()
          }*/
        }
      }


      Backend.getGroup().on('value', (response) => {
        let group = response.val();
        let array = {};
        

        for(var id in group)
        {
          group[id].id = id;
          group[id].messages = [];
          Backend.getGroupMessage(group[id].message).on('value',(responseMessage)=> {
            
            let message = responseMessage.val();
            group[id].messages = [];
            // console.log("app show group : ",this.props.showGroup)
            // let oldShowGroup = this.props.showGroup;

            for(var ids in message )
            {

              /*message[ids].group = group[id];
              Object.assign(array,message);
              //this._setGroupMessage(array)*/
              // console.log("old group show : ",oldShowGroup)
              // let index = oldShowGroup.findIndex(e => e.id == ids);
              // oldShowGroup[index] = message[ids];
              
              // console.log("new group show : ",oldShowGroup)
              // const action = { type: "SHOW_GROUP_MESSAGE", value: [] }
              // this.props.dispatch(action);

              let user = users.rows.find(i => i._id == message[ids].user._id);
              message[ids].id = ids;
              message[ids].nameGroup = group[id].nameGroup;
              message[ids].group = group[id];
              if(user.isTestUser)
              {
                message[ids].isTestUser = user.isTestUser
              }
              else
              {
                message[ids].isTestUser = false;
              }
              
              group[id].messages.push(message[ids]);
              
            }
            this._setGroupList(group);
          })
        }

          this._setGroupList(group);    

      })

      Backend.getPrivateMessage().on('value', (response) => {
        let privee = response.val();
        this._setPrivateList(privee);
        let array = [];
        for(var idSender in privee)
        {
          
          for(var idMessage in privee[idSender].messageDiscus)
          {
            privee[idSender].messageDiscus[idMessage].header = privee[idSender].header;
            privee[idSender].messageDiscus[idMessage].idMessage = idMessage;
            array.push(privee[idSender].messageDiscus[idMessage]);
            //this._setPrivateMessage(array);
            //this._setPrivateName(privee);
          } 
        }
      })


      /*Backend.getGroupName().on('value', (response) => {
        let groupName = response.val();
        this._setGroupList(groupName);
      })*/
    }
  }

  _setUsers(users){
    const action = { type: "SET_USERS", value: users }
    this.props.dispatch(action);
  }

  _setPublicMessage(message)
  {
    const action = { type: "SET_PUBLIC", value: message }
    this.props.dispatch(action);
  }
  _setGroupMessage(message)
  {
    const action = { type: "SET_GROUP", value: message }
    this.props.dispatch(action);
  }
  _setPrivateMessage(message)
  {
    const action = { type: "SET_PRIVATE", value: message }
    this.props.dispatch(action);
  }
  _setGroupList(groupList) {
    const action = { type: "SET_GROUP_LIST", value: groupList }

    this.props.dispatch(action);
  }
  _setPrivateList(privateList) {
    const action = { type: "SET_PRIVATE_LIST", value: privateList }

    this.props.dispatch(action);
  }
  _setPrivateName(privateName) {
    const action = { type: "SET_PRIVATENAME", value: privateName }
    this.props.dispatch(action);
  }
  _setPrivateHeader(privateHeader) {
    const action = { type: "SET_PRIVATEHEADER", value: privateHeader }
    this.props.dispatch(action);
  }
  _setPrivateDiscus(privateDiscus) {
    const action = { type: "SET_PRIVATEDISCUS", value: privateDiscus }
    this.props.dispatch(action);
  }

  render() {
    return (
      <Provider store={MessageStore}>
        <Router>
          <div style={{height : "100%"}}>
            <Switch>
              <Route exact path='/' component={HomeScreen}></Route>
              <Route exact path='/register' component={Register}></Route>
              <Route exact path='/login' component={Login}></Route>
              
              <Route exact path="/webhook" component={Webhook}/>
              

              <PrivateRoute path='/training' component={Training} />
              <PrivateRoute path='/chatGroup/:nomGroupe/:idcreata/:users/:nameMessage' component={ChatGroup} />
              <PrivateRoute path='/chatPrivate/:user/:userName' component={ChatPrivate} />
              <PrivateRoute path='/webhook/:userId/:trainingId' component={Webhook}/>
              <PrivateRoute path='/contact/:url' component={Contact} />
              <Route exact path="/profil/:id/:previous?" component={Profil}/>
              <PrivateRoute path='/trainingContent/:id' component={TrainingContent} />
              <PrivateRoute path='/trainingModule/:id/:idModule/:num/:itemId' component={TrainingModule} />

              <PrivateRoute exact path="/profil" component={Profil}/>  
              <PrivateRoute exact path='/chat' component={Chat}/>    



              <PrivateRoute path='/chat/:location' component={Chat} />

            </Switch>
          </div>
        </Router>
      </Provider>

    );
  }
}



let hotWrapper = () => () => App;
if (Platform.OS === 'web') {
  const { hot } = require('react-hot-loader');
  hotWrapper = hot;
}
export default connect(mapStateToProps)(hotWrapper(module)(App));
