import ApiService from '../../Shared/api.service';
class ChatService {
    getUsers(data) {
        return ApiService.post('users', data)
    }
    getUser(id) {
        return ApiService.get('user', id)
    }
}

export default new ChatService();
