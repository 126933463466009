import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import StarRateIcon from '@material-ui/icons/StarRate';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';

const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
    secondary: { light: amber[300], main: amber[500], dark: amber[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const styles = theme =>  ({
  main:{
    display: "block",
    width: "100%",
    margin: "auto",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "60%"
    },
  },

  center:{
    textAlign: 'center',
    marginTop: 0,
    width: "100%"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"95%",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"95%",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    borderColor: "white",
    borderRadius: "15px",
    backgroundColor: '#F4F5F9',
    width: "95%",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "100%"
    },


  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: teal["A700"],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: teal["A700"],
    },
  },
  notchedOutline: {

    borderColor:'#FFFFFF !important',

  },
  cssLabel: {
    '&$cssFocused': {
      color: teal["A700"],
    },
  },
  chip:{
    backgroundColor: amber[500],
    color: "#ffffff"
  },
  pro:{
    textAlign:"left",
    marginTop: "20px"
  }
});

class StepThree extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.register =  props.register;
    console.log("main %o",this.classes);
  }
  render(){
    return (
      <main className={this.classes.main}>


              <div  className={this.classes.pro}>
                <Chip label="PRO" className={this.classes.chip}
                      color="primary"
                      icon={<StarRateIcon />}
                 />
              </div>
              <div  className={this.classes.center}>
                <TextValidator

                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}

                  label="Company name"
                  className={this.classes.textField}
                  type="text"
                  name="companyName"

                  margin="normal"
                  variant="outlined"

                  value={this.register.state.data.companyName}

                  onChange={this.register.handleChange}
                />
              </div>

              <div  className={this.classes.center}>
                <TextValidator

                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}

                  label="VAT number"
                  className={this.classes.textField}
                  type="text"


                  margin="normal"
                  variant="outlined"

                  name="VATNumber"
                  value={this.register.state.data.VATNumber}
                  onChange={this.register.handleChange}
                />
              </div>
              <div  className={this.classes.center}>
                <Grid container spacing={24}>
                  <Grid item xs={8}>
                        <TextValidator

                          InputProps={{
                            classes: {
                              root: this.classes.cssOutlinedInput,
                              focused: this.classes.cssFocused,
                              notchedOutline: this.classes.notchedOutline,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: this.classes.cssLabel,
                              focused: this.classes.cssFocused,
                            },
                          }}

                          label="Street name"
                          className={this.classes.textField}
                          type="text"


                          margin="normal"
                          variant="outlined"

                          name="streetName"
                          value={this.register.state.data.streetName}
                          onChange={this.register.handleChange}
                        />
                  </Grid>
                  <Grid item xs={4}>
                        <TextValidator

                            InputProps={{
                              classes: {
                                root: this.classes.cssOutlinedInput,
                                focused: this.classes.cssFocused,
                                notchedOutline: this.classes.notchedOutline,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: this.classes.cssLabel,
                                focused: this.classes.cssFocused,
                              },
                            }}

                            label="Nb"
                            className={this.classes.textField}
                            type="text"


                            margin="normal"
                            variant="outlined"

                            name="Nb"
                            value={this.register.state.data.Nb}
                            onChange={this.register.handleChange}
                        />
                  </Grid>
                </Grid>


              </div>

              <div  className={this.classes.center}>
                <Grid container spacing={24}>
                  <Grid item xs={6}>
                        <TextValidator

                          InputProps={{
                            classes: {
                              root: this.classes.cssOutlinedInput,
                              focused: this.classes.cssFocused,
                              notchedOutline: this.classes.notchedOutline,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: this.classes.cssLabel,
                              focused: this.classes.cssFocused,
                            },
                          }}

                          label="Zipcode"
                          className={this.classes.textField}
                          type="text"


                          margin="normal"
                          variant="outlined"

                          name="zipCode"
                          value={this.register.state.data.zipCode}
                          onChange={this.register.handleChange}
                        />
                  </Grid>
                  <Grid item xs={6}>
                        <TextValidator

                            InputProps={{
                              classes: {
                                root: this.classes.cssOutlinedInput,
                                focused: this.classes.cssFocused,
                                notchedOutline: this.classes.notchedOutline,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: this.classes.cssLabel,
                                focused: this.classes.cssFocused,
                              },
                            }}

                            label="City"
                            className={this.classes.textField}
                            type="text"


                            margin="normal"
                            variant="outlined"

                            name="city"
                            value={this.register.state.data.city}
                            onChange={this.register.handleChange}
                        />
                  </Grid>
                </Grid>


              </div>

              <div  className={this.classes.center}>
                <TextValidator

                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}

                  label="Area"
                  className={this.classes.textField}
                  type="text"


                  margin="normal"
                  variant="outlined"

                  name="area"
                  value={this.register.state.data.area}
                  onChange={this.register.handleChange}
                />
              </div>

              <div  className={this.classes.center}>
                <TextValidator

                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}

                  label="Country"
                  className={this.classes.textField}
                  type="text"


                  margin="normal"
                  variant="outlined"

                  name="country"
                  value={this.register.state.data.country}
                  onChange={this.register.handleChange}
                />
              </div>

        </main>

    );
  }
}

export default withStyles(styles)(StepThree);
