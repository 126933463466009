import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import teal from '@material-ui/core/colors/teal';
import { Link, Redirect } from 'react-router-dom';
import SwipeableTemporaryDrawer from './Sidebar';
import Icon from '@material-ui/core/Icon';
import Responsive from 'react-responsive';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  header:{
    backgroundColor: "transparent !important"
  }
};

function Header(props) {
  const { classes } = props;
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  if(!user){
    return (<Redirect to='/login' />)
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>

          <Typography variant="h6" color="inherit" className={classes.grow}>
          <Link style={{color:'inherit', textDecoration: 'none',display:"inline"}} to={props.previous} >
                <IconButton onClick={()=>{console.log(props.previous)}}  color="inherit" aria-label="Menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 18 18"><path d="M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z"/></svg>
                </IconButton>
           </Link>
           <span> {props.title}</span>
          </Typography>
          <Button color="inherit">Bonjour {user.firstname} {user.lastname}</Button>

          <SwipeableTemporaryDrawer actualLink={props.actualLink?props.actualLink:""}/>
        </Toolbar>
      </AppBar>
    </div>
  );
}


Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
