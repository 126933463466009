import ApiService from '../../Shared/api.service';
class TrainingContentService {
    getUser(id) {
        return ApiService.get('user', id)
    }
    getModules(data) {
        return ApiService.post('modules', data)
    }
    getTraining(data){
        return ApiService.post('userTrainingRatings',data)
    }
    getModule(id) {
        return ApiService.get('training/' + id, 'modules')
    }
    postUserTraining(data){
        return ApiService.post('userTrainingRating',data)
    }
    getVideos(data) {
        return ApiService.post('videos', data)
    }
    getVideosLocal(data) {
        return ApiService.post('userVideoLocals', data)
    }
    addVideoLocal(data) {
        return ApiService.post('userVideoLocal', data)
    }
   /* getTraining(data){
      return ApiService.post()
    }*/

    getTrainingById(id){
      return ApiService.get("training", id)
    }
}

export default new TrainingContentService();
