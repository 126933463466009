import firebase from 'firebase';

const configFirebase = {
    apiKey: "AIzaSyAAVROIXtOT4dDvzexY1GNkImWmavOXUbU",
    authDomain: "apl-web-897ae.firebaseapp.com",
    databaseURL: "https://apl-web-897ae.firebaseio.com",
    projectId: "apl-web-897ae",
    storageBucket: "apl-web-897ae.appspot.com",
    messagingSenderId: "641666235426",
    appId: "1:641666235426:web:6e2eb7409bd17007"
  };

  const Firebase = firebase.initializeApp(configFirebase,'test');
  export default Firebase;