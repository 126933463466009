import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import {connect} from 'react-redux';
import ApiService from '../../Shared/api.service';


import {
  Redirect,
} from 'react-router-dom';

import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import config from '../../path';
import { AsyncStorage } from 'react-native';
import cookie from 'react-cookies';

import Backend from '../../notification.service';

import ReactPixel from 'react-facebook-pixel';
const mapStateToProps = (state) => {
  return {publicMessage: state.publicMessage, groupMessage: state.groupMessage, users: state.users};
}


const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const styles = theme =>  ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    // paddingBottom: theme.spacing.unit * 8
  },
  card: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    borderRadius: "12px"
    // marginBottom: theme.spacing.unit * 8,
  },
  media: {
    height: 200,
    backgroundPosition: 'top',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      height: 350,
    },
  },
  logo: {
    width: "80%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  center:{
    textAlign: 'center',
    marginTop: 0
  },
  social:{
    textAlign: 'center',
    marginTop: 40,
    width: "100%"
  },
  footer:{
    textAlign: 'center',
    marginTop: 40,
    color: "#42859F",
    textTransform: "uppercase"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"90%",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"90%",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
    marginRight: "20px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    borderColor: "white",
    borderRadius: "15px",
    backgroundColor: '#F4F5F9',
    width: "90%",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "60%"
    },


  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: teal["A700"],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: teal["A700"],
    },
  },
  notchedOutline: {

    borderColor:'#FFFFFF !important',

  },
  cssLabel: {
    '&$cssFocused': {
      color: teal["A700"],
    },
    transform : "translateY(-16px)"
  },
  loading:{
    textAlign: 'center',
    marginTop: "190px",
    height: "300px"
  },
  circularProgress:{
    color: teal[300]
  },
  title:{
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  submit:{
    textAlign: 'center',
    marginTop: "20px",
  },
  icon:{
    color: amber[500]
  },
  error:{
    color: "#FF0000 !important",
    textAlign: "center"
  }
});

class Login extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
  }
  user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
            data: {},
            error: false,
            errorMessage: "",
            isLoading:false,
            authenticated:false,
        };

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirect = this.redirect.bind(this);


  }

  handleChange(event) {
        const { data } = this.state;
        data[event.target.name] = event.target.value;
        this.setState({ data });
  }
  componentDidMount(){
    ReactPixel.init("714205238765459");
    ReactPixel.pageView();        
    if(cookie.load('user')){
      console.log("user cookie",cookie.load('user'));
      console.log("userToken cookie",cookie.load('userToken'));
      console.log("IdTokenPhone cookie",cookie.load('IdTokenPhone'));
      console.log("idToken cookie",cookie.load('idToken'));
      console.log("firstnameToken cookie",cookie.load('firstnameToken'));
      console.log("lastnameToken cookie",cookie.load('lastnameToken'));
      localStorage.setItem('user', JSON.stringify(cookie.load('user')));
      localStorage.setItem('userToken',cookie.load('userToken'));
      localStorage.setItem('IdTokenPhone',cookie.load('IdTokenPhone'));
      localStorage.setItem('idToken',cookie.load('idToken'));
      localStorage.setItem('firstnameToken',cookie.load('firstnameToken'));
      localStorage.setItem('lastnameToken',cookie.load('lastnameToken'));
      return <Redirect to="/training" />
    }


  }
  redirect(){
    return <Redirect to='/training' />;
  }
  handleSubmit(){
        // console.log("handle sublit")
        const URL = `${config.URL_API_base}/login`;
        this.setState({
          isLoading: true,
        })
        return fetch(URL, {
          method: "POST",
          headers: {
            "Authorization":"1234567",
            Accept: 'application/json',
            "Content-Type": "application/json",
            "ZUMO-API-VERSION" : "2.0.0"
          },
          body: JSON.stringify({
            email : this.state.data.email,
            password : this.state.data.password,
            mobile : true
          }),
        })
          .then((response) => response.json())
          .then(async (responseJson) => {

            if(responseJson.token !== null && responseJson.user){

              let userResp = responseJson.user;
              localStorage.setItem('user', JSON.stringify(responseJson.user));
              cookie.save('user',JSON.stringify(responseJson.user),{ path: '/' })
              localStorage.setItem('userToken', responseJson.token);
              cookie.save('userToken',responseJson.token,{ path: '/' })
               localStorage.setItem('IdTokenPhone', this.state.token);
               cookie.save('IdTokenPhone',this.state.token)
               localStorage.setItem('idToken', responseJson.user._id,{ path: '/' });
               cookie.save('idToken',responseJson.user._id)
               localStorage.setItem('firstnameToken', responseJson.user.firstname);
               cookie.save('firstnameToken',responseJson.user.firstname,{ path: '/' })
               localStorage.setItem('lastnameToken', responseJson.user.lastname);
               cookie.save('lastnameToken',responseJson.user.lastname,{ path: '/' })
              this.setState({
                isLoading: false,
                isError: false,
                authenticated: true,
              });

              //backend init
              if(userResp)
              {
                let users = await ApiService.post('users', {tabIds: []});
      Backend.getPublicMessage().on('value', (response) => {
        let messages = response.val();
        let tabIds = []

          for(var item in messages)
          {
            let user = users.rows.find(i => i._id == messages[item].user._id);
            if(user && user.avatarUrl && messages[item].user)
            {
              messages[item].user.avatar = user.avatarUrl;             
            }
            if(user && user.isTestUser)
            {
              messages[item].isTestUser = user.isTestUser
            }
            else
            {
              messages[item].isTestUser = false;
            }
          }
          this._setPublicMessage(messages);
        })

      ////////////GROUP ///////////////////
      let groupList = await ApiService.post('groups',{"price": 150} );
      groupList = groupList.rows;
      let groupArray = [];
      if(groupList && groupList.length>0)
      {
        for(let i=0; i<groupList.length; i++)
        {
          if(groupList[i].firebaseMessageId)
          {
          }
        }
      }


      Backend.getGroup().on('value', (response) => {
        let group = response.val();
        let array = {};
        

        for(var id in group)
        {
          group[id].id = id;
          group[id].messages = [];
          Backend.getGroupMessage(group[id].message).on('value',(responseMessage)=> {
            
            let message = responseMessage.val();
            group[id].messages = [];

            for(var ids in message )
            {

              let user = users.rows.find(i => i._id == message[ids].user._id);
              message[ids].id = ids;
              message[ids].nameGroup = group[id].nameGroup;
              message[ids].group = group[id];
              if(user.isTestUser)
              {
                message[ids].isTestUser = user.isTestUser
              }
              else
              {
                message[ids].isTestUser = false;
              }
              
              group[id].messages.push(message[ids]);
              
            }
            this._setGroupList(group);
          })
        }

          this._setGroupList(group);    

      })

      Backend.getPrivateMessage().on('value', (response) => {
        let privee = response.val();
        this._setPrivateList(privee);
        let array = [];
        for(var idSender in privee)
        {
          
          for(var idMessage in privee[idSender].messageDiscus)
          {
            privee[idSender].messageDiscus[idMessage].header = privee[idSender].header;
            privee[idSender].messageDiscus[idMessage].idMessage = idMessage;
            array.push(privee[idSender].messageDiscus[idMessage]);
          } 
        }
      })
              }
            }
            else{
              let users = await ApiService.post('users', {tabIds: []});
              let userList = users.rows;
              if(!userList.some(e => e.email == this.state.data.email))
              {
                this.setState({
                isLoading: false,
                error: true,
                errorMessage: "Email inexistant, inscrivez vous",
                });
              }
              else
              {
                this.setState({
                isLoading: false,
                error: true,
                errorMessage: "Mot de passe invalide",
                });
              }
              
              console.log(this.state);
            }
          })
          .catch((error) =>{
            console.log(error);
          });
  }

    _setPrivateList(privateList) {
    const action = { type: "SET_PRIVATE_LIST", value: privateList }
    this.props.dispatch(action);
  }

  _setGroupList(groupList) {
    const action = { type: "SET_GROUP_LIST", value: groupList }
    this.props.dispatch(action);
  }
  _setPublicMessage(message)
  {
    const action = { type: "SET_PUBLIC", value: message }
    this.props.dispatch(action);
  }

  render(){
    if(cookie.load('user')){

      return(
        <Redirect to='/' />
      );
    }else{
      return (
        <main className={this.classes.main} theme={theme}>

              <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        instantValidate
                    >


                <Typography align="right"  className={this.classes.title}>
                  <Link to="/" className={this.classes.link}>
                    <i className="material-icons">
                    chevron_left
                    </i>
                  </Link>
                  Connexion
                </Typography>
                <Card className={this.classes.card}>

                    { !this.state.isLoading ?
                    <CardContent>


                      <img src={logo} alt="Logo" className={this.classes.logo}/>

                      { this.state.error ?
                        <p className={this.classes.error}>
                          {this.state.errorMessage}
                        </p>
                      : ""}
                      <div  className={this.classes.center}>
                        <TextValidator

                          InputProps={{
                            classes: {
                              root: this.classes.cssOutlinedInput,
                              focused: this.classes.cssFocused,
                              notchedOutline: this.classes.notchedOutline,
                            },
                            startAdornment: <InputAdornment position="start" className={this.classes.icon}> <i className="material-icons" >mail_outline</i> </InputAdornment>,
                          }}
                          InputLabelProps={{
                            classes: {
                              root: this.classes.cssLabel,
                              focused: this.classes.cssFocused,
                            },
                          }}
                          id="outlined-email-input"
                          label="Email"


                          className={this.classes.textField}
                          type="email"

                          autoComplete="email"
                          margin="normal"
                          variant="outlined"

                          name="email"
                          value={this.state.data.email}
                          key={1}
                          validators={['required', 'isEmail']}

                          onChange={this.handleChange}
                          errorMessages={['this field is required', 'email is not valid']}

                        />
                      </div>

                      <div  className={this.classes.center}>
                        <TextValidator

                          InputProps={{
                            classes: {
                              root: this.classes.cssOutlinedInput,
                              focused: this.classes.cssFocused,
                              notchedOutline: this.classes.notchedOutline,
                            },
                            startAdornment: <InputAdornment position="start" className={this.classes.icon}> <i className="material-icons" >lock</i> </InputAdornment>,

                          }}
                          InputLabelProps={{
                            classes: {
                              root: this.classes.cssLabel,
                              focused: this.classes.cssFocused,
                            },

                          }}

                          label="Mot de passe"
                          className={this.classes.textField}
                          type="password"

                          margin="normal"
                          variant="outlined"

                          name="password"
                          value={this.state.data.password}
                          key={2}
                          validators={['required']}

                          onChange={this.handleChange}
                          errorMessages={['this field is required']}
                        />
                      </div>

                      

                      <div className={this.classes.submit}>

                        <Fab
                        type="submit"
                        onClick={this.nextStep} variant="extended" aria-label="Add" className={this.classes.button}>
                          CONTINUER
                        </Fab>
                      </div>

                      <div style={{textAlign: "right",marginRight: "10%", marginTop: "20px"}}>
                        <Link to="/register">
                          S'inscrire
                        </Link>
                      </div>


                    </CardContent>
                    : <div className={this.classes.loading}><CircularProgress className={this.classes.circularProgress} disableShrink /></div> }



                </Card>
                </ValidatorForm>

          </main>
      );

    }

  }
}

export default connect(mapStateToProps)(withStyles(styles)(Login));
