import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import LinearGradient from 'react-native-web-linear-gradient';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import ChatService from "./Chat.service.js";
import Backend from './Firebase.service.js';
import {GiftedChat,Send,View} from 'react-web-gifted-chat'
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import femme from './../../assets/images/avatar/profile.png';
import AddGroupeSlide from './AddGroupe.js'
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import { Link } from 'react-router-dom';
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ height:"70vh",overflow:"auto",position:"relative", padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "auto",
  },
  button: {
    margin: theme.spacing.unit,
  },
  iconMenu:{
    color: "#767676"
  },
  card:{
    padding:"1.5em",
    margin:"1em"
  },
  swipableDrawer:{
    overflow:"auto",
    height:"80%"
  },
  text:{
    fontSize:"16px",
    color:"#709fa5"
  },
  textMin:{
    fontSize:"12px",
    color:"#b9bdbc",
    height:"12px",
  }
});

class MessagesGroup extends React.Component {
  constructor(props){
    super(props)
    //console.log("props.groupParams",this.props.groupParams)
    console.log("props.groupParams",JSON.parse(localStorage.getItem("groupParams")))
  }
  state = {
    value: 0,
    text:"",
    messages: [],
    idToken : "idToken",
    firstnameToken : "firstnameToken",
    lastnameToken : "lastnameToken",
    users:[1,2,3,4],
    dataGroup:[],
    dataGroupInit:[]
  };
  
  
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  componentDidMount(){
    const groupParams = JSON.parse(localStorage.getItem("groupParams"))
    const idToken =  localStorage.getItem('idToken');
    const firstnameToken =  localStorage.getItem('firstnameToken');
    const lastnameToken =  localStorage.getItem('lastnameToken');
    const idcreate = groupParams.idcreata;
    const nameGroup=groupParams.nameGroup;
    const nameMessage=groupParams.nameMessage;
    const users=groupParams.users;
    this.setState({
      idToken : idToken,
      firstnameToken :firstnameToken ,
      lastnameToken : lastnameToken,
    })
    
    let self = this;
    let data=[]
    Backend.sendNameMessageGroupExist(nameMessage, users);
   
    Backend.loadMessagesGroup( message => {
          //console.log("-----------------------------------------------------"+message+"----------------------")
          this.setState(previousState => {
          return {
            messages: GiftedChat.append(previousState.messages, message)
          };
        });      
    });
    
  }
  onSend(messages = []) {
    console.log(messages);

    this.setState((previousState) => ({
      messages: GiftedChat.append(previousState.messages, messages),
    }));
  }
  setCustomText(text){
    this.setState({text:text})
  }
  onSendM(props){
    let self = this;
    var today = new Date();
    let timestamp = today.toISOString();
    console.log("messages",this.state.messages);
    console.log("prosp",props)
    const idToken =  localStorage.getItem('idToken');
    const firstnameToken =  localStorage.getItem('firstnameToken');
    const lastnameToken =  localStorage.getItem('lastnameToken');
    let messages = [{
      createdAt:timestamp,
      text:props.text,
      user:props.user
    }]
    
    console.log(messages)
    Backend.sendMessageGroup(messages);
    self.setState({
      text:''
    })
  }
  
  buttonSend(props){
  
    return (
      <>
        {props.text ?
           <Button onClick={()=>{
             props.self.onSendM(props)
             /*Backend.sendSingle({
               text:props.text,
               user:props.user
             })
             props.self.setState({text:''})*/             
         }} 
           style={{color:"blue",}}>
          <Icon>send</Icon>
         </Button>:""
        }  
      </>
    );
  }
  renderChat(){
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const idTokenC = this.state.idToken;
    const firstnameTokenC = this.state.firstnameToken;
    const lastnameTokenC = this.state.lastnameToken;
    console.log("idTockenC",idTokenC)
    return (
      <GiftedChat
        messages={this.state.messages}
        
        onSend={message => {
          Backend.sendMessage(message);
        }}
        user={{
          _id: idTokenC, //Backend.getUid(),
          name: `${firstnameTokenC} ${lastnameTokenC}`,
        }}
      />
    );
  }
  async listContacts(data){
        try{
        const responseJson = await ChatService.getUsers(data);
        console.log("responseJson %o", responseJson);
        if(responseJson){
            this.setState({
                users: responseJson.rows,
            }, function(){
              //console.log("users %o",this.state.users)
          });
        }
        } catch (e) {
        console.error(e);
      }
    }
  render() {
    const idTokenC = this.state.idToken
    const firstnameTokenC = this.state.firstnameToken;
    const lastnameTokenC = this.state.lastnameToken;
    const { classes, theme } = this.props;
    let self = this
    const { anchorEl } = this.state;
    
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const flexContainer = {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
    }
    const icon ={
      color:"#c3dbdd",
      margin:"auto",
      verticalAlign:"middle"
    }
    const adminButton={
      background:"#85b4bf",
      borderLeft:"outset 1.5px #c0e1d0",
      borderRight:"outset 1.5px #c0e1d0"
    }
    return (
     
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            
            <Tab label={
              <SvgIcon width={20} height={20} viewBox="0 0 640 512" style={icon}>
                
                <path d="M320 64c57.99 0 105 47.01 105 105s-47.01 105-105 105-105-47.01-105-105S262.01 64 320 64zm113.463 217.366l-39.982-9.996c-49.168 35.365-108.766 27.473-146.961 0l-39.982 9.996C174.485 289.379 152 318.177 152 351.216V412c0 19.882 16.118 36 36 36h264c19.882 0 36-16.118 36-36v-60.784c0-33.039-22.485-61.837-54.537-69.85zM528 300c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70zm-416 0c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70zm24 112v-60.784c0-16.551 4.593-32.204 12.703-45.599-29.988 14.72-63.336 8.708-85.69-7.37l-26.655 6.664C14.99 310.252 0 329.452 0 351.477V392c0 13.255 10.745 24 24 24h112.169a52.417 52.417 0 0 1-.169-4zm467.642-107.09l-26.655-6.664c-27.925 20.086-60.89 19.233-85.786 7.218C499.369 318.893 504 334.601 504 351.216V412c0 1.347-.068 2.678-.169 4H616c13.255 0 24-10.745 24-24v-40.523c0-22.025-14.99-41.225-36.358-46.567z" />
               
              </SvgIcon>
            } />
            
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
          SwipeAreaProps={{height:"50vh",overflow:"scroll"}}
          ModalProps={{BackdropProps:{
            classes:{
              root:classes.swipableDrawer
            }
          }}}
        >
        
        <LinearGradient
            colors={['#3b8698', '#489b71']}
            start={{ x: 1, y: 0 }}
            end={{ x: 1, y: 1 }}
         >
          <TabContainer style={{overflow:"auto",height:"300px"}} dir={theme.direction}>
           
            <GiftedChat
              messages={this.state.messages}
              
              self={this}
              renderSend={this.buttonSend}
              text={this.state.text}
              onInputTextChanged={text => this.setState({text:text})}
              user={{
                _id: idTokenC, //Backend.getUid(),
                name: `${firstnameTokenC} ${lastnameTokenC}`,
              }}
            />            
          </TabContainer>
         </LinearGradient>
        </SwipeableViews>

      </div>
    );
  }
}

MessagesGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MessagesGroup);
