import ApiService from '../../Shared/api.service';

class TrainingModuleService {
    getVideos(data) {
        return ApiService.post('videos', data)
    }
    getVideo(data) {
        return ApiService.get('video', data)
    }
    getTraining(id){
      return ApiService.get("training", id)
    }
    getModules(data) {
        return ApiService.post('modules',data)
    }
    getOneModules(id) {
        return ApiService.get('module', id)
    }
    getComments(data) {
        return ApiService.post('userVideoComments', data)
    }
    addComment(data) {
        return ApiService.post('userVideoComment', data)
    }
    getUsers(data) {
        return ApiService.post('users', data)
    }
    getUser(id) {
        return ApiService.get('user', id)
    }

}
export default new TrainingModuleService();
