import React, {useState, Component} from 'react';
import lifecycle from 'react-pure-lifecycle';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import teal from '@material-ui/core/colors/teal';
import { Link, Redirect } from 'react-router-dom';
import SwipeableTemporaryDrawer from './Sidebar';
import Icon from '@material-ui/core/Icon';
import {connect} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Backend from '../../notification.service.js';
import Badge from '@material-ui/core/Badge';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
  return {publicMessage: state.publicMessage , groupMessage : state.groupMessage, privateMessage: state.privateMessage, selectPrivate : state.selectPrivate, curPrivate: state.curPrivate}
}

const styles = {
  root: {
    flexGrow: 1,
  },
  chevron : {
      top : 0,
      fontSize: "32px"

  },
  topbar : {
    display : 'flex',
    height : '50px',
    flexDirection : 'row',
    color: 'white',
    alignItems: 'center',
    paddingLeft: "8px"
  },
  title :{
    fontWeight : '400'
  },
  right : {
    top : 0,
    marginLeft: 'auto',
    right: 0,
    fontSize: "32px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    style={{transform: "translateX(-64px)"}}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: "white",
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function dateConversion(value)
{
  let months = ['Jan','Feb','Mar',]
  let date = new Date(value);
  let minutes = 0;
  if(date.getMinutes()<=9)
    minutes = '0'+date.getMinutes();
  else
    minutes = date.getMinutes();
  
  return date.toLocaleDateString() + ' ' + date.getHours() + ":" + minutes
}

const componentDidMount = (props) => {
  console.log("mounted")
}
const componentDidUpdate = (props) => {
  console.log("updated")
}
const methods = {
  componentDidMount,
  componentDidUpdate
};
function Search(props)
{
  const search = props.search
  const classes = props.classes
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  if(search)
  {
    return <Icon  style={{'marginRight' : '12px'}}>search</Icon>;
  }
}

function Headers(props) {
  const { classes, previous } = props;
  const [redirect, setRedirect] = useState(false);
  const [redirectPrivate, setRedirectPrivate] = useState(false);
  const [redirectPublic, setRedirectPublic] = useState(false);
  const [redirectGroup, setRedirectGroup] = useState(false);
  const [urlParams,setUrlParams] = useState("");
  var Nnotif = 0;
  var i = 0;
  var globalMessage = [];
  const user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;
  if(!user){
    return (<Redirect to='/login' />)
  }


  const handleRedirect = () => {
  setRedirect(true);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if(globalMessage.length == 0){

      props.history.push("/profil");
    }else{
      setAnchorEl(event.currentTarget);
    }
    
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function clickedPublicMessage(item) {
    //Backend.readPublicMessage(item.id);
    props.history.push("/chat/public");
    //setRedirectPublic(true);
    //Nnotif--;
  }

  function clickedGroupMessage(item) {
    console.log(item)
    const action = { type: "SET_CURGROUP", value: item }
    props.dispatch(action);
    props.history.push("/chat/group");
  }

  function clickedPrivateMessage(item) {
    console.log(item)
    const action = { type: "SET_CURPRIVATE", value: item }
    props.dispatch(action);
    props.history.push("/chat/private");
  }
  
  if(props.publicMessage)
  {
    const message = props.publicMessage;
    for(var i=0; i<message.length; i++)
    {
      if(message[i].read==false && message[i].mention && message[i].mention.user.id == user._id)
      {
        Nnotif= Nnotif + 1;
        globalMessage.push(message[i]);
      }
    }
    globalMessage.sort(function(a,b){
      let _a = Date.parse(a.createdAt);
      let _b = Date.parse(b.createdAt)
      return _b - _a;
    })
  }
  if(props.groupMessage)
  {
    const message = props.groupMessage
    for(var i=0; i<message.length; i++)
    {
      if(message[i].read==false && message[i].mention && message[i].mention.user.id == user._id)
      {
        Nnotif= Nnotif + 1;
        globalMessage.push(message[i]);
      }
    }
    globalMessage.sort(function(a,b){
      let _a = Date.parse(a.createdAt);
      let _b = Date.parse(b.createdAt)
      return _b - _a;
    })
  }

  if(props.privateMessage)
  {
    const message = props.privateMessage
    for(var i=0; i<message.length; i++)
    {
      if(message[i].user._id != user._id && message[i].read==false)
      {
        Nnotif= Nnotif + 1;
        globalMessage.push(message[i]);
      }
    }
    globalMessage.sort(function(a,b){
      let _a = Date.parse(a.createdAt);
      let _b = Date.parse(b.createdAt)
      return _b - _a;
    })
  }

  if(!user){
    return (<Redirect to='/login' />)
  }
  if(redirect){
    return (<Redirect to={props.previous} />)
  }
  if(redirectPublic){
    return (<Redirect to='/chat' />)
  }
  if(redirectGroup){
    return (<Redirect to='/chat/group' />)
  }
  if(redirectPrivate){
    return (<Redirect to='/chat/876547' />)
  }
  if(props.search)
  {
    return (
     <div className={classes.topbar}>
       <Icon className={classes.chevron} onClick={handleRedirect}>chevron_left</Icon>
       <h3 className={classes.title}>{props.title}</h3>
       <Icon  className={classes.right} style={{'marginRight' : '12px'}}>search</Icon>
       <Badge className={classes.margin} badgeContent={Nnotif} color="secondary">
          <PermIdentityIcon 
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}/> 
        </Badge>   
        
       <SwipeableTemporaryDrawer actualLink={props.actualLink?props.actualLink:""}/>
       <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      {globalMessage.length > 0 ?
        <div>
        { globalMessage.map( (item, i) => (
          <div>
            {item.read == false && item.mention && item.group &&
              <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {clickedGroupMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>{item.group.nameGroup} : </span>
                    {item.text}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }
            {item.read == false && item.mention && item.type == "public" &&
            <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {clickedPublicMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>Public : </span>
                    {item.text}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }
            {item.read == false && item.type == "private" &&
            <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {clickedPrivateMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>Privée : </span>
                    {item.message}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }

          </div>))}
        </div>
        :
        <div style={{padding: "16px"}}>Aucune notification</div>
      }
        </StyledMenu>
    </div>

    );
  }
  else
  {
    return (
     <div className={classes.topbar}>
       <Icon className={classes.chevron} onClick={handleRedirect}>chevron_left</Icon>
       <h3 className={classes.title}>{props.title}</h3>
       <div className={classes.right}>
       <Badge className={classes.margin} badgeContent={Nnotif} color="secondary">
          <PermIdentityIcon 
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}/> 
        </Badge>   
        <SwipeableTemporaryDrawer className={classes.right} actualLink={props.actualLink?props.actualLink:""}/>
        
       </div>

       <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      {globalMessage.length > 0 ?
        <div>
        { globalMessage.map( (item, i) => (
          <div>
            {item.read == false && item.mention && item.group &&
              <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {clickedGroupMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>{item.group.nameGroup} : </span>
                    {item.text}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }
            {item.read == false && item.mention && item.type == "public" &&
            <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {clickedPublicMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>Public : </span>
                    {item.text}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }
            {item.read == false && item.type == "private" &&
            <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {clickedPrivateMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>Privée : </span>
                    {item.message}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }

          </div>))}
        </div>
        :
        <Redirect to='/profil' />
      }
        </StyledMenu>

    </div>
    );
  }
}



Headers.propTypes = {
  classes: PropTypes.object.isRequired,
  previous: PropTypes.string.isRequired
};

export default withRouter(connect(mapStateToProps)(lifecycle(methods)(withStyles(styles)(Headers))));
