import React, { Component, Fragment } from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ReactHtmlParser from 'react-html-parser';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import VideocamIcon from '@material-ui/icons/Videocam';
import StarIcon from '@material-ui/icons/Star';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SendIcon from '@material-ui/icons/Send';
//import ReactPlayer from 'react-player'
import Rating from 'material-ui-rating';

import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import femme from './../../assets/images/video/femme.jpeg';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import config from '../../path';
import TrainingModuleService from "./TrainingModule.service.js";
import { Player } from 'video-react';
import { display } from '@material-ui/system';
import Header from '../Layout/newHeader';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import ReactPixel from 'react-facebook-pixel';

const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const styles = theme =>  ({
  main: {
    width: '70%',
    display: 'block', // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '70%',
    margin: "auto"
    },

    // paddingBottom: theme.spacing.unit * 8
  },
  mainMobile: {
    width: '100%',
    display: 'block', // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '100%',
    margin: "auto"
    },

    // paddingBottom: theme.spacing.unit * 8
  },
  loading:{
    textAlign: 'center',
    marginTop: "190px",
    height: "300px"
  },
  card:{
    width: "100%",
    // marginTop: theme.spacing.unit * 2,
    borderRadius: "0 0px 12px 12px"
  },
  media: {
    height: 140,
  },
  logo: {
    width: "80%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  center:{
    textAlign: 'center',
    marginTop: 20
  },
  social:{
    textAlign: 'center',
    marginTop: 40
  },
  footer:{
    textAlign: 'center',
    marginTop: 40,
    color: "#42859F",
    textTransform: "uppercase"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"300px",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"100%",
    fontWeight: "bold",
    position: "relative",
    top: "35%"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  titlePrimary:{
    color: "#3B808E",
    fontSize: "1.4em",
    marginBottom: "10px",
    fontWeight: "bold"
  },
  textPrimary:{
    color: "#3B808E",
    fontSize: "1em",
    marginBottom: "10px",
    marginTop: "10px",
  },
  text:{
    color:"#777777"
  },
  textAccent:{
    color:"#72E297"
  },
  icon:{
    position: "relative",
    top: "5px",
    color: amber[500]
  },
  divider:{
    marginTop: "10px"
  },
  comment:{
    borderRadius: "10px 10px 10px 0px",
    marginTop: "10px",
    padding: "15px",
    backgroundColor: "#FFFFF",
    color : "white",
   // marginLeft:"1em"

  },
  commentMobile:{
    borderRadius: "10px 10px 10px 0px",
    marginTop: "10px",
    padding: "15px",
    backgroundColor: "#FFFFF",
    color : "white",
    marginLeft:"1em"

  },
  myComment:{
    borderRadius: "10px 10px 0px 10px",
    marginTop: "10px",
    padding: "15px",
    backgroundColor: "#3C8799",
    color : "white"
  },
  textField:{
    width: "70%",
    borderColor: "white",
    borderRadius: "15px",
    backgroundColor: '#FFFFFF',
    paddingRight: "50px",
    marginTop: "30px",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
       width: "85%",
        borderColor: "white",
        borderRadius: "15px",
        backgroundColor: '#FFFFFF',
        paddingRight: "70px",
        marginTop: "30px",
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: teal["A700"],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: teal["A700"],
    },
  },
  notchedOutline: {

    borderColor:'#FFF !important',
    borderRadius: "15px",
  },
  cssLabel: {
    '&$cssFocused': {
      color: "white",
    },
  },
  fab:{
    position: "relative",
    // left: "20%",
     top: "35%"
  },
  commentText:{
    color: "white"
  },
  myCommentText:{
    color: "#8A8E92"
  },
  commentDate:{
    color: "#EEE"
  },
  video:{
    width:"70%",
    height: "100%",
    overflow: "hidden",
    display:"block",
    margin:"auto"
  },
  commentSection:{
    backgroundColor: "#F3F5F9",
    padding: "25px",
    maxHeight: "600px",
    overflow: "scroll"

  },
  videoMobile:{
    width:"100%",
    overflow: "hidden",
    display:"block",
    margin:"auto"
  },
  extendedIcon:{
    position: "relative",
    top: "45px",
    right: "40px",
    color: teal[500]
  }

});
class TrainingModule extends React.Component {
  videoPlayer;
  constructor(props) {
        super(props);
        this.classes =  props.classes;
        this.state = {
          text:'',
          messages: [],
          btnActive : false,
          myFirstname : '',
          myLastname : '',
          dataComments : [],
          video : [],
          tabContent : [],
          dataUsers : [],
          module:{},
          UserList:[],
          prevPath:''
        };
        this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.setState({ text: e.target.value });
 }
 setDate(date){
     let day = ['Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi','Dimanche'];
     let month = ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']
     let toDate = new Date (date);
     let retour =''
     if(toDate!='Invalid Date'){
         retour = toDate.getHours()+":"+toDate.getMinutes()+", "+toDate.getDate()+' '+month[toDate.getMonth()]+' '+toDate.getFullYear();
     }
     // "8:24, 09 April 2019"
     return retour;
 }
 saveComments(){

      console.log(this.state);
      console.log(this.props.match.params.id);
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
      let toPost = {
      "userId": user._id,
      "videoId":this.props.match.params.id,
      "comment": this.state.text,
      "createdAt" : new Date()
    }
    let self = this;
    TrainingModuleService.addComment(toPost).then(function(){
        self.setState({
            text:''
        })
        self.setValueTorender();
    });
  }
   htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  singleUser(id){
      let retour = {};
      for (var i=0;i<this.state.UserList.length;i++){
          if(this.state.UserList[i]._id==id){
              retour.lastname = this.state.UserList[i].lastname;
              retour.firstname = this.state.UserList[i].firstname;
          }
      }
      return retour;
  }
  setValueTorender(){
      //console.log("params %o", this.props.match.params.idModule);
      let self = this;
      // this.listVideos({
      //     "searchValue": self.props.match.params.id,
      //     "searchFields": [
      //          "moduleId"
      //      ]
      // }).then(function(value){
      //     console.log("Video::"+value);
      // });
      let idModule=this.props.match.params.idModule;

      this.listComments({
          "searchValue": this.props.match.params.id,
          "searchFields": [
            "videoId"
          ]
      });
      this.getThisVideo(this.props.match.params.id)
  }
  async getUser(id){
      let retour ={};
      try {
          const responseJson = await TrainingModuleService.getUser(id);
          if(responseJson){
              retour = responseJson;


          }
      }catch(e){
          console.log(e)
      }
     return retour;
  }
  async listUsers(data){
      return await TrainingModuleService.getUsers(data)
  }
  async listComments(data) {
      let self = this;
        let  tabIds = [];
        try {
        const responseJson = await TrainingModuleService.getComments(data);
        if(responseJson){

            this.setState({
                dataComments: responseJson.rows
            }, function(){
                for(var i = 0;i<responseJson.rows.length;i++){
                    tabIds.push(responseJson.rows[i].userId)
                }
                self.listUsers({"ids":tabIds}).then(function(value){
                    console.log("users dans le commentaire",value);
                    self.setState({
                        UserList:value.rows
                    })
                })

            });
        }
        } catch (e) {
         console.log(e);
        }
  }
  async getComments(data){
      try {
          const responseJson  =await TrainingModuleService.getComments(data);
          if(responseJson){
              console.log("commentaire %o", responseJson);

          }
      }catch(e){
          console.log(e);
      }
  }

  async getModule(id){
      try {
          const responseJson  =await TrainingModuleService.getModule(id);
          if(responseJson){
              console.log("module %o", responseJson);
              this.setState({
                  module: responseJson
              });
          }
      }catch(e){
          console.log(e);
      }
  }
  async getThisVideo(id) {
        try{
            const responseJson = await TrainingModuleService.getVideo(id);
            if(responseJson) {
                
                // responseJson.entryVideoEmbedCode = '<iframe src="https://player.vimeo.com/video/236828201" width="auto" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
                if(responseJson.videoEmbedCode)
                {
                  if(responseJson.videoEmbedCode[0] == '<')
                  {
                    var regex = /<iframe.*?src='(.*?)'/
                    var src = regex.exec(responseJson.videoEmbedCode)[1];
                    this.setState({entryVideoEmbedCode: src})
                    //this.state.entryVideoEmbedCode = src;
                  }
                  else
                  {
                    this.setState({entryVideoEmbedCode: responseJson.videoEmbedCode})
                    //this.state.entryVideoEmbedCode = responseJson.videoEmbedCode;
                  }
                }
                this.setState({

                    video: responseJson,
                    _idModule : responseJson.moduleId,
                    title : responseJson.title,
                    score : responseJson.score,
                    isPremium : responseJson.isPremium,
                    entryVideoUrl: responseJson.videoUrl,
                    entryDescription : responseJson.description,
                    //entryVideoEmbedCode : '<iframe src="https://player.vimeo.com/video/236828201" width="auto" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
                    videosDuration : responseJson.duration,
                    averageRating : responseJson.averageRating,
                    isLoading: false
                }, function(){
                });
            }
        } catch (e) {
         console.log(e);
        }

    }

    async componentDidMount(){
      ReactPixel.init("714205238765459");
      ReactPixel.pageView(); 
      let self = this;

      let idModule=this.props.match.params.idModule;
      let num=this.props.match.params.num;
      this.getModule(idModule);
      // this.getComments({
      //       "searchValue": idModule,
      //       "searchFields": [
      //           "moduleId"
      //       ]
      //   });
      this.getThisVideo(this.props.match.params.id)
      this.setValueTorender();


    }

  ref = player => {
    this.player = player
  }
  play=()=>{
      this.setState({
          toPlay:!this.state.toPlay
      })
  }












  async verifyUser(id)
  {
    if(await TrainingModuleService.getUser(id))
      return true;
    else
    {
      alert('utilisateur non existant')
      return false;
    }
  }


  render() {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const {redirectProfil,id_profil } = this.state;
   // console.log("user",user);
   if(redirectProfil)
    {
      return (<Redirect to={id_profil}/>);
    }
    return (
     <div>
         <link
            rel="stylesheet"
            href="https://video-react.github.io/assets/video-react.css"
          />
         <Header title={this.state.module.title} previous={'/trainingContent/'+this.props.match.params.itemId} search={false} contact={[]}/>









         <MediaQuery minDeviceWidth={376}>

            <div  className={this.classes.video}>
               {this.state.entryVideoEmbedCode ?
                (

                  <iframe src={this.state.entryVideoEmbedCode} width="100%" height="400px" frameborder="0" allowFullScreen></iframe>
                )
               :
               (
                <Player
                  playsInline
                  poster="/assets/poster.png"
                  src={this.state.entryVideoUrl}
                />
                )}
           </div>
         </MediaQuery>




         <MediaQuery maxDeviceWidth={375}>

            <div  className={this.classes.videoMobile}>
            {this.state.entryVideoEmbedCode ?
                (

                  <iframe src={this.state.entryVideoEmbedCode} width="100%" height="100%" frameborder="0" allowFullScreen></iframe>
                )
               :
               (
                <Player
                  playsInline
                  poster="/assets/poster.png"
                  src={this.state.entryVideoUrl}
                />
                )}
           </div>


         </MediaQuery>




        <MediaQuery minDeviceWidth={414.1}>
        { !this.state.isLoading ?
        <main className={this.classes.main}>

            <Card className={this.classes.card}>


               <CardContent>
                   <Typography align="left" className={this.classes.titlePrimary}>
                     {this.props.match.params.num}. {this.state.title ? this.state.title.toUpperCase() : ""}
                   </Typography>
                   <Typography align="left" className={this.classes.text}>
                     {this.state.entryDescription}
                   </Typography>



                   <Divider className={this.classes.divider}/>

                   <div className={this.classes.commentSection} >



                            {this.state.dataComments.map(function(item){
                              return <div>
                                 {item.userId != user._id?
                                 <Grid container alignItems="end" spacing={24}>
                                      <Grid item xs={2}>
                                          <Fab color="primary" aria-label="Add" className={this.classes.fab} onClick={async () => {
                                            if (await this.verifyUser(item.userId)){
                                              this.setState({ redirectProfil: true, id_profil:'/profil/'+ item.userId + '/training' })}}
                                            }>
                                            {this.singleUser(item.userId).firstname?this.singleUser(item.userId).firstname.substr(0,1):''}{this.singleUser(item.userId).lastname?this.singleUser(item.userId).lastname.substr(0,1):''}
                                          </Fab>
                                      </Grid>

                                      <Grid item xs={10}>
                                        <MediaQuery minDeviceWidth={376}>

                                            <Paper className={this.classes.comment}>
                                                  <Typography align="left" className={this.classes.myCommentText}>
                                                    {item.comment}
                                                  </Typography>
                                                  <Typography align="left" className={this.classes.commentDate}>
                                                    { item.createdAt === undefined ? null :
                                                        moment(item.createdAt).format('h:mm a, Do MMMM YYYY')
                                                      }
                                                  </Typography>
                                            </Paper>
                                         </MediaQuery>
                                         <MediaQuery maxDeviceWidth={375}>

                                            <Paper className={this.classes.commentMobile}>
                                                  <Typography align="left" className={this.classes.myCommentText}>
                                                    {item.comment}
                                                  </Typography>
                                                  <Typography align="left" className={this.classes.commentDate}>
                                                    { item.createdAt === undefined ? null :
                                                        moment(item.createdAt).format('h:mm a, Do MMMM YYYY')
                                                      }
                                                  </Typography>
                                            </Paper>
                                         </MediaQuery>
                                      </Grid>

                                 </Grid>
                                 :
                                 <Grid container spacing={24}>
                                      <Grid item xs={2}>

                                      </Grid>
                                      <Grid item xs={10}>
                                          <Paper className={this.classes.myComment}>
                                                <Typography align="left" className={this.classes.commentText}>
                                                 {item.comment}
                                                </Typography>
                                                <Typography align="left" className={this.classes.commentDate}>
                                                  { item.createdAt === undefined ? null :
                                                    moment(item.createdAt).format('h:mm a, Do MMMM YYYY')
                                                  }
                                                </Typography>
                                          </Paper>
                                      </Grid>


                                </Grid>
                            }
                            </div>
                            },  this)}

                     <Grid container alignItems="center">

                      <Grid item xs={12}>
                        <TextField
                            onChange={this.handleChange}
                            value={this.state.text}
                            InputProps={{
                              classes: {
                                root: this.classes.cssOutlinedInput,
                                focused: this.classes.cssFocused,
                                notchedOutline: this.classes.notchedOutline,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: this.classes.cssLabel,
                                focused: this.classes.cssFocused,
                              },
                            }}
                            id="outlined-multiline-static"
                            label=""
                            multiline
                            placeholder="Your comment"
                            className={this.classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                        <SendIcon  onClick={()=>this.saveComments()} className={this.classes.extendedIcon} />

                      </Grid>


                    </Grid>
                  </div>

               </CardContent>



           </Card>
        </main>
        : <div className={this.classes.loading}><CircularProgress className={this.classes.circularProgress} disableShrink /></div> }
         </MediaQuery>










         <MediaQuery maxDeviceWidth={414}>
          <main className={this.classes.mainMobile}>

            <Card className={this.classes.card}>


               <CardContent>
                   <Typography align="left" className={this.classes.titlePrimary}>
                     {this.props.match.params.num}. {this.state.title ? this.state.title.toUpperCase() : ""}
                   </Typography>
                   <Typography align="left" className={this.classes.text}>
                     {this.state.entryDescription}
                   </Typography>



                   <Divider className={this.classes.divider}/>

                   <div className={this.classes.commentSection} >



                            {this.state.dataComments.map(function(item){
                              return <div >
                                 {item.userId != user._id?
                                 <Grid container alignItems="end" spacing={24}>
                                      <Grid item xs={2}>
                                          <Fab color="primary" aria-label="Add" className={this.classes.fab} onClick={async () => {
                                            if (await this.verifyUser(item.userId)){
                                              this.setState({ redirectProfil: true, id_profil:'/profil/'+ item.userId + '/training' })}}
                                            }>
                                            {this.singleUser(item.userId).firstname?this.singleUser(item.userId).firstname.substr(0,1):''}{this.singleUser(item.userId).lastname?this.singleUser(item.userId).lastname.substr(0,1):''}
                                          </Fab>
                                      </Grid>
                                      <Grid item xs={1}>

                                      </Grid>
                                      <Grid item xs={9}>
                                        <MediaQuery minDeviceWidth={376}>

                                            <Paper className={this.classes.comment}>
                                                  <Typography align="left" className={this.classes.myCommentText}>
                                                    {item.comment}
                                                  </Typography>
                                                  <Typography align="left" className={this.classes.commentDate}>
                                                    { item.createdAt === undefined ? null :
                                                        moment(item.createdAt).format('h:mm a, Do MMMM YYYY')
                                                      }
                                                  </Typography>
                                            </Paper>
                                         </MediaQuery>
                                         <MediaQuery maxDeviceWidth={375}>

                                            <Paper className={this.classes.commentMobile}>
                                                  <Typography align="left" className={this.classes.myCommentText}>
                                                    {item.comment}
                                                  </Typography>
                                                  <Typography align="left" className={this.classes.commentDate}>
                                                    { item.createdAt === undefined ? null :
                                                        moment(item.createdAt).format('h:mm a, Do MMMM YYYY')
                                                      }
                                                  </Typography>
                                            </Paper>
                                         </MediaQuery>
                                      </Grid>

                                 </Grid>
                                 :
                                 <Grid container spacing={24}>

                                      <Grid item xs={9}>
                                          <Paper className={this.classes.myComment}>
                                                <Typography align="left" className={this.classes.commentText}>
                                                 {item.comment}
                                                </Typography>
                                                <Typography align="left" className={this.classes.commentDate}>
                                                  { item.createdAt === undefined ? null :
                                                    moment(item.createdAt).format('h:mm a, Do MMMM YYYY')
                                                  }
                                                </Typography>
                                          </Paper>
                                      </Grid>
                                      <Grid item xs={3}>

                                      </Grid>

                                </Grid>
                            }
                            </div>
                            },  this)}

                     <Grid container alignItems="center">

                      <Grid item xs={12}>
                        <TextField
                            onChange={this.handleChange}
                            value={this.state.text}
                            InputProps={{
                              classes: {
                                root: this.classes.cssOutlinedInput,
                                focused: this.classes.cssFocused,
                                notchedOutline: this.classes.notchedOutline,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: this.classes.cssLabel,
                                focused: this.classes.cssFocused,
                              },
                            }}
                            id="outlined-multiline-static"
                            label=""
                            multiline
                            placeholder="Your comment"
                            className={this.classes.textField}
                            margin="normal"
                            variant="outlined"
                        />
                        <SendIcon onClick={()=>this.saveComments()} className={this.classes.extendedIcon} />

                      </Grid>

                    </Grid>
                  </div>

               </CardContent>



           </Card>
        </main>
         </MediaQuery>


     </div>
    );
  }
}
export default withStyles(styles)(TrainingModule);
