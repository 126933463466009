import React from 'react';
import Image from 'react-native';
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import Modal from 'react-modal'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import Header from '../Layout/newHeader';
import Headers from '../Layout/header-s'

import TrainingService from "./Training.service.js";

import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import maison from './../../assets/images/video/image.png';
//import bookmark from './../../assets/images/icon/bookmark.svg';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import config from '../../path';
//const mollie = require('@mollie/api-client')({ apiKey: 'test_RwbSTpNcWWWzcn5b6RH2cwpwK3QbJP' });
//import axios from 'axios';
import NavigationIcon from '@material-ui/icons/Navigation';
import CloseIcon from '@material-ui/icons/Close';

import Responsive from 'react-responsive';
import Firebase from './firebase';
import ApiService from './../../Shared/api.service';

import ReactPixel from 'react-facebook-pixel';


const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding: 0,
    color: "white"
  }
};

const styles = theme =>  ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingTop: "30px",
    [theme.breakpoints.up('782.001')]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('782')]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('488')]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
    // paddingBottom: theme.spacing.unit * 8
  },

  card: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    borderRadius: "12px",
    marginBottom:"40px  !important",
    border : "5px solid #65C66A",
    paddingTop: "8px"
  },

  cardSecond: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    borderRadius: "12px",
    marginBottom:"40px  !important",
    background: "#EEE"
  },

  logo: {
    width: "100%",
    marginLeft: "10px"


  },
  container:{
    position: "relative",
    width:"100%",
  },
  center:{
    textAlign: 'center',
    marginTop: 0
  },

  centerButton:{
    textAlign: 'center',
    marginTop: 0,
    marginBottom: "40px"
  },

  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"300px",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  currenctPrice:{
    fontSize:"15px",
    color:"#f5c012",
    marginBottom: "10px"
  },
  lastPrice:{
    fontSize:"15px",
    color:"#afbfbf",
    textDecorationLine: "line-through"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"300px",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  overlay :{
    position: "absolute",
    bottom: "0",
    left: "9%",
    color:'#eff4f5',
    right: "0",
    backgroundColor: "#2C5C66",
    overflow: "hidden",
    height: "100%",
    borderRadius: " 0 12px 12px 0 ",
    transition:'300ms ease-in-out',
    display : "flex",
    flexDirection : "row"
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
    marginRight: "20px"
  },
  logout:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "underline",
  },




  loading:{
    textAlign: 'center',
    marginTop: "190px",
    height: "300px"
  },
  circularProgress:{
    color: teal[300]
  },
  title:{
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  submit:{
    textAlign: 'center',
    marginTop: "20px",
  },
  icon:{
    color: amber[500]
  },
  error:{
    color: "#FF0000 !important",
    textAlign: "center"
  },
  titlePrimary:{
    color: "#3B808E",
    fontSize: "1.5em",
    marginTop: "10px",
    marginBottom: "10px",
    textDecoration: "none",
    [theme.breakpoints.down('md')]:{
        fontSize: "1em",
    }
  },
  titleWhite:{
    color: "#FFFFFF",
    fontSize: "1.5em",
    marginTop: "10px",
    marginBottom: "10px",
    textDecoration: "none",
    [theme.breakpoints.down('md')]:{
        fontSize: "1em",
    }
  },
  content:{
    [theme.breakpoints.down('md')]:{
        padding: "0 !important",
    }
  },
  text:{
    color: "#848985",
    [theme.breakpoints.down('sm')]:{
        fontSize:"0.75em"
    }
  },
  textBold:{
    fontSize : 14,
    color : '#424242',
    fontWeight : 'bold'

  },
  footer:{
    position: "relative",
    bottom: "0"
  },
  right:{
    textAlign:"right",
    [theme.breakpoints.down('md')]:{
        padding:"0 2em 0.5em 0 !important"
    }
  },
  info:{
    backgroundColor: "#3B808E",
    color:"white",
    marginRight: "10px",
    fontSize: "30px",
    fontWeight : "bold"

  },
  buttonSecondary:{
    backgroundColor: amber[500],
    color:"white",
    marginRight :"10px"
  },
  buttonPlay:{
    backgroundColor: "#65C66A",
    color:"white",
  },
  modal : {
    display : "flex",
    flexDirection :"column"
  },
  modalTop : {
    flexBasis: "50px",
    backgroundColor: "#3B8598",
    display : "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight : "20px"
  },
  modalBody : {
    flexBasis : "250px",
    display :  "flex",
    flexDirection : "column",
    padding: "20px",
    color: 'black'
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  iconButton:{
    marginRight: "10px"
  },
  infoIcon:{
    fontSize: "2em"
  },

  paperLink:{
    textDecoration : "none"
  }

});

class Training extends React.Component {

  users = [];
  static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
              latitude: null,
              longitude: null,
              error: null,
              showToast: false,
              basicCustomColorsVisible: false,
              activeIcon : false,
              isPress : false,
              isIcon : false,
              idInscrire : 0,
              //isLoading: true,
              keySearch : '',
              searchState : true,
              dataSource:[],
              slideClasse:{},
              currentClasse:{},
              itemWidth:{},
              leftPosition:{}  ,
              showPriceChoice : false,
              modalIsOpen: false,
              item : {},
              isLoading:false,
              redirectContent : false,
              status : [],
              isInfo: false,
              modalIsOpen: false,
              item: {}, 
              currentUser : {},
              code_promo : "",
              badCode : false,
              sendCode : false,
              resultPromo : {},
              codeUsed : false,
              originMonthly : "",
              originYearly : "",
              promoCodeId: "",
    };
    //console.log("main %o",this.classes);



    this.logout = this.logout.bind(this);
    //this.setWidth = this.setWidth.bind(this);
  }

  ///////Modal
  openModal(item) {
    this.setState({item : item});
    this.setState({modalIsOpen: true});
    this.setState({originYearly : item.priceAnnualy, originMonthly : item.priceMonthly})
  }
  openInfoModal(item) {
    this.setState({item : item});
    this.setState({isInfo: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }
  afterInfoModal()
  {

  }
  closeInfoModal()
  {
    this.setState({isInfo : false});
  }

  closeModal() {
    let item = this.state.item;
    item.priceMonthly = this.state.originMonthly;
    item.priceAnnualy = this.state.originYearly;

    this.setState({modalIsOpen: false, badCode: false, sendCode : false, item : item, codeUsed: false});
  }
  redirectContent() {
    //this.setState(redirectContent,true)
  }
  ///////
  /**
   * list Training init
   */
  async componentDidMount(){
    ReactPixel.init("714205238765459");
    ReactPixel.pageView();     
    this.listTraining( {"price": 150} );
    const user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;
    this.users = await ApiService.post('users', {tabIds: []});
    let currentUser = this.users.rows.find(i => i._id == user._id);
    this.setState({
      slideClasse:this.classes.overlay,
      currentClasse:this.classes.overlay,
      currentUser : currentUser
    });

  }

  handleCode(event)
  {
    this.setState({code_promo: event.target.value})
  }
  async validerCode()
  {
    const user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;
    
    let result = await TrainingService.getPromoCode(this.state.code_promo);
    let currentUser = await ApiService.get('user',user._id);
    
    this.setState({sendCode : true});
    if(currentUser.subscriptions && currentUser.subscriptions.length > 0)
    {
      let subscription = currentUser.subscriptions.filter(e => e.promoCodeId == result._id);
      if(subscription.length > 0)
      {
        this.setState({codeUsed: true});
      }
      else
      {
        this.setState({codeUsed: false})
      }

    }
    else
    {
      this.setState({codeUsed : false})
    }
    if(result)
    {
      let monthly = this.state.item.priceMonthly - ((this.state.item.priceMonthly * result.monthlyValue)/100);
      let annualy =  this.state.item.priceAnnualy - ((this.state.item.priceAnnualy * result.yearlyValue)/100);
      monthly = monthly.toFixed(2);
      annualy = annualy.toFixed(2);
      let old = this.state.item;
      old.priceMonthly = monthly;
      old.priceAnnualy = annualy;
      this.setState({resultPromo: result});
      if(!result.expireAt)
      {
        if(this.state.codeUsed == true)
        {
          old.priceMonthly = this.state.originMonthly;
          old.priceAnnualy = this.state.originYearly;
          this.setState({item : old, badCode : true, trainingCode : this.state.item._id, promoCodeId : ""})
        }
        else
        {
          this.setState({item : old, badCode : true, trainingCode : this.state.item._id, promoCodeId : result._id})
        }
      }
      else
      {
        let now = Date.now();
        let expiration = new Date(result.expireAt);
        if(expiration < now)
        {
          console.log("code User : ",this.state.codeUsed)
          if(this.state.codeUsed == true)
          {
            old.priceMonthly = this.state.originMonthly;
            old.priceAnnualy = this.state.originYearly;
            this.setState({item : old, badCode : false, trainingCode : this.state.item._id, promoCodeId : ""})
          }
          else
          {
            this.setState({item : old, badCode : true, trainingCode : this.state.item._id, promoCodeId : result._id})
          }
        }
        else
        {
          this.setState({badCode : false})
        }
      }
    }
    else
    {
      this.setState({badCode : false});
    }
  }
  /**
  * list Training
  * @param {*} data
  */
   async listTraining(data) {
     try{
       this.setState({isLoading: true});
       const user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;
       const responseJson = await TrainingService.get(data);

       const userId = user._id;
       if(responseJson)
       {

         let tableau = responseJson.rows;

         
         let prom = new Promise((resolve,reject) => {
           tableau.forEach((training,index,array) => {

              TrainingService.getUser(userId).then(_user => {
                console.log("USER ID %o",_user);
                /*_user.subscriptions = [];
                TrainingService.updateUser(_user,user._id).then(result => {
                      console.log("vidée : ",result)
                       tableau[index].status= false;
                       this.setState({
                         dataSource : tableau,
                         isLoading: false
                       })
                     })*/
                if(_user && _user.subscriptions && _user.subscriptions.some(e => e.trainingId === array[index]._id))
                {
                  let subscription = _user.subscriptions.find(e => e.trainingId === array[index]._id);
                  if(subscription.expireAt > Date.now())
                  {
                    tableau[index].status = false;
                    this.setState({
                       dataSource : tableau,
                       isLoading: false
                     })
                  }
                  else
                  {
                    tableau[index].status = true;
                    this.setState({
                       dataSource : tableau,
                       isLoading: false
                     })
                  }
                }
                else
                {
                  let dbRef = Firebase.database().ref('mollie/'+userId+'/'+array[index]._id);
                   dbRef.once("value",fetch => {
                     this.setState({isLoading: false});
                     if(fetch.val() && fetch.val().status == "paid")
                     {
                         //_user.subscriptions = [];
                        delete _user['_id'];
                        delete _user['password'];
                        if(!_user.subscriptions){
                          _user.subscriptions = [];
                        }
                         _user.subscriptions.push({

                       "trainingId": tableau[index]._id,
                        "duration": fetch.val().duration,
                        "expireAt": fetch.val().expireAt.toString(),
                        "updatedAt" : fetch.val().updatedAt,
                        "createdAt" :  fetch.val().createdAt,
                        "promoCodeId" : fetch.val().promoCodeId,
                        "from": fetch.val().from
                       })
                       TrainingService.updateUser(_user,user._id).then(result => {

                       tableau[index].status= false;
                       this.setState({
                         dataSource : tableau
                       })
                     })
                     }
                     else
                     {

                       tableau[index].status = true;
                       this.setState({
                         dataSource : tableau
                       })
                     }
                   });
                }


                if(index === array.length-1) resolve();
              })


            })

         })
       }

     }
     catch (e) {
       console.log("error training list : ",e.err)
     }
   }

  logout(){
    localStorage.removeItem("user");
  }

  card(){
    return (
      <div>
         <Paper className={this.classes.card}>
            Hello paper
         </Paper>
      </div>
    )
  }


/*<SimpleSlide item={item}/>*/
  setWidth(j){

    let itemWidth = this.state.itemWidth;
    let leftPosition = this.state.leftPosition;
    //console.log("itemWidth 1 %o", itemWidth);

    itemWidth[j]= "0px";
    leftPosition[j]="100%"

    this.setState({
      itemWidth:itemWidth,
      leftPosition:leftPosition
    })
  }
  buy(item){
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    console.log("new item : ",item)
    if(item._id  && user._id)
    {
      let codePromo = false
      if(!this.state.codeUser && this.state.badCode)
      {
        codePromo = true
      }
    item.webhookUrl = window.location.href;
    //fetch('https://apl-server-mollie.herokuapp.com/paiement', {
      fetch('https://salty-plains-75430.herokuapp.com/paiement', {
      method: "POST",
      body: JSON.stringify({
              amount:{
                  value:item.price,
                  currency:"EUR"
              },
              duration : item.duration,
              createdAt : item.createdAt,
              trainingId: item._id,
              userId : user._id,
              description:item.description,
              redirectUrl:window.location.href,
              promoCodeId : codePromo ? this.state.promoCodeId : null,
              webhookUrl:"http://acheter-pour-louer-web.firebaseapp.com/",
      }),
      headers: {
        //"Authorization":"1234567",
        "Content-Type": "application/json",
        Accept: 'application/json',
        //"Access-Control-Allow-Origin":"*"
      },
      //credentials: "same-origin"
    })
      .then(response => {
        ReactPixel.track( 'Purchase', {value: item.price,currency: "EUR"});
        if (response.ok) {
          response.json().then(json => {
            window.location = json.payement;
        });
      }
    });
    }
    /*mollie.payments.create({
      amount: {
        value:    '10.00',
        currency: 'EUR'
      },
      description: 'My first API payment',
      redirectUrl: 'http://acheter-pour-louer-web.firebaseapp.com/',
      webhookUrl:  'http://acheter-pour-louer-web.firebaseapp.com/'
    })
      .then((payment) => {
        // Forward the customer to the payment.getPaymentUrl()
        mollie.payments.get(payment.id)
          .then((payment) => {
            // E.g. check if the payment.isPaid()
          })
          .catch((err) => {
            // Handle the error
          });
      })
      .catch((err) => {
        // Handle the error
      });*/
  }
  showBlock(j){

    let itemWidth = this.state.itemWidth;
    let leftPosition = this.state.leftPosition;
    //console.log("itemWidth 1 %o", itemWidth);

    itemWidth[j]= "91.33%";
    leftPosition[j]="9%"

    this.setState({
      itemWidth:itemWidth,
      leftPosition:leftPosition,

    })
  }

  render(){
    //console.log("user %o",localStorage.getItem('user'));
    // console.log("lien du site actuel",window.location.href);
    const user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;

    if(this.state.redirectContent)
    {
      return <Redirect to="/trainingContent"/>
    }
    return (


      <div>
      <Header title="Choisis ta formation" search={false} actualLink={"/training"} previous={'/'} contact={[]}/>
      <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal.bind(this)}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div className={this.classes.modal}>
          <div className={this.classes.modalTop}>
          Choix d'abonnement
          <CloseIcon style={{color :"white", marginLeft: "40px"}} onClick={this.closeModal.bind(this)}/>
          </div>
        </div>
        {!this.state.currentUser.isTestUser &&
        <div className={this.classes.modalBody}>
          {this.state.badCode && !this.state.codeUsed &&
            <span>Reduction de {this.state.resultPromo.monthlyValue}%</span>
          }
          <Button onClick={() => {this.state.item.price = this.state.item.priceMonthly; this.state.item.duration = "1"; this.buy(this.state.item); this.setState({isLoading : true}); this.closeModal()}} variant="contained" style={{"backgroundColor" : "#ffb700", textTransform : "lowercase" ,color : "white", fontWeight : "500", minHeight: "50px", marginBottom : "20px"}}>
            <ShoppingCartIcon className={this.classes.extendedIcon} /> {this.state.item.priceMonthly}€ mensuel
          </Button>
          {this.state.badCode && !this.state.codeUsed &&
            <span>Reduction de {this.state.resultPromo.yearlyValue}%</span>
          }
          <Button onClick={() => {this.state.item.price = this.state.item.priceAnnualy; this.state.item.duration = "12"; this.buy(this.state.item); this.setState({isLoading : true}); this.closeModal()}} variant="contained" style={{"backgroundColor" : "#ffb700", textTransform : "lowercase", color : "white", fontWeight : "500", minHeight: "50px"}}>
            <ShoppingCartIcon className={this.classes.extendedIcon} /> {this.state.item.priceAnnualy}€ annuel
          </Button>
          {!this.state.badCode && 
          <TextField
            placeholder="CODE PROMO"
            style={{marginTop: "24px"}}
            name="code_promo"
            onChange={this.handleCode.bind(this)}
          />
          }
          {this.state.codeUsed &&
          <TextField
            placeholder="CODE PROMO"
            style={{marginTop: "24px"}}
            name="code_promo"
            onChange={this.handleCode.bind(this)}
          />
          }
          {!this.state.badCode && this.state.sendCode &&
            <span style={{color: "red", fontSize: "10px", textAlign : "center"}}>Code invalide</span>
          }
          {this.state.codeUsed && this.state.sendCode &&
            <span style={{color: "red", fontSize: "10px", textAlign : "center"}}>Code déja utilisé</span>
          }
          {!this.state.badCode && 
          <Button variant="primary"  onClick={this.validerCode.bind(this)}>Valider Code Promo</Button>
          }
          {this.state.codeUsed && 
          <Button variant="primary"  onClick={this.validerCode.bind(this)}>Valider Code Promo</Button>
          }
        </div>
        }
        {this.state.currentUser.isTestUser && 
        <div className={this.classes.modalBody}>
          <Button onClick={() => {this.state.item.price = 0.1; this.state.item.duration = "1"; this.buy(this.state.item); this.setState({isLoading : true}); this.closeModal()}} variant="contained" style={{"backgroundColor" : "#ffb700", textTransform : "lowercase" ,color : "white", fontWeight : "500", minHeight: "50px", marginBottom : "20px"}}>
            <ShoppingCartIcon className={this.classes.extendedIcon} /> 0.1€ mensuel
          </Button>
          <Button onClick={() => {this.state.item.price = 0.2; this.state.item.duration = "12"; this.buy(this.state.item); this.setState({isLoading : true}); this.closeModal()}} variant="contained" style={{"backgroundColor" : "#ffb700", textTransform : "lowercase", color : "white", fontWeight : "500", minHeight: "50px"}}>
            <ShoppingCartIcon className={this.classes.extendedIcon} /> 0.2€ annuel
          </Button>
          <TextField
            placeholder="CODE PROMO"
            style={{marginTop: "24px"}}
            name="code_promo"
            onChange={this.handleCode.bind(this)}
          />
          {!this.state.badCode && this.state.sendCode &&
            <span style={{color: "red", fontSize: "10px", textAlign : "center"}}>Code invalide</span>
          }          
          {this.state.codeUsed && this.state.sendCode &&
            <span style={{color: "red", fontSize: "10px", textAlign : "center"}}>Code déja utilisé</span>
          }
          {!this.state.badCode &&
            <Button variant="primary"  onClick={this.validerCode.bind(this)}>Valider Code Promo</Button>
          }
        </div>
        }
        </Modal>
        <Modal
          isOpen={this.state.isInfo}
          onAfterOpen={this.afterInfoModal}
          onRequestClose={this.closeInfoModal.bind(this)}
          style={customStyles}
          contentLabel="Example Modal">
          <div className={this.classes.modal}>
          <div className={this.classes.modalTop}>
           {this.state.item.title} <CloseIcon onClick={this.closeInfoModal.bind(this)} style={{marginLeft: "32px"}}/>
          </div>
          <div className={this.classes.modalBody}>
            <div style={{minHeight: "96px", borderBottom : "1px solid rgba(175,186,180,0.5)"}}>
              {this.state.item.description}
            </div>
            <div style={{marginTop: "32px"}}>
              <span style={{color: "gray", fontWeight: 700, textDecoration: "line-through"}}>{this.state.item.priceMonthly}</span>
              <span style={{color: "#ffb700", marginLeft: "8px", fontWeight: 700}}>{this.state.item.priceMonthly}€ mensuel</span>
            </div>
            <div style={{marginTop: "32px", paddingBottom: "32px", borderBottom : "1px solid rgba(175,186,180,0.5)"}}>
              <span style={{color: "gray", fontWeight: 700, textDecoration: "line-through"}}>{this.state.item.priceAnnualy}</span>
              <span style={{color: "#ffb700", marginLeft: "8px", fontWeight: 700}}>{this.state.item.priceAnnualy}€ annuel</span>
            </div>
            <Link style={{textDecoration: "none", textAlign: "center"}}to={"/trainingContent/"+this.state.item._id}>
            <Button variant="contained" style={{background: "#3B8598", color: "white", marginTop: "24px" }}>
              VOIR UN EXTRAIT <PlayArrowIcon style={{color: "white"}}/>
            </Button>
            </Link>
          </div>
        </div>
        </Modal>
        { !this.state.isLoading ?
      <main className={this.classes.main} theme={theme}>
              <div className={this.classes.centerButton} >
                <Link to="/chat" className={this.classes.link}>
                  <Fab variant="extended" aria-label="Add" className={this.classes.button}>
                    <svg className={this.classes.iconButton} fill={this.props.actualLink != "/contact"?'#FFFFFF' : '#59e099'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/></svg>
                   Discuter avec des investisseurs et experts
                  </Fab>
                </Link>
              </div>
              { this.state.dataSource.map( (item, i) => (

                    <div className={this.classes.container}>

                    <Link to={!item.status ? "/trainingContent/"+item._id : "#"} className={this.classes.paperLink} >

                    <Paper   className={!item.status ? this.classes.card : this.classes.cardSecond} elevation={5}>


                        <Grid container spacing={24} style={{padding: "10px"}}>

                            <Grid item xs={4} sm={4} style = {{padding: 0, display : "flex", flexDirection: "column", justifyContent: "center"}}>
                                <img src={maison} alt="Logo" className={this.classes.logo}/>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <Grid item  container direction="column" spacing={32} >
                                  <Grid item xs style={{margin: "0 4em 0 20px"}} className={this.classes.content}>
                                    <Typography align="left"  style={{marginBottom: 0}}>
                                      <Link to={"/trainingContent/"+item._id} className={this.classes.titlePrimary}>
                                          {item.title}
                                      </Link>
                                    </Typography>
                                    <Typography align="left" className={this.classes.text}>
                                      {item.description}
                                    </Typography>
                                    <Typography align="left" className={this.classes.textBold} style={{marginTop : "10px", marginBottom : "10px"}}>
                                      {item.priceMonthly}€
                                    </Typography>
                                    <Typography align="left" className={this.classes.textBold} style={{marginBottom: "10px"}}>
                                      {item.priceAnnualy}€
                                    </Typography>


                                  </Grid>
                                  <div style={{position: "absolute", right: 0, bottom: "10px"}}>
                                    <Fab onClick={this.openInfoModal.bind(this,item)} aria-label="Add" className={this.classes.info}>
                                          !
                                    </Fab>


                                      {item.status ?

                                      (
                                        /*<Fab onClick={()=>{this.buy(item)}} className={this.classes.buttonPlay} className={this.classes.buttonSecondary}>
                                            <ShoppingCartIcon />
                                        </Fab>*/

                                        <Fab onClick={this.openModal.bind(this,item)} className={this.classes.buttonPlay} className={this.classes.buttonSecondary}>
                                            <ShoppingCartIcon />
                                        </Fab>

                                      )
                                      :
                                      (
                                        <Link to={"/trainingContent/"+item._id}>
                                          <Fab className={this.classes.buttonPlay} style={{marginRight: "10px"}}>
                                              <PlayArrowIcon />
                                          </Fab>
                                        </Link>
                                      )
                                      }
                                  </div>

                                </Grid>



                            </Grid>

                          </Grid>




                    </Paper>
                    </Link>
                  </div>
              ) ) }
        </main>
        : <div className={this.classes.loading}><CircularProgress className={this.classes.circularProgress} disableShrink /></div> }
        </div>
    );
  }
}

export default withStyles(styles)(Training);
