import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';

import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import teal from '@material-ui/core/colors/teal';
import config from '../../path';
import cookie from 'react-cookies';
import ReactPixel from 'react-facebook-pixel';

const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const styles = theme =>  ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    // paddingBottom: theme.spacing.unit * 8
  },
  card: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    borderRadius: "12px"
    // marginBottom: theme.spacing.unit * 8,
  },
  media: {
    height: 200,
    backgroundPosition: 'top',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      height: 350,
    },
  },
  logo: {
    width: "80%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  center:{
    textAlign: 'center',
    marginTop: 0,
    width: "100%"
  },
  social:{
    textAlign: 'center',
    marginTop: 40
  },
  footer:{
    textAlign: 'center',
    marginTop: 40,
    color: "#42859F",
    textTransform: "uppercase"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"95%",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"90%",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
    marginRight: "20px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    borderColor: "white",
    borderRadius: "15px",
    backgroundColor: '#F4F5F9',
    width: "95%",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "60%"
    },


  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: teal["A700"],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: teal["A700"],
    },
  },
  notchedOutline: {

    borderColor:'#FFFFFF !important',

  },
  cssLabel: {
    '&$cssFocused': {
      color: teal["A700"],
    },
  },
  loading:{
    textAlign: 'center',
    marginTop: "190px",
    height: "300px"
  },
  circularProgress:{
    color: teal[300]
  },
  title:{
    color: "#FFFFFF",
    fontWeight: "bold",
  }
});

class Register extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
            step: 1,
            data: {},
            disabled: true,
            isLoading:false,
            isAuthenticated: false
        };
    console.log("main %o",this.classes);
    this.nextStep = this.nextStep.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.checkForm = this.checkForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validatorListener = this.validatorListener.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.rate = this.rate.bind(this);
  }

  handleChange(event) {
        console.log("change herre");
        const { data } = this.state;
        data[event.target.name] = event.target.value;
        this.setState({ data });
  }
  componentDidMount()
  {
    ReactPixel.init("714205238765459");
    ReactPixel.pageView();          // For tracking page view
    
  }
  handleSubmit(){
    const URL = `${config.URL_API_base}/register`;
    //console.log(this.state.data);
    if(this.state.step <3){
      this.setState({step: this.state.step +1});
    }else{
          const { data } = this.state;

          this.setState({ data });

          this.setState({
            isLoading: true,
          });
          fetch(URL, {
          method: "POST",
          headers: {
            "Authorization":"1234567",
            Accept: 'application/json',
            "Content-Type": "application/json",
            "ZUMO-API-VERSION" : "2.0.0"
          },
          body: JSON.stringify({
            user : data
          }),
          })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log("response json %o", responseJson);
            if(responseJson.token !== null){
              

                          const URL = `${config.URL_API_base}/login`;
                          this.setState({
                            isLoading: true,
                          })
                          return fetch(URL, {
                            method: "POST",
                            headers: {
                              "Authorization":"1234567",
                              Accept: 'application/json',
                              "Content-Type": "application/json",
                              "ZUMO-API-VERSION" : "2.0.0"
                            },
                            body: JSON.stringify({
                              email : data.email,
                              password : data.password,
                              mobile : true
                            }),
                          })
                            .then((response) => response.json())
                            .then((responseJson) => {

                              if(responseJson.token !== null){

                                console.log("USER %o",responseJson.user);
                                // localStorage.setItem("userToken", JSON.stringify(responseJson.token));
                                // localStorage.setItem("user", JSON.stringify(responseJson.user));
                                ReactPixel.track( 'CompleteRegistration', responseJson.user);
                                localStorage.setItem('user', JSON.stringify(responseJson.user));
                                cookie.save('user',JSON.stringify(responseJson.user),{ path: '/' })
                                localStorage.setItem('userToken', responseJson.token);
                                cookie.save('userToken',responseJson.token,{ path: '/' })
                                 localStorage.setItem('IdTokenPhone', this.state.token);
                                 cookie.save('IdTokenPhone',this.state.token)
                                 localStorage.setItem('idToken', responseJson.user._id,{ path: '/' });
                                 cookie.save('idToken',responseJson.user._id)
                                 localStorage.setItem('firstnameToken', responseJson.user.firstname);
                                 cookie.save('firstnameToken',responseJson.user.firstname,{ path: '/' })
                                 localStorage.setItem('lastnameToken', responseJson.user.lastname);
                                 cookie.save('lastnameToken',responseJson.user.lastname,{ path: '/' })
                                this.setState({
                                  isLoading: false,
                                  isAuthenticated: true,
                                });

                              }
                           
                            })
                            .catch((error) =>{
                              alert(error);
                            });

            }
            else{
              this.setState({
                isLoading: false,
              })
            }
          })
          .catch((error) =>{
            console.log(error);
          });
    }
  }

  rate(num){

    const { data } = this.state;
    data["score"] =num;

    this.setState({ data: data });
    console.log(this.state.data);
  }
  validatorListener(result) {
    // console.log("validator listenr %o", result);
    // this.setState({ disabled: !result });
  }

  checkForm(){

  }

  nextStep(step){

  }

  renderStep() {
    const disabled = this.state.disabled;
    let content = null;
    switch (this.state.step) {
      case 1:
          content = <StepOne register={this} style={{width:"100%"}}/>;
          break;
      case 2:
          content = <StepTwo register={this} style={{width:"100%"}}/>;
          break;
      case 3:
          content = <StepThree register={this} style={{width:"100%"}}/>;
          break;

    }
    return content;
  }
  render(){
    if(this.state.isAuthenticated){
      return(
        <Redirect to='/training' />
      );
    }else{
      return (
        <main className={this.classes.main} theme={theme}>

              <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        instantValidate
                    >


                <Typography align="right"  className={this.classes.title}>
                  <Link to="/" className={this.classes.link}>
                    <i className="material-icons">
                    chevron_left
                    </i>
                  </Link>
                  Inscription
                </Typography>
                <Card className={this.classes.card}>

                    { !this.state.isLoading ?
                    <CardContent>


                      <img src={logo} alt="Logo" className={this.classes.logo}/>
                      {this.renderStep()}
                      <div className={this.classes.center}>

                        <Fab
                        type="submit"
                        onClick={this.nextStep} variant="extended" aria-label="Add" className={this.classes.button}>
                          CONTINUER
                        </Fab>
                      </div>


                    </CardContent>
                    : <div className={this.classes.loading}><CircularProgress className={this.classes.circularProgress} disableShrink /></div> }



                </Card>
                </ValidatorForm>

          </main>
      );
    }

  }
}

export default withStyles(styles)(Register);
