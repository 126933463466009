import React, {useState, Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import teal from '@material-ui/core/colors/teal';
import { Link, Redirect } from 'react-router-dom';
import SwipeableTemporaryDrawer from './Sidebar';
import Icon from '@material-ui/core/Icon';
import {connect} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Backend from '../../notification.service.js';
import Badge from '@material-ui/core/Badge';
import { withRouter } from 'react-router-dom';
import Notification from 'react-web-notification'
import audio from "./../../assets/sound.mp3"
import ogg from './../../assets/sound.ogg';
import FormControl from '@material-ui/core/FormControl';
import SearchInput, {createFilter} from 'react-search-input'

const mapStateToProps = (state) => {
  return {publicMessage: state.publicMessage , groupMessage : state.groupMessage, privateMessage: state.privateMessage, selectPrivate : state.selectPrivate, curPrivate: state.curPrivate, groupList: state.groupList,
  	privateList: state.privateList}
}

const KEYS_TO_FILTERS = ['firstname', 'lastname']

const styles = {
  root: {
    flexGrow: 1,
  },
  chevron : {
      top : 0,
      fontSize: "32px"

  },
  topbar : {
    display : 'flex',
    height : '50px',
    flexDirection : 'row',
    color: 'white',
    alignItems: 'center',
    paddingLeft: "8px"
  },
  title :{
    fontWeight : '400'
  },
  right : {
    top : 0,
    marginLeft: 'auto',
    right: 0,
    fontSize: "32px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    style={{transform: "translateX(-64px)"}}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: "white",
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function dateConversion(value)
{
  let months = ['Jan','Feb','Mar',]
  let date = new Date(value);
  let minutes = 0;
  if(date.getMinutes()<=9)
    minutes = '0'+date.getMinutes();
  else
    minutes = date.getMinutes();
  
  return date.toLocaleDateString() + ' ' + date.getHours() + ":" + minutes
}

class Header extends React.Component {

	static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
      isLoading: true,
      contacts:[],
      training: {},
      anchorEl: null,
      redirect: false,
      globalMessage: [],
      Nnotif : 0,
      i: 0,
      notifList : [],
      notificationDate : {},
  	  ignore: false,
	  title : '',
	  notificationMessage: {},
	  prenom: '',
	  displayIn: [],
	  searchTerm : '',
	  openSearch : false

    	};  

	}
	handleRedirect(){
	  this.setState({redirect: true})
	  }

	handleClick(event) {
      this.setState({anchorEl: event.currentTarget})
  }

  handleClose() {
    this.setState({anchorEl: null})
  }

  clickedPublicMessage(item) {
    const action = { type: "SET_CURPUBLIC", value: item }
    this.props.dispatch(action);
    this.props.history.push("/chat/public");
  }
  showNotifications(message)
  {
  	const user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;
  	if(this.state.ignore) {
      return;
    }
    const now = Date.now();
    let title = "";
    let body = "";

    if(message.mention)
    {
    	title ="Message " + message.type + " de " +message.user.name
    	body = message.text + " - " + dateConversion(message.createdAt);

    }
    else if(message.mentions)
    {
    	let  mention = message.mentions.filter(e => (e._id == user._id))
    	title ="Message " + message.type + " de " +mention[0].user
    	body = message.text + " - " + dateConversion(message.createdAt);
    }
    else if(message.type == "private")
    {
    	title ="Message " + message.type + " de " +  message.user.name
    	body = message.message + " - " + dateConversion(message.createdAt);
    }
    const tag = now;
    const icon = 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png';
    // const icon = 'http://localhost:3000/Notifications_button_24.png';

    // Available options
    // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: 'en',
      dir: 'ltr',
      sound: './sound.mp3',
      data: {message: message, type: message.type}
    }
    this.setState({
      title: title,
      options: options
    });
	}
  handlePermissionGranted(){
    console.log('Permission Granted');
    this.setState({
      ignore: false
    });
  }
  handlePermissionDenied(){
    console.log('Permission Denied');
    this.setState({
      ignore: true
    });
  }
  handleNotSupported(){
    console.log('Web Notification not Supported');
    this.setState({
      ignore: true
    });
  }

  handleNotificationOnClick(e, tag){
    if(e.target.data.type == "public")
    {
    	const action = { type: "SET_CURPUBLIC", value: e.target.data.message }
    	this.props.dispatch(action);
    	this.props.history.push("/chat/public");
    }
    else if(e.target.data.type == "group")
    {	
    	const action = { type: "SET_CURGROUP", value: e.target.data.message }
	    this.props.dispatch(action);
	    this.props.history.push("/chat/group");
    }
    else if(e.target.data.type == "private")
    {
    	const action = { type: "SET_CURPRIVATE", value: e.target.data.message }
	    this.props.dispatch(action);
	    this.props.history.push("/chat/private");
    }
  }

  handleNotificationOnError(e, tag){
    console.log(e, 'Notification error tag:' + tag);
  }

  handleNotificationOnClose(e, tag){
    console.log(e, 'Notification closed tag:' + tag);
  }

  handleNotificationOnShow(e, tag){
    this.playSound();
  }
  playSound(filename){
    document.getElementById('sound').play();
	}
  clickedGroupMessage(item) {
    const action = { type: "SET_CURGROUP", value: item }
    this.props.dispatch(action);
    this.props.history.push("/chat/group");
  }
  dateConversion(value)
    {
      let months = ['Jan','Feb','Mar',]
      let date = new Date(value);
      let minutes = 0;
      if(date.getMinutes()<=9)
        minutes = '0'+date.getMinutes();
      else
        minutes = date.getMinutes();
      
      return date.toLocaleDateString() + ' ' + date.getHours() + ":" + minutes
    }
  clickedPrivateMessage(item) {
    const action = { type: "SET_CURPRIVATE", value: item }
    this.props.dispatch(action);
    this.props.history.push("/chat/private");
  }
  handleRedirect()
  {
  	this.setState({redirect: true});
  }
	componentDidMount()
	{
		const user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;
		let publicM = [];
	    let N = 0;
	    let self =this;
   		
		  if(this.props.publicMessage)
		  {
		    const message = this.props.publicMessage;
		    
		    for(var i=0; i<message.length; i++)
		    {
		      if(message[i].read==false && message[i].mention && message[i].mention.user.id == user._id)
		      {
		      	N++;
		      	publicM.push(message[i])
	        	this.setState({Nnotif: N})
	        	this.setState({globalMessage: publicM})
		      }
		      if(message[i].read==false && message[i].mentions)
		      {
		      	let  mention = message[i].mentions.filter(e => (e._id == user._id))
		      	if(mention != null)
		      	{
		      		
		      		N++;
			      	publicM.push(message[i])
		        	this.setState({Nnotif: N})
		        	publicM.sort(function(a,b)
		        	{
				      let _a = Date.parse(a.createdAt);
				      let _b = Date.parse(b.createdAt)
				      return _b - _a;
				  	});
		        	this.setState({globalMessage: publicM})
		      	}
		      }
		    }
	    	this.setState({Nnotif: N})
	    	publicM.sort(function(a,b)
        	{
		      let _a = Date.parse(a.createdAt);
		      let _b = Date.parse(b.createdAt)
		      return _b - _a;
		  	});
	    	this.setState({globalMessage: publicM})	    
		  }
		   /////////////////////////// GROUP ////////////////////
		   if(this.props.groupList)
		   {
		   	let groupMessage = [];
		   	
		   	for(let i=0; i<this.props.groupList.length; i++)
		   	{
		   		groupMessage = groupMessage.concat(this.props.groupList[i].messages)
		   	}

		   	for(let j=0; j<groupMessage.length; j++)
		   	{
	   		  if(groupMessage[j].read==false && groupMessage[j].mention && groupMessage[j].mention.user.id == user._id)
		      {
		      	N++;
		      	groupMessage[j].type = "group";
		      	publicM.push(groupMessage[j])
	        	this.setState({Nnotif: N})
	        	publicM.sort(function(a,b)
	        	{
			      let _a = Date.parse(a.createdAt);
			      let _b = Date.parse(b.createdAt)
			      return _b - _a;
			  	});
	        	this.setState({globalMessage: publicM})
		      }
		   	}
		   	this.setState({Nnotif: N})
        	publicM.sort(function(a,b)
        	{
		      let _a = Date.parse(a.createdAt);
		      let _b = Date.parse(b.createdAt)
		      return _b - _a;
		  	});
        	this.setState({globalMessage: publicM})

		   }

		   //////////////////// PRIVATE ////////////////////////
		  if(this.props.privateList)
		  {
		  	let privateMessage = [];
		  	for(let i=0; i<this.props.privateList.length; i++)
		  	{
		  		for(var item in this.props.privateList[i].messageDiscus)
		  		{
		  			this.props.privateList[i].messageDiscus[item].id = item;
		  			this.props.privateList[i].messageDiscus[item].header = this.props.privateList[i].header;
		  			this.props.privateList[i].messageDiscus[item].messageDiscus = this.props.privateList[i].messageDiscus;
		  			privateMessage.push(this.props.privateList[i].messageDiscus[item]);
		  		}
		  	}
		  	
		  	for(let j=0; j<privateMessage.length; j++)
		  	{
		  		if(privateMessage[j].read == false && privateMessage[j].user.id != user._id)
		  		{
		  			N++;
		  			privateMessage[j].type = "private";
		  			publicM.push(privateMessage[j]);
		  			this.setState({Nnotif: N});
		  			publicM.sort(function(a,b)
		        	{
				      let _a = Date.parse(a.createdAt);
				      let _b = Date.parse(b.createdAt)
				      return _b - _a;
				  	});
		        	this.setState({globalMessage: publicM})
		  		}
		  	}
		  this.setState({Nnotif: N});
			publicM.sort(function(a,b)
	    	{
		      let _a = Date.parse(a.createdAt);
		      let _b = Date.parse(b.createdAt)
		      return _b - _a;
		  	});
	    	this.setState({globalMessage: publicM})
		}

	}
	
	componentWillReceiveProps(nextProps)
	{
		const user = (localStorage.getItem('user') && localStorage.getItem('user') != "undefined") ? JSON.parse(localStorage.getItem('user')) : null;
		let self = this;
		if(nextProps != this.props)
		{
			
		  let publicM = [];
	      let N = 0;
	      let timer = 0;
	      let notifList = this.state.notifList;	
		  if(nextProps.publicMessage)
		  {
		    const message = nextProps.publicMessage;
		    
		    for(var i=0; i<message.length; i++)
		    {
		      
		      if(message[i].read==false && message[i].mention && message[i].mention.user.id == user._id)
		      {
		      	N++;
		      	publicM.push(message[i])
	        	this.setState({Nnotif: N})
	        	publicM.sort(function(a,b)
	        	{
			      let _a = Date.parse(a.createdAt);
			      let _b = Date.parse(b.createdAt)
			      return _b - _a;
			  	});

			  	
			  	if(!notifList.some(e => e.id == message[i].id))
			  	{
			  		timer += 1000;
			  		let item = message[i];
			  		setTimeout(function(){
			  			self.showNotifications(item);}.bind(item),timer)
			  		notifList.push(message[i]);
			  		this.setState({notifList: notifList});
			  		
			  	}
	        	this.setState({globalMessage: publicM})
		      }
		      if(message[i].read==false && message[i].mentions)
		      {
		      	let  mention = message[i].mentions.filter(e => (e._id == user._id))
		      	if(mention.length > 0)
		      	{
		      		
		      		N++;
			      	publicM.push(message[i])
		        	this.setState({Nnotif: N})
		        	publicM.sort(function(a,b)
		        	{
				      let _a = Date.parse(a.createdAt);
				      let _b = Date.parse(b.createdAt)
				      return _b - _a;
				  	});

				  	if(!notifList.some(e => e.id == message[i].id))
				  	{
				  		timer += 1000;
				  		let item = message[i];
				  		setTimeout(function(){
				  			self.showNotifications(item);}.bind(item),timer)
				  		notifList.push(message[i]);
				  		this.setState({notifList: notifList});
				  		
				  	}
		        	this.setState({globalMessage: publicM})
		      	}
		      }
		    }
	    	this.setState({Nnotif: N})
	    	publicM.sort(function(a,b)
        	{
		      let _a = Date.parse(a.createdAt);
		      let _b = Date.parse(b.createdAt)
		      return _b - _a;
		  	});
	    	this.setState({globalMessage: publicM})	
 
		  }

		  if(nextProps.groupList)
		  {
		  	
		  	let groupMessage = [];
		   	
		   	for(let i=0; i<nextProps.groupList.length; i++)
		   	{
		   		groupMessage = groupMessage.concat(nextProps.groupList[i].messages)
		   	}
		   	for(let j=0; j<groupMessage.length; j++)
		   	{

	   		  if(groupMessage[j].read==false && groupMessage[j].mention && groupMessage[j].mention.user.id == user._id)
		      {
		      	N++;
		      	groupMessage[j].type ="group"
		      	publicM.push(groupMessage[j])
	        	this.setState({Nnotif: N})
	        	publicM.sort(function(a,b)
	        	{
			      let _a = Date.parse(a.createdAt);
			      let _b = Date.parse(b.createdAt)
			      return _b - _a;
			  	});
			  	if(!notifList.some(e => e.id == groupMessage[j].id))
			  	{
			  		timer += 1000;
			  		let item = groupMessage[j];
			  		setTimeout(function(){
			  			self.showNotifications(item);}.bind(item),timer)
			  		notifList.push(groupMessage[j]);
			  		this.setState({notifList: notifList});
			  		
			  	}
	        	this.setState({globalMessage: publicM})
		      }
		   	}
		   	this.setState({Nnotif: N})
        	publicM.sort(function(a,b)
        	{
		      let _a = Date.parse(a.createdAt);
		      let _b = Date.parse(b.createdAt)
		      return _b - _a;
		  	});
        	this.setState({globalMessage: publicM})

		  }

		  //////////////////// PRIVATE ////////////////////////
		  if(nextProps.privateList)
		  {
		  	let privateMessage = [];
		  	for(let i=0; i<nextProps.privateList.length; i++)
		  	{
		  		for(var item in nextProps.privateList[i].messageDiscus)
		  		{
		  			nextProps.privateList[i].messageDiscus[item].id = item;
		  			nextProps.privateList[i].messageDiscus[item].header = nextProps.privateList[i].header;
		  			nextProps.privateList[i].messageDiscus[item].messageDiscus = nextProps.privateList[i].messageDiscus;
		  			privateMessage.push(nextProps.privateList[i].messageDiscus[item]);
		  		}
		  	}
		  	
		  	for(let j=0; j<privateMessage.length; j++)
		  	{
		  		if(privateMessage[j].read == false && privateMessage[j].user.id != user._id)
		  		{
		  			N++;
		  			privateMessage[j].type = "private";
		  			publicM.push(privateMessage[j]);
		  			this.setState({Nnotif: N});
		  			publicM.sort(function(a,b)
		        	{
				      let _a = Date.parse(a.createdAt);
				      let _b = Date.parse(b.createdAt)
				      return _b - _a;
				  	});

				  	if(!notifList.some(e => e.id == privateMessage[j].id))
				  	{
				  		timer += 1000;
				  		let item = privateMessage[j];
				  		setTimeout(function(){
				  			self.showNotifications(item);}.bind(item),timer)
				  		notifList.push(privateMessage[j]);
				  		this.setState({notifList: notifList});
				  		
				  	}
		        	this.setState({globalMessage: publicM})

		  		}
		  	}
		  this.setState({Nnotif: N});
			publicM.sort(function(a,b)
	    	{
		      let _a = Date.parse(a.createdAt);
		      let _b = Date.parse(b.createdAt)
		      return _b - _a;
		  	});
	    	this.setState({globalMessage: publicM})
		}
	}
	}
	
  searchUpdated (term) {
  	this.setState({searchTerm: term})
  	if(term == "")
  	{
  		this.props.mereProps.setState({search: false});
  	}
  	else
  	{
  		const filteredUsers = this.props.contact.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
  		this.props.mereProps.setState({search: true})
  		this.props.mereProps.setState({contactResult : filteredUsers}) 
  	}
  }
	render()
	{
		if(this.state.redirect)
		{
			return (<Redirect to={this.props.previous} />)
		}
		if(this.props.search)
		{
			return(
				
				<div className={this.classes.topbar}>
		       <Icon className={this.classes.chevron} onClick={this.handleRedirect.bind(this)}>chevron_left</Icon>
		       <h3 className={this.classes.title}>{this.props.title}</h3>
		       <Icon  className={this.classes.right} >search</Icon>
		       <SearchInput className="search-input" style={{'marginRight' : '12px'}} onChange={this.searchUpdated.bind(this)} />
                
		       <Badge className={this.classes.margin} badgeContent={this.state.Nnotif} color="secondary">
		          <PermIdentityIcon 
		          aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
		          aria-haspopup="true"
		          variant="contained"
		          onClick={this.handleClick.bind(this)}/> 
		        </Badge>   		        
		        
		       <SwipeableTemporaryDrawer actualLink={this.props.actualLink?this.props.actualLink:""}/>
		       <Notification
		          ignore={this.state.ignore && this.state.title !== ''}
		          notSupported={this.handleNotSupported.bind(this)}
		          onClick={this.handleNotificationOnClick.bind(this)}
		          timeout={10000}
		          title={this.state.title}
		          options={this.state.options}
					/>
				<audio id='sound' preload='auto'>
				          <source src="./sound.mp3" type='audio/mpeg' />
				          <source src="./sound.ogg" type='audio/ogg' />
				          <embed hidden='true' autostart='false' loop='false' src="./sound.mp3" />
				</audio>
		       <StyledMenu
		        id="simple-menu"
		        anchorEl={this.state.anchorEl}
		        open={Boolean(this.state.anchorEl)}
		        onClose={this.handleClose.bind(this)}
		      >
		      {this.state.globalMessage.length > 0 ?
		        <div>
		        { this.state.globalMessage.map( (item, i) => (
		          <div>
		            {item.read == false && item.type == "group" && 
		              <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {this.clickedGroupMessage(item)}}>
		                <div style={{display: "flex", flexDirection: "column"}}>
		                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
		                    <span style={{color:"red"}}>{item.nameGroup} : </span>
		                    {item.text}
		                  </div>
		                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
		                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
		                  </div>
		                </div>
		              </StyledMenuItem>
		            }
		            {item.read == false && item.type == "public" &&
		            <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {this.clickedPublicMessage(item)}}>
		                <div style={{display: "flex", flexDirection: "column"}}>
		                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
		                    <span style={{color:"red"}}>Public : </span>
		                    {item.text}
		                  </div>
		                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
		                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
		                  </div>
		                </div>
		              </StyledMenuItem>
		            }
		            {item.read == false && item.type == "private" &&
		            <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {this.clickedPrivateMessage(item)}}>
		                <div style={{display: "flex", flexDirection: "column"}}>
		                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
		                    <span style={{color:"red"}}>Privée : </span>
		                    {item.message}
		                  </div>
		                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
		                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
		                  </div>
		                </div>
		              </StyledMenuItem>
		            }
		            {item.user.name}
		          </div>))}
		        </div>
		        :
		        <div style={{padding: "16px"}}>Aucune notification</div>
		      }
		        </StyledMenu>
		    </div>

			)
		}
		else
		{
			return (
		
     <div className={this.classes.topbar}>
       <Icon className={this.classes.chevron} onClick={this.handleRedirect.bind(this)}>chevron_left</Icon>
       <h3 className={this.classes.title}>{this.props.title}</h3>
       <div className={this.classes.right}>
       <Badge className={this.classes.margin} badgeContent={this.state.Nnotif} color="secondary">
          <PermIdentityIcon 
          aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          variant="contained"
          onClick={this.handleClick.bind(this)}/> 
        </Badge>   
        <SwipeableTemporaryDrawer className={this.classes.right} actualLink={this.props.actualLink?this.props.actualLink:""}/>
        
        <Notification
          ignore={this.state.ignore && this.state.title !== ''}
          notSupported={this.handleNotSupported.bind(this)}
          onClick={this.handleNotificationOnClick.bind(this)}
          onShow={this.handleNotificationOnShow.bind(this)}
          timeout={10000}
          title={this.state.title}
          options={this.state.options}
		/>
		<audio id='sound' preload='auto'>
				          <source src={audio} type='audio/mpeg' />
				          <source src={ogg} type='audio/ogg' />
				          <embed hidden='true' autostart='false' loop='false' src={audio} />
				</audio>
       </div>
       
       <StyledMenu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose.bind(this)}
      >
      {this.state.globalMessage.length > 0 ?
        <div>
        { this.state.globalMessage.map( (item, i) => (
          <div>
            {item.read == false && item.type  == "group" &&
              <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {this.clickedGroupMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>{item.nameGroup} : </span>
                    {item.text}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }
            {item.read == false  && item.type == "public" &&
            <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {this.clickedPublicMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>Public : </span>
                    {item.text}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }
            {item.read == false && item.type == "private" &&
            <StyledMenuItem style={{marginTop:"8px",marginBottom:"8px"}} onClick={()=> {this.clickedPrivateMessage(item)}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", fontStyle: "italic"}}>
                    <span style={{color:"red"}}>Privée : </span>
                    {item.message}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 400}}>
                    <div>{item.user.name} - {dateConversion(item.createdAt)}</div>
                  </div>
                </div>
              </StyledMenuItem>
            }

          </div>))}
        </div>
        :
        <Redirect to='/profil' />
      }
        </StyledMenu>

    </div>
    );
		}
	}
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(Header)));