import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import Icon from '@material-ui/core/Icon';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import femme from './../../assets/images/avatar/profile.png';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import URL_API_base from '../../path';
import ContactService from "./Contact.service.js";
import config from '../../path';
import SendIcon from '@material-ui/icons/Send';
import PhoneIcon from '@material-ui/icons/Phone';
import LabelIcon from '@material-ui/icons/Label';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Header from '../Layout/newHeader';
import Headers from '../Layout/header-s'
import AlertDialogSlide from './Dialog';
import UpdateDialogSlide from './UpdateDialog';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import ReactPixel from 'react-facebook-pixel';

import { withRouter } from 'react-router-dom';
const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const styles = theme =>  ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingTop: "30px",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    // paddingBottom: theme.spacing.unit * 8
  },
  card: {
    // width: "100%",
    // marginTop: theme.spacing.unit * 2,
    padding: "10px",
    borderRadius: "12px",
    marginBottom:"30px !important",
    display : "flex",
    flexDirection : "row"
  },

  logo: {
    width: "70%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius:"50%",
    border: "2px solid #6dd49d",
    boxShadow: "0px 7px 10px #aaa",
    marginTop: "25px",
    marginBottom: "25px"
  },
  center:{
    textAlign: 'center',
    marginTop: 0
  },

  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"300px",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"300px",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
    marginRight: "20px"
  },
  logout:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "underline",
  },




  loading:{
    textAlign: 'center',
    marginTop: "190px",
    height: "300px"
  },
  circularProgress:{
    color: teal[300]
  },
  title:{
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  submit:{
    textAlign: 'center',
    marginTop: "20px",
  },
  icon:{
    position: "relative",
    top: "5px",
    marginRight: "5px",
    color: "#72E299"
  },
  error:{
    color: "#FF0000 !important",
    textAlign: "center"
  },
  titlePrimary:{
    color: "#70DF9D",
    fontSize: "1.2em",
    marginRight: "20px",
    marginTop: "15px",
    fontWeight: "bold"
  },

  text:{
    fontSize : "12px",
    color : '#3E8090',
    textTransform: "uppercase"
  },
  footer:{
    position: "relative",
    bottom: "0"
  },
  right:{
    textAlign:"right"
  },
  info:{
    backgroundColor: "#3B808E",
    color:"white",
    marginRight: "10px"
  },
  buttonSecondary:{
    backgroundColor: amber[500],
    color:"white"
  },
  buttonPlay:{
    backgroundColor: "#65C66A",
    color:"white"
  },
  iconMenu:{
    color: "#767676"
  }
});

class Contact extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
      isLoading: true,
      contacts:[],
      training: {},
      anchorEl: null,
      search : false,
      contactResult : []

    };
    console.log("main %o",this.classes);
  }

  /**
   * list TrainingDetail init
   */
  componentDidMount(){
  ReactPixel.init("714205238765459");
  ReactPixel.pageView();    
    this.listContacts({
      "searchValue": localStorage.getItem('user')?JSON.parse(localStorage.getItem('user'))._id:null,
      "searchFields": [
        "userId"
      ]
    })
  }
  deleteCt(id){

  ContactService.deleteContact(id).then(function(){
     this.listContacts({
      "searchValue": localStorage.getItem('user')?JSON.parse(localStorage.getItem('user'))._id:null,
      "searchFields": [
        "userId"
      ]
  })
  })
}
componentDidUpdate(prevProps, prevState){
  /*this.listContacts({
      "searchValue": localStorage.getItem('user')?JSON.parse(localStorage.getItem('user'))._id:null,
      "searchFields": [
        "userId"
      ]
  })*/

  if (prevProps.data !== this.props.data) {

  }

}
  /**
     * list Training
     * @param {*} data
     */
    async listContacts(data){
        try{
        const responseJson = await ContactService.getContacts(data);
        console.log("responseJson %o", responseJson);
        if(responseJson){
            this.setState({
                contacts: responseJson.rows,
            }, function(){
              console.log("contacts %o",this.state.contacts)
          });
        }
        } catch (e) {
        console.error(e);
      }
    }

    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };


  render(){
    console.log("user %o",localStorage.getItem('user'));
    let self = this
    const { anchorEl } = this.state;
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;


    return (
      <div>
        {/*<Header actualLink="/contact" title="Contacts" previous={'/training'}/>*/}
        <Header actualLink="/contact" title="Contacts" search={true} previous={'/training'} contact={this.state.contacts} mereProps = {this}/>
        <main className={this.classes.main} theme={theme}>
           <AlertDialogSlide  mereProps={this} label={'Ajouter'}/>
            {!this.state.search && 
              <div>
             {this.state.contacts.map(function(contact,i){
                return <div>
                  <Paper className={self.classes.card} elevation={5}>

                      <div style={{flexBasis: "100px", borderRight: "2px solid rgba(0,0, 0, 0.2)" , padding: 0}}>
                        <img src={femme} alt="Logo" className={self.classes.logo}/>
                      </div>
                      <div style={{flex: 1, display: "flex", flexDirection: "column", paddingLeft: "10px"}}>
                        <Typography align="right" style={{fontSize: "14px",  color: "#70DF9D", fontWeight: 500}}>
                          {contact.lastname.toUpperCase()} {contact.firstname.toUpperCase()}
                        </Typography>
                        <Typography align="left"  className={self.classes.text}>
                           <SendIcon style={{transform: "rotate(-37deg)"}} className={self.classes.icon} /> {contact.email}
                        </Typography>

                        <Typography align="left" className={self.classes.text}>
                           <PhoneIcon style={{transform: "rotate(-89deg)"}} className={self.classes.icon} />{contact.phone}
                        </Typography>

                        <Typography  align="left" className={self.classes.text}>
                           <LabelIcon className={self.classes.icon} />More Info
                        </Typography>
                        <Button
                            aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={self.handleClick}
                            className={self.classes.iconMenu}
                            style= {{alignSelf: "flex-end", transform : "translateY(-150%)"}}
                          >
                            <MoreVertIcon/>
                          </Button>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={self.handleClose}
                          >
                            <MenuItem className={self.classes.iconMenu} ><UpdateDialogSlide contact={contact}/></MenuItem>

                            <MenuItem className={self.classes.iconMenu}  onClick={()=>self.deleteCt(contact._id)}><Icon>delete</Icon></MenuItem>
                          </Menu>
                      </div>

             </Paper>
                </div>
             })}
             </div>
           }
           {this.state.search &&
            <div>
            
            {this.state.contactResult.map(function(contact,i){
              
                return <div>

                  <Paper className={self.classes.card} elevation={5}>
                      <div style={{flexBasis: "100px", borderRight: "2px solid rgba(0,0, 0, 0.2)" , padding: 0}}>
                        <img src={femme} alt="Logo" className={self.classes.logo}/>
                      </div>
                      <div style={{flex: 1, display: "flex", flexDirection: "column", paddingLeft: "10px"}}>
                        <Typography align="right" style={{fontSize: "14px",  color: "#70DF9D", fontWeight: 500}}>
                          {contact.lastname.toUpperCase()} {contact.firstname.toUpperCase()}
                        </Typography>
                        <Typography align="left"  className={self.classes.text}>
                           <SendIcon style={{transform: "rotate(-37deg)"}} className={self.classes.icon} /> {contact.email}
                        </Typography>

                        <Typography align="left" className={self.classes.text}>
                           <PhoneIcon style={{transform: "rotate(-89deg)"}} className={self.classes.icon} />{contact.phone}
                        </Typography>

                        <Typography  align="left" className={self.classes.text}>
                           <LabelIcon className={self.classes.icon} />More Info
                        </Typography>
                        <Button
                            aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={self.handleClick}
                            className={self.classes.iconMenu}
                            style= {{alignSelf: "flex-end", transform : "translateY(-150%)"}}
                          >
                            <MoreVertIcon/>
                          </Button>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={self.handleClose}
                          >
                            <MenuItem className={self.classes.iconMenu} ><UpdateDialogSlide contact={contact}/></MenuItem>

                            <MenuItem className={self.classes.iconMenu}  onClick={()=>self.deleteCt(contact._id)}><Icon>delete</Icon></MenuItem>
                          </Menu>
                      </div>

             </Paper>
            </div>

           })
         }
           </div>
         }

        </main>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Contact));
