import ApiService from '../../Shared/api.service';
class ProfilService {
    getUser(id) {
        return ApiService.get('user', id)
    }

    updateUser(data,id)
    {
        return ApiService.put('user',data,id);
    }

    saveImage(data, id){
        // return ApiService.saveImage('avatar/'+id,data);
        return ApiService.postDocs("document/upload",data)
    }

    getAvatar(id){
    	return ApiService.getImage('avatar/'+id);
    }
}

export default new ProfilService();
