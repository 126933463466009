import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import teal from '@material-ui/core/colors/teal';

const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});

const errorStyle = {
    position: 'absolute',
    marginBottom: '-22px',
    color: 'red',
};
const styles = theme =>  ({


  center:{
    textAlign: 'center',
    marginTop: 0,
    width: "100%"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"95%",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"95%",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    borderColor: "white",
    borderRadius: "15px",
    backgroundColor: '#F4F5F9',
    width: "100%",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "60%"
    },


  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: teal["A700"],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: teal["A700"],
    },
  },
  notchedOutline: {

    borderColor:'#FFFFFF !important',

  },
  cssLabel: {
    '&$cssFocused': {
      color: teal["A700"],
    },
  },
  text:{
    display:"block",
    width: "100%",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%"
    },
    margin: "auto",
    color:"#B0B7B5"
  }
});

class StepOne extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.register = props.register

    console.log("register %o",this.register);
  }
  render(){
    return (
      <main>
              <div  className={this.classes.center}>
                <TextValidator

                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}
                  id="outlined-email-input"
                  label="Email"


                  className={this.classes.textField}
                  type="email"

                  autoComplete="email"
                  margin="normal"
                  variant="outlined"

                  name="email"
                  value={this.register.state.data.email}
                  key={1}
                  validators={['required', 'isEmail']}
                
                  onChange={this.register.handleChange}
                  errorMessages={['this field is required', 'email is not valid']}

                />
              </div>

              <div className={this.classes.center}>
                  <Typography align="center" className={this.classes.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Typography>

              </div>

              <div  className={this.classes.center}>
                <TextValidator

                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}

                  label="Mot de passe"
                  className={this.classes.textField}
                  type="password"

                  margin="normal"
                  variant="outlined"

                  name="password"
                  value={this.register.state.data.password}
                  key={2}
                  validators={['required']}
                  validatorListener={this.register.validatorListener}
                  onChange={this.register.handleChange}
                  errorMessages={['this field is required']}
                />
              </div>

              <div  className={this.classes.center}>
                <TextValidator

                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}

                  label="Prénom"
                  className={this.classes.textField}
                  type="text"


                  margin="normal"
                  variant="outlined"

                  name="firstname"
                  value={this.register.state.data.firstname}
                  key={3}
                  validators={['required']}
                  validatorListener={this.register.validatorListener}
                  onChange={this.register.handleChange}
                  errorMessages={['this field is required']}
                />
              </div>
              <div  className={this.classes.center}>
                <TextValidator

                  InputProps={{
                    classes: {
                      root: this.classes.cssOutlinedInput,
                      focused: this.classes.cssFocused,
                      notchedOutline: this.classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: this.classes.cssLabel,
                      focused: this.classes.cssFocused,
                    },
                  }}

                  label="Nom"
                  className={this.classes.textField}
                  type="text"


                  margin="normal"
                  variant="outlined"
                  name="lastname"
                  value={this.register.state.data.lastname}
                  key={4}
                  validators={['required']}
                  validatorListener={this.register.validatorListener}
                  onChange={this.register.handleChange}
                  errorMessages={['this field is required']}
                />
              </div>






        </main>

    );
  }
}

export default withStyles(styles)(StepOne);
