import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import ChatService from "./Chat.service.js";
import Backend from "./Firebase.service.js";
import Fab from '@material-ui/core/Fab';
import './dialog.css';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootList: {
    width: '100% !important',

    backgroundColor: theme.palette.background.paper,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    '&$cssFocused': {
      color: purple[500],
    },
  },

  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: purple[500],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: purple[500],
    },
  },
  notchedOutline: {},
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: { useNextVariants: true },
});
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function Transition(props) {

  return <Slide direction="left" {...props} />;
}

class UpdateGroupeSlide extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      nom: '',
      prenom:"",
      usersId:{},
      users:[],
      savable:false,
      displayIn:{},
      groupUsers:[],
      open: props.parent.state.openEdit

  };

    this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount(){
    this.configure(this.props);
    console.log("edit group : ",this.props.group)
  }

  configure(props){
    let self = this;
 
    this.setState({
      groupUsers:props.group.users
    })
    let users = this.state.usersId;
    let allUsers = this.props.users;
    let propsUser = props.group.users
    for(var i = 0;i<propsUser.length;i++){
       for (var j = 0 ;j<allUsers.length;j++){
         if(allUsers[j]._id == propsUser[i]){
           users[j.toString()] = true
         }
         // console.log(allUsers[j]._id == propsUser[i])
       }
    }
    this.setState({
      nom:props.group.nameGroup,
      usersId:users
    },function(){
      self.savebleGroupe(allUsers)
    })
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleCheck = (name,usersToSave) => event => {
    let users = this.state.usersId;
    users[name] = event.target.checked;
    this.setState({ usersId: users});
    this.savebleGroupe(usersToSave);
  };
  handleClose() {
    this.props.handler();
  };
  saveMessageGroupe = ()=>{
    let idTokenMe = localStorage.getItem('idToken');
   
    Backend.addUserMessageGroupExist(idTokenMe,this.state.nom,this.state.users,this.props.group.id);
  }

  handleChange(event) {
    let key = event.target.name
    this.setState({[event.target.name]: event.target.value},function(){
     
    });
  }
  handleFilter = (users,event)  =>{

    this.setState({prenom: event.target.value});

    let index=[]
    let usernames = [];
    for (var i = 0 ;i<users.length; i++){
      let username = users[i].firstname+' '+users[i].lastname;
      usernames.push(username);
    }
    let displayIn = this.state.displayIn;
    for (i=0;i<usernames.length;i++){
      if(usernames[i].toUpperCase().indexOf(this.state.prenom.toUpperCase())>-1){
        displayIn[i]={display:"", width:"70%"}
        // index[i]=usernames[i].toUpperCase().indexOf(this.state.prenom.toUpperCase())
      }
      else{
        displayIn[i]={display:"none", width:"70%"}
        // index[i]=usernames[i].toUpperCase().indexOf(this.state.prenom.toUpperCase())
      }
    }
    // console.log(displayIn);
    this.setState({displayIn:displayIn})
  }
  resetState =()=>{
    for(var key in this.state){
       this.setState({
         [key]:''
       })
    }
  }
  savebleGroupe(usersToSave){
 
    // console.log("users To save",usersToSave);

    let users =[];
    for(var key in this.state.usersId){
      if(this.state.usersId[key] === true) {
        users.push(usersToSave[key]._id)
      }
    }

    if(users.length>1){
      this.setState({
        savable:true,
        users:users
      },()=>{console.log("savable",this.state.savable);})
    }
    else{
      this.setState({
        users:users,
        savable:false,
      },()=>{console.log("savable",this.state.savable);})
    }
  }
  render() {

   /* console.log(this.props.group);
    console.log(this.props);
    let users = this.state.usersId;
    for(var i=0 ;i<this.props.users.length;i++){
      for(var j = 0 ;j<this.props.group.users.length;j++){
        if(this.props.users[i]==this.props.group.users[j]){
          users[i]=true
        }
      }
    }
    this.setState({
      usersId:users,
      nom:this.props.group.nameGroup
    })*/
    // console.log("user %o",localStorage.getItem('user'));
    const classes={
      input:{
      minHeight: "32px",
      fontSize: "14px",
      borderRadius: "0.5em",
      border: "solid #F4F5F9 2px",
      background: "#F4F5F9",
      marginBottom: "20px"

    },
    label:{
      color: "#828B88"
    },
     button:{
       color:"white",
       background: "linear-gradient(60deg,#439598, #6CD898)",
       width:"100%",
       fontWeight: "bold",
       borderRadius: "2em"
     },
     buttonSimple:{
       color:"white",
       background: "#D5D5D5",
       width:"100%",
       fontWeight: "bold",
       borderRadius: "2em"
     }
    }
    return (
      /*
      <Grid item xs={12}>
                    <FormControl fullWidth="true" >
                    <label style={classes.label} >Nom du Groupe*:</label>
                    <input style={classes.input} name="nom" value={this.state.nom} onChange={this.handleChange} fullWidth="true"/>
                    </FormControl>
                    </Grid>*/
      <div>

        <Dialog
          open={this.props.parent.state.openEdit}
          TransitionComponent={Transition}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullScreen={true}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                <Grid container>

                    <Grid item xs={12}>
                      <FormControl fullWidth="true">
                        <label style={classes.label} >Amis:</label>
                        <input style={classes.input} placeholder="Tapez vos recherches ici" name="prenom" value={this.state.prenom} onChange={this.handleFilter.bind(this,this.props.users)}/>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <List className={classes.rootList}>
                      {this.props.users.map((user,i)=>{
                        return (
                          <ListItem style={this.state.displayIn[i]}>
                            <Avatar>
                              <ImageIcon />
                            </Avatar>
                            <ListItemText primary={<Typography>{user.firstname} {user.lastname}</Typography>} />
                            <Checkbox

                              checked={this.state.usersId[i]?"true":0}

                              onChange={this.handleCheck(i,this.props.users)}
                              value={user._id}
                              color="primary"
                            />
                          </ListItem>
                          )
                      })}
                      </List>
                    </Grid>
                </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={classes.buttonSimple}
             onClick={()=>{this.handleClose();/*this.resetState()*/}} color="primary">
              Annuler
            </Button>
            <Button
             style={(!this.state.savable ||!this.state.nom) ? classes.buttonSimple  : classes.button}
             disabled={
                !this.state.nom ||
                !this.state.savable
             }
             onClick={()=>{
               this.saveMessageGroupe();
               this.handleClose();
               this.props.parent.reloadGroup()
              }
             } color="primary">
              Enregistrer
            </Button>

          </DialogActions>
        </Dialog>


       

      </div>
    );
  }
}

export default UpdateGroupeSlide;
