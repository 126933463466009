import { AsyncStorage } from 'react-native';
class WebhookService {
get(id) {
        return new Promise(async (resolve, reject) => {
            //const URL = `${config.URL_API_base}/${entity}/${id}`;
            const URL = `https://api.mollie.com/v2/payments/${id}`;
            try {
                const TokenAwait = localStorage.getItem('userToken');
                const Token = `Bearer ${TokenAwait}`;
                const response = await fetch(URL, {
                    method: "GET",
                    headers: {
                        "Authorization": Token,
                        Accept: 'application/json',
                        "Content-Type": "application/json",
                        
                        //"zumo-api-version" : "2.0.0"
                    },
                    //testmode: true
                })
                console.log('> response log ::  ', response)
                if (!this.hasNoError(response)) {
                    reject('server error')
                } else {
                    resolve(response.json());
                }
            } catch (error) {
            }
        });
    }
}
export default new WebhookService();