import ApiService from '../../Shared/api.service';
class TrainingService {
    get(data) {
        return ApiService.post('trainings', data)
    }
    getUser(id) {
        return ApiService.get('user', id)
    }
    addSubscr(data, id) {
        return ApiService.put('user', data, id)
    }
    getSubscription(id)
    {
    	return ApiService.get('userSubscription',id)
    }
    updateUser(data,id)
    {
        return ApiService.put('user',data,id);
    }
    getPromoCode(code)
    {
        return ApiService.get('promoCode/code',code );
    }
}

export default new TrainingService();