import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Moment from 'react-moment';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import MessageIcon from '@material-ui/icons/Message';
import Icon from '@material-ui/core/Icon';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddAPhoto from '@material-ui/icons/AddAPhoto';


import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import femme from './../../assets/images/avatar/profile.png';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';

import config from '../../path';
import SendIcon from '@material-ui/icons/Send';
import PhoneIcon from '@material-ui/icons/Phone';
import LabelIcon from '@material-ui/icons/Label';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';

import Header from '../Layout/newHeader';
import Headers from '../Layout/header-s'
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ForumIcon from '@material-ui/icons/Forum';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import GroupIcon from '@material-ui/icons/Group';

import Backend from './Firebase.service.js';
import {GiftedChat,Send,View, Bubble, Actions} from 'react-web-gifted-chat';
import AddGroupeSlide from './AddGroupe.js'
import UpdateGroupeSlide from './UpdateGroup.js'
import ChooseFriendSlide from './PrivateChatSlide.js'
import ChatService from './Chat.service.js'
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Modal from 'react-modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import CloseIcon from '@material-ui/icons/Close';

import {connect} from 'react-redux';

import URL_API_base from '../../path';
import ApiService from '../../Shared/api.service';

import { withRouter } from 'react-router-dom';

import { MentionsInput, Mention } from 'react-mentions';
import BackendNotif from '../../notification.service'; 

import ReactPixel from 'react-facebook-pixel';


const mapStateToProps = (state) => {
  return {publicMessage: state.publicMessage , groupMessage : state.groupMessage,curPrivate: state.curPrivate, curGroup : state.curGroup, users: state.users,

    groupList : state.groupList, showGroup : state.showGroup, privateList : state.privateList, curPublic: state.curPublic
  }
}

function MyComponent(props) {
  const matches = useMediaQuery('(min-width:600px)');

  return (<div>{props.children}</div>);
}


// const matches = useMediaQuery('(max-width:375px)');

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding: "16px",
    color: "black"
  },
  overlay: {
    background: "transparent"
  }
};



const muiTheme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});

function TabContainer(props) {
  return (
    <Typography component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme =>  ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: "transparent",
    overflow: "hidden"
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 1,
    // marginRight: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 1,

    paddingTop: "0px",

    [theme.breakpoints.up('800.001')]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('800')]: {
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    },

  },
  card: {
    // width: "100%",
    // marginTop: theme.spacing.unit * 2,
    padding: "20px",
    borderRadius: "12px",
    marginBottom:"10px !important",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      padding: "20px",
      borderRadius: "12px",
      marginBottom:"30px !important",
    },
  },

  logo: {
    backgroundColor: "#B4F9D8",
    color: "#fff",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    // width:"45px",
    // heigth:"30px",

    // boxShadow: "0px 2px 4px #aaa",
    marginTop: "10px",
    marginBottom: "10px"
  },
  center:{
    textAlign: 'center',
    marginTop: 0
  },

  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"300px",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"300px",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
    marginRight: "20px"
  },
  logout:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "underline",
  },

  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"90%",
    fontWeight: "bold"
  },


  loading:{
    textAlign: 'center',
    marginTop: "190px",
    height: "300px"
  },
  circularProgress:{
    color: teal[300]
  },
  title:{
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  submit:{
    textAlign: 'center',
    marginTop: "20px",
  },
  icon:{
    position: "relative",
    top: "5px",
    marginRight: "5px",
    color: "#72E299"
  },
  error:{
    color: "#FF0000 !important",
    textAlign: "center"
  },
  titlePrimary:{
    color: "#428898",
    fontSize: "1.2em",
    marginRight: "20px",
    marginTop: "15px",
    textTransform: "uppercase"
  },

  text:{
    fontSize : "14px",
    color : '#AEB7B4',
    textTransform: "uppercase"
  },
  footer:{
    position: "relative",
    bottom: "0"
  },
  right:{
    textAlign:"right"
  },
  info:{
    backgroundColor: "#3B808E",
    color:"white",
    marginRight: "10px"
  },
  buttonSecondary:{
    backgroundColor: amber[500],
    color:"white"
  },
  buttonPlay:{
    backgroundColor: "#65C66A",
    color:"white"
  },
  iconMenu:{
    color: "#767676"
  },
  container: {
    display:'flex',
    flexDirection: 'row',
    background:'none',
    height:"57vh", 
    overflow: "hidden",
    marginLeft: "20px",
    paddingLeft :"20px",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      height : "75vh"
    }


  },
  container3: {

    background:'none',"height":"50vh",overflow: "hidden",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {

      background:'none',"height":"60vh",overflow: "hidden",
    }

  },
  container2: {
    display:'flex',
    flexDirection: 'row',
    background:'none',"height":"40vh",overflow: "hidden",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      display:'flex',
      flexDirection: 'row',
      background:'none',"height":"60vh",overflow: "hidden"
    }

  },
  conversationList: {
    display:'flex',
    flex: 1,
  },
  chat: {
    display:'flex',/*
    borderBottomWidth: '1px',
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',*/
    backgroundColor: "transparent",
    display: "flex",
    width: "100%",
    overflow: "hidden",
    // minHeight: "4000px",
    // height: 'auto',


  },
  chatPrivate: {
    display:'flex',
    flex: 3,
    borderWidth: '1px',
    borderColor: '#ccc',
    borderStyle: 'solid',
    minHeight: '1000px',

  },
  converationDetails: {
    display:'flex',
    flex: 1,
  },
  rightIcon:{
    position: "relative",
    top: "35%",
    color: "#B4F9D8",
    fontSize: "32px"
  },

  fab:{
    backgroundColor: "#F19233",
    color:"#FFFFFF"
  },
  tabsIndicator: {
    backgroundColor: "white",
  },
  tabRoot: {
    backgroundColor: "#3C8896",
    color: "#A2C5CB",
    borderRight:"outset 1px #42868b",
    borderLeft:"outset 0.5px #6ab1a4",
    '&:hover': {
      color: "#FFFFFF",
      opacity: 1,
    },
    '&$tabSelected': {
      color: "#FFFFFF",

    },
    '&:focus': {
      color: "#FFFFFF",
    },
  },
  tabSelected: {
    color: "#FFFFFF",
  },

  textField:{
    borderColor: "white",
    borderRadius: "5px",
    backgroundColor: '#F4F5F9',
    paddingRight: "37px",
    width:"69%",
    marginLeft: "10px",
    right: "5%",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width:"42%",
      right: "1%",
      paddingRight: "50px",
    }
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: teal["A700"],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: teal["A700"],
    },
    padding: "0px"
  },
  notchedOutline: {

    borderColor:'#FFFFFF !important',

  },
  cssLabel: {
    '&$cssFocused': {
      color: teal["A700"],
    },
  },


    buttonSend:{
      color:teal[700],position: "absolute", bottom: "17px", background: "none", right: "12%",

      [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
        right: "16px",
        color:teal[700],position: "relative", background: "none", top:"0"
      }
    },
    buttonRefresh:{
      color:"white",position: "absolute", bottom: "17px", background: "none", left: "8px",

      [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
        left: "64px",
        color:"white",position: "relative", background: "none", bottom:"0"
      }
    },

    inputFixed:{
      position: "fixed", bottom: "10px", width:"100%",marginLeft:"auto",marginRight:"auto",right: "0%",
      display: "flex", flexDirection : "row",
      [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
        position: "fixed", bottom: "10px", width:"100%",marginLeft:"auto",marginRight:"auto",left:"23%", transform: "translateX(-48px)"
      }
    },
    inputFixedPrivate:{
      position: "fixed", bottom: "10px", width:"100%",marginLeft:"auto",marginRight:"auto",right: "0%",
      display: "flex", flexDirection : "row",
      [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
        position: "fixed", bottom: "10px", width:"100%",marginLeft:"auto",marginRight:"auto",left:"23%", transform: "translateX(-48px)"
      }
    },

  addButton:{
    color: teal[500]
  },

});


class Chat extends React.Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
  }
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({value: value });
  };

  users = null;
  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
      isLoading: true,
      value: 0,
      text:"",
      messages: [],
      groupMessages: [],
      idToken : localStorage.getItem('idToken'),
      firstnameToken : localStorage.getItem("firstnameToken"),
      lastnameToken : localStorage.getItem("lastnameToken"),
      dataGroup:[],
      dataGroupInit:[],
      dataGroup: [],
      dataGroupInit: [],
      groupId:false,
      curGroup: {},

      //
      dataMessage:[],
      curIdUser:false,
      curPrivateChat:{},
      dataPrivateMess:[],
      users:[],
      //////
      anchorEl: null,
      open: false,
      openEdit: false,
      messageOption: false,
      item: null,
      modalIsOpen: false,
      mention: null,
      messageType: "public",
      mentionData : [],
      mentionList : [],
      groupList : [],
      pwdIsOpen: false,
      pwdValue: "",
      checkedGroup : {},
      pwdError: false

    };

    //this.toogleEdit = this.toogleEdit.bind(this);

  }

  /**
   * list TrainingDetail init
   */
   imageChange(event)
   {
    console.log("event image upload : ",event.target.files[0]);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    let profilData = new FormData();
    if(event.target.files[0])
    {
      let name = event.target.files[0].name.split(".")[0];
      profilData.append('uploadedFile',event.target.files[0]);
      profilData.append('referenceId',user._id);
      profilData.append('referenceKind','user');
      profilData.append('internalName',name+"_"+Date.now());
      profilData.append('name',name);
      profilData.append('documentType','image')
    }
    

    ApiService.postDocs("document/upload",profilData).then(result => {
      if(result)
      {
         Backend.sendPublicPhoto({
            text:"",
            user:user,
            image : result.fullPath
          });

      }
    });
   }
   imagePrivateChange(event)
   {
    console.log("event image private upload : ",event.target.files[0]);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    let profilData = new FormData();
    if(event.target.files[0])
    {
      let name = event.target.files[0].name.split(".")[0];
      profilData.append('uploadedFile',event.target.files[0]);
      profilData.append('referenceId',user._id);
      profilData.append('referenceKind','user');
      profilData.append('internalName',name+"_"+Date.now());
      profilData.append('name',name);
      profilData.append('documentType','image')
    }
    

    ApiService.postDocs("document/upload",profilData).then(result => {
      if(result)
      {
        let today = new Date();
        let timestamp = today.toISOString();
        let messages = {
            createdAt:timestamp,
            image : result.fullPath,
            user:{
                _id: user._id, 
                id: user._id,
                name: user.firstname + " " + user.lastname,
                nameUserChat:this.state.curUserName
            }
          }
          Backend.sendPrivatePhoto(this.state.idToken,this.state.curIdUser,messages);
      }
    });
   }

   imageGroupChange(event)
   {
    console.log("event image group upload : ",event.target.files[0]);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    let profilData = new FormData();
    if(event.target.files[0])
    {
      let name = event.target.files[0].name.split(".")[0];
      profilData.append('uploadedFile',event.target.files[0]);
      profilData.append('referenceId',user._id);
      profilData.append('referenceKind','user');
      profilData.append('internalName',name+"_"+Date.now());
      profilData.append('name',name);
      profilData.append('documentType','image')
    }
    

    ApiService.postDocs("document/upload",profilData).then(result => {
      if(result)
      {
        let today = new Date();
        let timestamp = today.toISOString();
        let messages = {
            createdAt:timestamp,
            image : result.fullPath,
            user:{
                _id: user._id, 
                id: user._id,
                name: user.firstname + " " + user.lastname
            }
          }
          Backend.sendGroupPhoto(this.state.curGroup,messages,null);
      }
    });
   }

    dateConversion(value)
    {
      let months = ['Jan','Feb','Mar',]
      let date = new Date(value);
      let minutes = 0;
      if(date.getMinutes()<=9)
        minutes = '0'+date.getMinutes();
      else
        minutes = date.getMinutes();
      
      return date.toLocaleDateString() + ' ' + date.getHours() + ":" + minutes
    }
   isMyMessage(item)
   {  
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    let currentMessage = JSON.parse(item);

    if(currentMessage && currentMessage.user.id == user._id) {
      return true;
    }
    else
    {
      return false;
    }
   }
   answerTo() {
    let currentMessage = JSON.parse(this.state.item);
     this.setState({mention: currentMessage});
   }
   sendMpTo()
   {
    let currentMessage = JSON.parse(this.state.item);
     //this.setState({mention: currentMessage});
     console.log("sendMpTo : ",currentMessage);
     console.log("private chat: ",this.props.privateList);
     for(let i=0; i<this.props.privateList.length; i++)
     {
      if(this.props.privateList[i].header.idUser == currentMessage.user._id)
      {
        this.setState({value: 2})
        this.selectPrivateChat(this.props.privateList[i]);
        return;
      }
     }
     this.setState({value: 2})
     let header = {
      createdAt : "2019-06-11T12:25:37.093Z",
      idUser : currentMessage.user._id,
      lastMessage :"",
      userName: currentMessage.user.name
     }
     this.selectPrivateChat({header : header});

     
   }
   archiveMessage()
   {
    let currentMessage = JSON.parse(this.state.item);
    
    Backend.archiveMesssage(currentMessage.id);
    /*this.setState({messages:[]});
    Backend.loadMessages(message => {

      this.setState(previousState => {
        if(message.user.id){
          return {
            messages: GiftedChat.append(previousState.messages, message)
          };
        }

      });


      },{
        id:this.state.idToken,
        name:`${this.state.firstnameToken} ${this.state.lastnameToken}`
      });*/
   }
   archiveMessageGroup(item)
   {
    let currentMessage = JSON.parse(this.state.item);
    Backend.archiveMessageGroup(currentMessage._id,this.state.curGroup.firebaseMessageId);
    this.setState({groupMessages:[]});

    Backend.sendNameMessageGroupExist(this.state.curGroup.firebaseMessageId, this.state.curGroup.members);

    Backend.loadMessagesGroup( message => {

          this.setState(previousState => {
            return {
              groupMessages: GiftedChat.append(previousState.groupMessages, message)
            };
          });
    });
   }
   archiveMessagePrivate()
   {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    let currentMessage = JSON.parse(this.state.item);
    Backend.archiveMessagePrivate(currentMessage.id,user._id,this.state.curPrivateChat.idUser);
    this.setState({dataPrivateMess:[]});
    Backend.loadMessagesFePriv( message => {

      this.setState(previousState => {
        return {
          dataPrivateMess: GiftedChat.append(previousState.dataPrivateMess, message)
        };
      });

    });
   }

  isGroupMember(group)
  {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if(group.members)
    {
      if(group.members.length > 0)
      {
        if(group.members.some(e => e == user._id))
        {
          console.log("user _id : ",user._id);
          console.log("group list : ",group.members)
          return true
        }
        else
        {
          return false
        }
      }
      else
      {
        return false;
      }
    }
    else
    {
      return false
    }

  }
  leaveGroup(group)
  {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    /*let finalGroup = group.members.filter(e => e != user._id);
    group.members = finalGroup;
    let index = this.state.groupList.findIndex(e => e._id == group._id);
    let oldGroupList = this.state.groupList;
    oldGroupList[index]= group;*/
    
    ApiService.post('group/'+group._id+'/left').then(result => {
      let index = this.state.groupList.findIndex(e => e._id == group._id);
      let oldGroupList = this.state.groupList;
      oldGroupList[index]= result;
      this.setState({groupList : oldGroupList, groupId: null});
    });
    
  }
  async joinGroup(group)
  {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if(group.members)
    {
      group.members.push(user._id);
    }
    else
    {
      group.members = [];
      group.members.push(user._id)
    }
    let index = this.state.groupList.findIndex(e => e._id == group._id);
    let oldGroupList = this.state.groupList;
    oldGroupList[index]= group;
    let data = {
      "name": group.name,
      "members": group.members,
      "password": group.password,
      "kind": group.kind,
      "firebaseMessageId": group.firebaseMessageId
    }
    ApiService.post('group/'+group._id+'/join').then(result => {
      if(result.error)
      {
        alert("Le groupe n'est pas ouvert")
      }
      else
      {
        this.setState({groupList : oldGroupList});
      }
    });
  }
  openModal(item) {
    this.setState({item : item});
    this.setState({modalIsOpen: true});
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  closePwd()
  {
    this.setState({pwdIsOpen: false})
  }
  afterOpenModal() {
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };


  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };
  handleClickMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };
  async listContacts(data){
        try{
        const responseJson = await ChatService.getUsers(data);
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        let users = [];
        if(responseJson){
            for(let i=0; i<responseJson.rows.length; i++)
            {
              users.push({id : responseJson.rows[i]._id, display: responseJson.rows[i].firstname + ' ' + responseJson.rows[i].lastname})
            }
            this.setState({
                users: responseJson.rows,
            })
        }
         this.setState({mentionData : users})
        } catch (e) {
          alert(e);
      }
    }
  async reloadGroup(){
  /*this.setState({
    dataGroup:[]
  })
  await  Backend.sendNameMessageGroup(this.state.idToken);
  let  dataGroup =await Backend.loadTablesMessage();
    if(dataGroup){
      this.setState({
        dataGroup: dataGroup,
        dataGroupInit: dataGroup
      })
      this.setState({isLoading : false});
    }
*/
  }
  async reloadPrivate(){
    /*Backend.getPrivateMessage().on('value', (response) => {
      let privee = response.val();
      console.log('reloaded private: ',privee);
      const action = { type: "SET_PRIVATENAME", value: privee }
      this.props.dispatch(action);
    }) */
  }
  async componentDidMount(){
    ReactPixel.init("714205238765459");
    ReactPixel.pageView();
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    this.users = await ApiService.post('users', {tabIds: []});

    let self = this;
      this.listContacts({"limit": 250})

      ///////////////////GET GROUP ////////////////////////////
      let groupList = await ChatService.getGroup();
      this.setState({groupList : groupList.rows})


      /////////////////////////// PUBLIC //////////////////////////////////////////

        this.setState(previousState => {
          return {
            messages: GiftedChat.append(previousState.messages, this.props.publicMessage)
          };
        });
        let publicMessage = this.props.publicMessage;
        for(let i=0; i<publicMessage.length; i++)
        {
          if(publicMessage[i].read==false && publicMessage[i].mention && publicMessage[i].mention.user.id == user._id)
          {
            Backend.readPublicMessage(publicMessage[i].id);
          }
          if(publicMessage[i].read==false && publicMessage[i].mentions)
          {
            let  mention = publicMessage[i].mentions.filter(e => (e._id == user._id))
            if(mention != null)
            {
              Backend.readPublicMessage(publicMessage[i].id);
            }
          }
        }
        /*if(this.props.match.params.location == "public")
        {
          this.setState({value: 0});
          if(this.props.curPublic)
          {
            console.log("hereeee! : ",this.props.curPublic);
            scroll.scrollTo(this.props.curPublic.id,{
              duration: 800,
              delay: 0,
              smooth: 'easeInOutQuart'
            })
          }
        }*/
        if(this.props.match.params.location == "group")
        {
          this.setState({value : 1});
          if(this.props.curGroup.group)
          {
            this.setState({messageType:"group"})
            this.setState({value:1})
            this.selectGroup(this.props.curGroup.group)
          }
          else if(this.props.curGroup.password)
          {
            this.setState({messageType:"group"})
            this.setState({value:1})
          }
          else
          {
            this.setState({value: 1})
            this.props.history.push("/chat")
          }
        }
        if(this.props.match.params.location == "private")
        {
          this.setState({value : 2});
          if(this.props.curPrivate.header)
          {
            this.setState({messageType:"private"})
            this.selectPrivateChat(this.props.curPrivate);
          }
          else if(this.props.curPrivate.password)
          {
            this.setState({messageType:"private"})
            this.setState({value:2})
            let header = {
              createdAt : "",
              idUser: this.props.curPrivate._id,
              lastMessage : "",
              userName : this.props.curPrivate.firstname + ' ' + this.props.curPrivate.lastname
            }
            this.selectPrivateChat({header : header});          
          }
          else
          {
            this.setState({value: 0})
            this.props.history.push("/chat")
          }
        }

        /////////////GROUP////////////////////////

        
      /*Backend.initFire(this.state.idToken);
      Backend.loadDiscussion().then((data)=>{
        self.setState({dataMessage:data})
      })

      Backend.loadMessages(message => {
          

          this.setState(previousState => {
            
            message.user._id = message.user.id ? message.user.id : (message.user._id ? message.user._id : null);
            let user = null//self.props.users.find((item) => { return message.user ? item._id == message.user._id : false});
            if(user && user.avatar && message.user){
                  message.user.avatar = user.avatar;
            }
            if(message.user.id){
              return {
                messages: GiftedChat.append(previousState.messages, message)
              };
            }

          });
      },{
        id:this.state.idToken,
        name:`${this.state.firstnameToken} ${this.state.lastnameToken}`
      });

      await Backend.sendNameMessageGroup(this.state.idToken);
      const dataGroup = await Backend.loadTablesMessage();
      if(dataGroup){
          this.setState({
              dataGroup: dataGroup,
              dataGroupInit: dataGroup
          })
        this.setState({isLoading : false});
      }


    /*if(this.props.match.params.location)
    {
      if(this.props.match.params.location == "public")
      {
        this.setState({messageType:"public"})
        let publicMessage = this.props.publicMessage;
        for(var i=0; i<publicMessage.length; i++)
        {
          if(publicMessage[i].read==false && publicMessage[i].mention && publicMessage[i].mention.user.id == user._id)
          {
            Backend.readPublicMessage(publicMessage[i].id);
          }
        }

     
          const action = { type: "SET_PUBLIC", value: [] }
          this.props.dispatch(action);
        

        this.props.history.push("/chat");
        this.setState({value: 0});
      }
      if(this.props.match.params.location == "group")
      {
        console.log(this.props.curGroup)
        if(this.props.curGroup.group)
        {
          this.setState({messageType:"group"})
          this.setState({value:1})
          this.selectGroup(this.props.curGroup.group)
        }
        else if(this.props.curGroup.password)
        {
          this.setState({messageType:"group"})
          this.setState({value:1})
        }
        else
        {
          this.props.history.push("/chat");
          
        }
      }
      if(this.props.match.params.location == "private")
      {
        
        if(this.props.curPrivate.header)
        {
          this.setState({messageType:"private"})
          this.setState({value:2})
          this.selectPrivateChat(this.props.curPrivate.header);
        }
        else if(this.props.curPrivate.password)
        {
          this.setState({messageType:"private"})
          this.setState({value:2})
          let header = {
            createdAt : "",
            idUser: this.props.curPrivate._id,
            lastMessage : "",
            userName : this.props.curPrivate.firstname + ' ' + this.props.curPrivate.lastname
          }
          this.selectPrivateChat(header);          
        }
        else
        {
          this.props.history.push("/chat");
        }
       
        
      }
    }
    else*/
    {

      /*let publicMessage = this.props.publicMessage;
        for(var i=0; i<publicMessage.length; i++)
        {
          if(publicMessage[i].read==false && publicMessage[i].mention && publicMessage[i].mention.user.id == user._id)
          {
            Backend.readPublicMessage(publicMessage[i].id);
          }
        }*/

    }
  }


  componentWillReceiveProps(nextProps)
  {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if(nextProps != this.props)
    {
       //////////PUBLIC///////////////////////////
      this.setState({messages : []},()=>{
        this.setState(previousState => {
          return {
            messages: GiftedChat.append(previousState.messages, nextProps.publicMessage)
          };
        });
      })
      let publicMessage = this.props.publicMessage;
      for(let i=0; i<publicMessage.length; i++)
      {
        if(publicMessage[i].read==false && publicMessage[i].mention && publicMessage[i].mention.user.id == user._id)
        {
          Backend.readPublicMessage(publicMessage[i].id);
        }
        if(publicMessage[i].read==false && publicMessage[i].mentions)
        {
          let  mention = publicMessage[i].mentions.filter(e => (e._id == user._id))
          if(mention != null)
          {
            Backend.readPublicMessage(publicMessage[i].id);
          }
        }
      }

       /////////////GROUP////////////////////////
      let group = nextProps.groupList.find(e => e.id == this.state.curGroup.id);
      //this.setState({curGroup: this.props.groupList})
      if(group )
      {
        let array = group.messages.filter(e => e.archived == false)
        array.sort(function(a,b){
          let _a = Date.parse(a.createdAt);
          let _b = Date.parse(b.createdAt)
          return _b - _a;
        })
        this.setState({groupMessages : []},()=>{
          this.setState(previousState => {
            return {
              groupMessages: GiftedChat.append(previousState.groupMessages, array),
            };
          });
        })
      }

      //////////PRIVATE //////////////////////////
      let privateList = nextProps.privateList.find(e => e.header.idUser == this.state.curPrivateChat.idUser)
      if(privateList)
      {
         let array = [];
        for(var item in privateList.messageDiscus)
        {
          privateList.messageDiscus[item].id = item;
          privateList.messageDiscus[item].text = privateList.messageDiscus[item].message;
          array.push(privateList.messageDiscus[item]);
        }
        array = array.filter(e => e.archived == false)
        array.sort(function(a,b){
          let _a = Date.parse(a.createdAt);
          let _b = Date.parse(b.createdAt)
          return _b - _a;
        })
        this.setState({dataPrivateMess : []},()=>{
          this.setState(previousState => {
            return {
              dataPrivateMess: GiftedChat.append(previousState.dataPrivateMess, array)
            };
          });
        })
      }
      if(nextProps.match.params.location == "group")
        {
          this.setState({value : 1});
          if(this.props.curGroup.group)
          {
            this.setState({messageType:"group"})
            this.setState({value:1})
            this.selectGroup(this.props.curGroup.group)
          }
          else
          {
            this.setState({value: 1})
            this.props.history.push("/chat")
          }
        }
        if(nextProps.match.params.location == "private")
        {
          this.setState({value : 2});
          if(this.props.curPrivate.header)
          {
            this.setState({messageType:"private"})
            this.selectPrivateChat(this.props.curPrivate);
          }
          else
          {
            this.setState({value: 2})
            this.props.history.push("/chat")
          }
        }     
    }
  }


  buttonSend(props){
    return (
           <Button onClick={()=>{

             Backend.sendSingle({
               text:props.text,
               user:props.user
             })
             props.self.setState({text:''})
         }}
           style={{color:"blue",}}>
          <Icon>send</Icon>
         </Button>
    )
  }
  renderBubble(props){
    let self = props.self;
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    return (
      <div style={{display:"flex", flexDirection: "row", alignItems: "center"}}>
      
      <div style={{display: "flex", flexDirection: "column"}}>
        {props.currentMessage.mention ?
        (
          props.currentMessage.user.id == user._id ?
          ( 
            <div id={props.currentMessage.id} style={{background: "#B7F9D8", borderBottomLeftRadius: "16px", borderBottomRightRadius: "16px", borderTopLeftRadius: "16px", display: "flex", flexDirection: "column"}}>
              <div style={{transform: "scaleX(-1)", marginLeft: "16px", marginTop: "16px", width: "16px", height: "16px"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18"><path style={{fill:'#2d6970'}}  d="M10 5v5h2.75L11 13h2.25L15 10V5h-5zm-7 5h2.75L4 13h2.25L8 10V5H3v5z" /></svg>  
              </div>
              <div style={{color: "#2d6970", marginLeft: "32px", marginTop: "8px" ,fontStyle : "italic"}}>{props.currentMessage.mention.text}</div>
              <p style={{marginLeft: "32px", marginTop :"8px", color:"#2d6970", marginRight: "32px", fontWeight: "500"}}>{props.currentMessage.mention.user.name} - {this.dateConversion(props.currentMessage.mention.createdAt)}</p>
              <div style={{border: "0.5px solid #2d6970", marginLeft: "24px", marginRight: "24px"}}></div>
              <Bubble {...props}

              wrapperStyle={{
                  right: {
                    backgroundColor: '#B7F9D8',
                    width: "90%"
                  },
                  left: {

                    width: "90%"
                  },
                }}
                textStyle={{
                    right: {
                      color: '#2D6970',
                      
                    },
                    left: {
                      color: '#2D6970',
                    },

                  }}


                />
            </div>
          )
          :
          (
            <div id={props.currentMessage.id} style={{background: "#F0F0F0", borderBottomLeftRadius: "16px", borderBottomRightRadius: "16px", borderTopRightRadius: "16px",  display: "flex", flexDirection: "column"}}>
            <div style={{transform: "scaleX(-1)", marginLeft: "16px", marginTop: "16px", width: "16px", height: "16px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18"><path style={{fill:'#2d6970'}}  d="M10 5v5h2.75L11 13h2.25L15 10V5h-5zm-7 5h2.75L4 13h2.25L8 10V5H3v5z" /></svg>  
            </div>
            <div style={{color: "#2d6970", marginLeft: "32px", marginTop: "8px" ,fontStyle : "italic"}}>{props.currentMessage.mention.text}</div>
            <p style={{marginLeft: "32px", marginTop :"8px", color: "#2d6970", fontWeight: "500", marginRight: "32px"}}>{props.currentMessage.mention.user.name} - {this.dateConversion(props.currentMessage.mention.createdAt)}</p>
            <div style={{border: "0.5px solid #2d6970", marginLeft: "24px", marginRight: "24px"}}></div>
            <Bubble {...props}

            wrapperStyle={{
                right: {
                  backgroundColor: '#B7F9D8',
                  width: "90%"
                },
                left: {

                  width: "90%"
                },
              }}
              textStyle={{
                  right: {
                    color: '#2D6970',
                  },
                  left: {
                    color: '#2D6970'
                  },

                }}
              />
          </div>
          )
        )
        : 
        (!props.currentMessage.isTestUser ?
          (
          <div id={props.currentMessage.id}>
            <Bubble {...props}

            wrapperStyle={{
                right: {
                  backgroundColor: '#B7F9D8',
                  width: "90%"
                },
                left: {

                  width: "90%"
                },
              }}
              textStyle={{
                  right: {
                    color: '#2D6970',
                  },
                  left: {
                    color: '#2D6970',
                  },

                }}


              />
          </div>
          )
          :
          (
            <div id={props.currentMessage.id}>
            <Bubble {...props}

            wrapperStyle={{
                right: {
                  backgroundColor: '#ffc107',
                  width: "90%"
                },
                left: {

                  width: "90%"
                },
              }}
              textStyle={{
                  right: {
                    color: '#2D6970',
                  },
                  left: {
                    color: '#2D6970',
                  },

                }}


              />
            </div>
          )
        )
        }
        
      </div>
        {this.state.messageType != "private" || props.currentMessage.user.id == user._id ? (
         <Button
            onClick={()=>{
              this.setState({modalIsOpen: true});
              let data= props.currentMessage;
              delete data.group;
              delete data.messageDiscus;
              let currentMessage = JSON.stringify(data);
              this.setState({item: currentMessage});
              
            }}
          >
            <MoreVertIcon style={{color: "white"}}/>
          </Button>
        )
        :
        (<div></div>)}
        
      </div>)
  }


  addMentions(id,display)
  {
    let old = this.state.mentionList;
    if(!old.includes(item => item.id == id))
      old.push({id: id, display: display})
    this.setState({mentionList: old})
  }
  renderTagSuggestion (entry, search, highlightedDisplay, index, focused)
  {
    return <div style={{color:"#00796b", marginLeft: "8px", marginTop: "8px", fontWeight: "600"}}>{highlightedDisplay}</div>
  }
  displayTransform (id, display)
  {
    return "@"+display
  }
  renderInputToolbar(props){
    let self = props.self;
    let mention = this.state.mention
    return (
      <div className={self.classes.inputFixed}>
        <Button onClick={()=>{
              /*props.self.setState({messages:[]});
              Backend.loadMessages(message => {


                  props.self.setState(previousState => {
                    if(message.user.id){
                      return {
                        messages: GiftedChat.append(previousState.messages, message)
                      };
                    }

                  });


              },{
                id:self.state.idToken,
                name:`${self.state.firstnameToken} ${self.state.lastnameToken}`
              });*/
            }}
           className={this.classes.buttonRefresh}>
            <Icon>replay</Icon>
        </Button>
        <div className = {self.classes.textField} style={{marginLeft: "64px", paddingRight: "0"}}>
            {mention ? (
            <div style={{minHeight: "64px", background : "#f4f5f9", width: "100%", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", color: "#a2a5a9"}}>
              <div style ={{display: "flex", flexDirection: "row"}}>
              <div style={{transform: "scaleX(-1)"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 18 18"><path style={{fill:'#a2a5a9'}}  d="M10 5v5h2.75L11 13h2.25L15 10V5h-5zm-7 5h2.75L4 13h2.25L8 10V5H3v5z" /></svg>  
              </div>
                           
              <p style={{marginLeft: "16px", fontStyle: "italic"}}>{this.state.mention.text} :</p>
              <CloseIcon onClick={()=> this.setState({mention: null})} style={{marginLeft: "auto", marginRight:"40px", marginTop : "8px", color :"#a2a5a9"}}/>
            </div>
            <p style={{marginLeft: "64px", marginTop :0}}>{this.state.mention.user.name} - {this.dateConversion(this.state.mention.createdAt)}</p>
              
            </div>):(<div></div>)}
            
            <div style= {{display: "flex", flexDirection: "row", paddingRight: "0"}}>
            {/*<TextField
            InputProps={{
              classes: {
                root: self.classes.cssOutlinedInput,
                focused: self.classes.cssFocused,
                notchedOutline: self.classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              classes: {
                root: self.classes.cssLabel,
                focused: self.classes.cssFocused,
              },
            }}
            id="outlined-multiline-static"
            label=""
            multiline
            value={self.state.text}
            onChange={e => self.setState({text:e.target.value })}
            placeholder="Your message"
            style ={{width: "100%", paddingRight:"32px"}}
            margin="none"
            variant="outlined"


          />*/}
          <MentionsInput value={this.state.value} onChange={e => self.setState({text:e.target.value })}
            multiline
            value={self.state.text}
            onChange={e => self.setState({text:e.target.value })}
            placeholder="Your message"
            style ={{width: "100%", paddingRight:"32px", minHeight: "50px",  border: "2px solid #F4F5F9", outline: "none", borderRadius: "6px", background : "white"}}
            classeName = {this.classes.mentions}
            allowSpaceInQuery= {true}
          >
            <Mention
              trigger="@"
              data={this.state.mentionData}
              onAdd={this.addMentions.bind(this)}
              renderSuggestion={this.renderTagSuggestion.bind(this)}
              displayTransform= {this.displayTransform}
              appendSpaceOnAdd = {true}

            />
          </MentionsInput>
          <input id="imageUpload" type="file" onChange={this.imageChange.bind(this)} style={{visibility : "hidden"}}/>
          <label htmlFor="imageUpload" className={self.classes.buttonSend} style={{top: "12px"}} >
            <Icon>add_a_photo</Icon>
          </label>
          <Button onClick={()=>{
              if(self.state.text){
                let mentions = this.verifyMentions(self.state.text);
                Backend.sendSingle({
                  text:mentions.text,
                  user:props.user
                },this.state.mention,mentions.mentions)
                

                props.self.setState({text:''})
                props.self.setState({mention: null})
              }
            }}
            className={self.classes.buttonSend} >
            <Icon>send</Icon>
          </Button>
            </div>
        </div>
        
        </div>
    );
  }

  renderInputToolbarGroup(props){
    let self = props.self;
    let mention = this.state.mention
    return (
      <div className={self.classes.inputFixed}>
      <Button onClick={()=>{
              props.self.setState({groupMessages:[]});

              Backend.sendNameMessageGroupExist(self.state.curGroup.firebaseMessageId, self.state.curGroup.members);

              Backend.loadMessagesGroup( message => {

                    self.setState(previousState => {
                      return {
                        groupMessages: GiftedChat.append(previousState.groupMessages, message)
                      };
                    });
              });
           }}
           className={this.classes.buttonRefresh}>
          <Icon>replay</Icon>
         </Button>
         <div className = {self.classes.textField} style={{marginLeft: "64px", paddingRight: "0"}}>
            {mention ? (
            <div style={{minHeight: "64px", background : "#f4f5f9", width: "100%", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", color: "#a2a5a9"}}>
              <div style ={{display: "flex", flexDirection: "row"}}>
              <div style={{transform: "scaleX(-1)"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 18 18"><path style={{fill:'#a2a5a9'}}  d="M10 5v5h2.75L11 13h2.25L15 10V5h-5zm-7 5h2.75L4 13h2.25L8 10V5H3v5z" /></svg>  
              </div>
                           
              <p style={{marginLeft: "16px", fontStyle: "italic"}}>{this.state.mention.text} :</p>
              <CloseIcon onClick={()=> this.setState({mention: null})} style={{marginLeft: "auto", marginRight:"40px", marginTop : "8px", color :"#a2a5a9"}}/>
            </div>
            <p style={{marginLeft: "64px", marginTop :0}}>{this.state.mention.user.name} - {this.dateConversion(this.state.mention.createdAt)}</p>
              
            </div>):(<div></div>)}
            
            <div style= {{display: "flex", flexDirection: "row", paddingRight: "0"}}>
            <TextField
                InputProps={{
                  classes: {
                    root: self.classes.cssOutlinedInput,
                    focused: self.classes.cssFocused,
                    notchedOutline: self.classes.notchedOutline,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: self.classes.cssLabel,
                    focused: self.classes.cssFocused,
                  },
                }}
                id="outlined-multiline-static"
                label=""
                multiline
                value={self.state.text}
                onChange={e => self.setState({text:e.target.value })}
                placeholder="Your message"
                style ={{width: "100%", paddingRight:"32px"}}
                margin="none"
                variant="outlined"
                style ={{width :"100%"}}
              />
              <input id="imageGroupUpload" type="file" onChange={this.imageGroupChange.bind(this)}style={{visibility : "hidden"}}/>
              <label htmlFor="imageGroupUpload" className={self.classes.buttonSend} style={{top: "12px"}} >
                <Icon>add_a_photo</Icon>
              </label>

                <Button onClick={()=>{
                  var today = new Date();
                  let timestamp = today.toISOString();
                  if(self.state.text ){
                    Backend.sendMessageGroup(this.state.curGroup,{
                      createdAt:timestamp,
                      text:props.text,
                      user:props.user
                    },mention)
                    props.self.setState({text:''})
                    props.self.setState({mention: null})
                  }
                }}
                className={self.classes.buttonSend}>
                <Icon>send</Icon>
              </Button>
            </div>
         </div>
      </div>

    );
  }
  renderInputToolbarPrivate(props){
    let self = props.self;
    let mention = this.state.mention
    return (
      <div className={self.classes.inputFixedPrivate}>
      <Button onClick={()=>{
              /*props.self.setState({dataPrivateMess:[]});
              Backend.initFire(self.state.idToken);
              Backend.loadMessagesFePriv( message => {

                this.setState(previousState => {
                  return {
                    dataPrivateMess: GiftedChat.append(previousState.dataPrivateMess, message)
                  };
                });
          
              });*/
           }}
           className={this.classes.buttonRefresh}>
          <Icon>replay</Icon>
         </Button>
      <div className = {self.classes.textField} style={{marginLeft: "64px", paddingRight: "0"}}>
            {mention ? (
            <div style={{minHeight: "64px", background : "#f4f5f9", width: "100%", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", color: "#a2a5a9"}}>
              <div style ={{display: "flex", flexDirection: "row"}}>
              <div style={{transform: "scaleX(-1)"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 18 18"><path style={{fill:'#a2a5a9'}}  d="M10 5v5h2.75L11 13h2.25L15 10V5h-5zm-7 5h2.75L4 13h2.25L8 10V5H3v5z" /></svg>  
              </div>
                           
              <p style={{marginLeft: "16px", fontStyle: "italic"}}>{this.state.mention.text} :</p>
              <CloseIcon onClick={()=> this.setState({mention: null})} style={{marginLeft: "auto", marginRight:"40px", marginTop : "8px", color :"#a2a5a9"}}/>
            </div>
            <p style={{marginLeft: "64px", marginTop :0}}>{this.state.mention.user.name} - {this.dateConversion(this.state.mention.createdAt)}</p>
              
            </div>):(<div></div>)}
            
            <div style= {{display: "flex", flexDirection: "row", paddingRight: "0"}}>
        <TextField
            InputProps={{
              classes: {
                root: self.classes.cssOutlinedInput,
                focused: self.classes.cssFocused,
                notchedOutline: self.classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              classes: {
                root: self.classes.cssLabel,
                focused: self.classes.cssFocused,
              },
            }}
            id="outlined-multiline-static"
            label=""
            multiline
            value={self.state.text}
            onChange={e => self.setState({text:e.target.value })}
            placeholder="Your message"
            style ={{width: "100%", paddingRight:"32px"}}
            margin="none"
            variant="outlined"

          />
          <input id="imagePrivateUpload" type="file" onChange={this.imagePrivateChange.bind(this)}style={{visibility : "hidden"}}/>
          <label htmlFor="imagePrivateUpload" className={self.classes.buttonSend} style={{top: "12px"}} >
            <Icon>add_a_photo</Icon>
          </label>

            <Button onClick={()=>{

              var today = new Date();
              let timestamp = today.toISOString();
              let messages = {
                  createdAt:timestamp,
                  text:props.text,
                  user:{
                      _id: props.user._id, 
                      name: props.user.name,
                      nameUserChat:self.state.curUserName
                  }
                }
              if(self.state.text) 
              {
                //Backend.initFire(self.state.curIdUser);
                Backend.sendMPBy(self.state.idToken,self.state.curIdUser,messages)/*.then(()=>{
                  console.log("Test send mp by")
                  self.setState({dataMessage:[]})
                  Backend.initFire(self.state.idToken)
                  Backend.loadDiscussion().then((data)=>{
                    console.log("data messages private", data)
                    self.setState({dataMessage:data})
                  })

                });*/
                self.setState({
                  text:''
                })
                props.self.setState({text:''})
              }

            }}
            className={self.classes.buttonSend}>
            <Icon>send</Icon>
          </Button>
          </div>
      </div>
    </div>

    );
  }
  onSend(messages = []) {

    this.setState((previousState) => ({
      messages: GiftedChat.append(previousState.messages, messages),
    }));
  }

  selectPrivateChat(privateChat){
    console.log("received : ",privateChat);
    this.setState({
      text:''
    })
    this.setState({
      curIdUser:privateChat.header.idUser,
      curUserName:privateChat.header.userName,
      curPrivateChat:privateChat.header,
      dataPrivateMess:[]
    });
    //alert(privateChat.idUser)

    /*Backend.loadFireDiscus(privateChat.idUser);

    Backend.initFire(this.state.idToken);
    Backend.loadMessagesFePriv( message => {

        let user = this.props.users.find((item) => message.user ? item._id == message.user._id : false);
          if(user && user.avatar && message.user){
            message.user.avatar = user.avatar;
          }
      this.setState(previousState => {
        return {
          dataPrivateMess: GiftedChat.append(previousState.dataPrivateMess, message)
        };
      });
      if(message.read == false)
      {
        Backend.readPrivateMessage(message);
      }
      
    })*/
    let array = [];
    for(var item in privateChat.messageDiscus)
    {
      let user = this.users.rows.find(i => i._id == privateChat.messageDiscus[item].user._id);
      if(user && user.avatarUrl && privateChat.messageDiscus[item].user)
      {
        privateChat.messageDiscus[item].user.avatar = user.avatarUrl;
      }
      privateChat.messageDiscus[item].id = item;
      privateChat.messageDiscus[item].text = privateChat.messageDiscus[item].message;
      array.push(privateChat.messageDiscus[item]);
    }
    array = array.filter(e => e.archived == false)
    array.sort(function(a,b){
      let _a = Date.parse(a.createdAt);
      let _b = Date.parse(b.createdAt)
      return _b - _a;
    })

    for(let i=0; i<array.length; i++)
    {
      if(array[i].read == false)
      {
        Backend.readPrivateMessage(array[i]);
      }
    }
    this.setState(previousState => {
      return {
        dataPrivateMess: GiftedChat.append(previousState.dataPrivateMess, array)
      };
    });

  }
  async deleteGroup(idToken,nameGroup){
    let success= await  Backend.deleteMessageGroup(idToken,nameGroup)
    if(success.success){
      this.reloadGroup();
      this.setState({groupId:false})
    }
  }
  checkGroup(group)
  {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    console.log("checked group : ",group)
    if(group.members.some(e=> e == user._id))
    {
      if(group.kind == "open")
      {
        this.selectGroup(group)
      }
      else
      {
        this.setState({pwdIsOpen: true, checkGroup : group})
      }
    }
  }
  checkPwd(pwd)
  {
    if(this.state.checkGroup.password == this.state.pwdValue)
    {
      this.selectGroup(this.state.checkGroup)
      this.setState({pwdError :false});
      this.closePwd();
    }
    else
    {
      this.setState({pwdError: true})
    }
  }
  handlePwd($event)
  {
    this.setState({pwdValue: $event.target.value, pwdError: false});
  }
  selectGroup(group){
    const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    console.log("selected groupe : ",group);
    this.setState({groupId : group._id, curGroup: group})


    
    let idTokenMe = localStorage.getItem('idToken');
    this.setState({
      text:''
    })
    this.setState({groupId : group._id , curGroup: group,groupMessages:[]});

    if(this.props.curGroup.password)
    {
      let user = group.users;
      if(!user.includes(this.props.curGroup._id))
      {

        user.push(this.props.curGroup._id)
      }
      Backend.sendNameMessageGroup(idTokenMe);
      Backend.addUserToGroup(
        group,
        this.props.curGroup._id,
        user,
        userLocal._id
     );
    }
    Backend.sendNameMessageGroupExist(group.firebaseMessageId, group.members);


    Backend.loadMessagesGroup( message => {

          let user = this.props.users.find((item) => message.user ? item._id == message.user._id : false);
          if(user && user.avatar && message.user){
            message.user.avatar = user.avatar;
          }
          this.setState(previousState => {
            return {
              groupMessages: GiftedChat.append(previousState.groupMessages, message)
            };
          });
          if(message.read==false && message.mention && message.mention.user.id == user._id)
          {
            Backend.readGroupMessage(this.state.curGroup,message);
          }

    });
    /*let array = group.messages.filter(e => e.archived == false)
    array.sort(function(a,b){
      let _a = Date.parse(a.createdAt);
      let _b = Date.parse(b.createdAt)
      return _b - _a;
    })
    for(let i=0; i<array.length; i++)
    {
      if(array[i].read==false && array[i].mention && array[i].mention.user.id == userLocal._id)
      {
        Backend.readGroupMessage(group,array[i]);
      }
    }
    this.setState(previousState => {
      return {
        groupMessages: GiftedChat.append(previousState.groupMessages, array)
      };
    });*/
  

        
  }
  async verifyUser(id)
  {
    if(await ChatService.getUser(id))
      return true;
    else
    {
      alert('utilisateur non existant')
      return false;
    }
  }

  _setPublicMessage()
  {
    const action = { type: "SET", value: this.state.messages }
    this.props.dispatch(action);
  }

  ediGroup(){
    this.setState({
      openEdit: true
    });
  }
  
  toogleEdit(){
    this.setState({
      openEdit: !this.state.openEdit
    });
  }
  removeMention(text)
  {
    let regex = text.match(/\[([^\]]+)/i);
    return regex.replace('[','@');
  }
  verifyMentions(text)
  {
    let mentions = {};
    let list = [];
    let array = text.match(/@\[([^)]+)\)/g);
    let finalText = "";

    if(array && array.length>0)
    array.forEach((item,array)=>
    {
      let temp = item.match(/\(([^)]+)\)/i)
      let id = temp[0].replace('(','');
      id = id.replace(')','');
      temp = item.match(/\[([^\]]+)\]/i);
      let username = temp[0].replace('[','');
      username = username.replace(']','');
      list.push({_id : id, user : username, read : false});
      finalText += "@"+username + " ";
    })
    mentions.text = finalText + text.replace(/@\[([^)]+)\)/g,'');
    mentions.mentions = list;
    return mentions;
  }

  render(){

    let self = this


    const { anchorEl,open,redirectProfil,id_profil,messageOption,item, modalIsOpen } = this.state;
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const { value } = this.state;
    const idToken  = user._id;
    const firstnameToken  = this.state.firstnameToken;
    const lastnameToken  = this.state.lastnameToken;
    const curGroup = this.state.curGroup;

    

    if(redirectProfil)
    {

      return (<Redirect to={id_profil}/>);
    }

    return (
      <div>
      <MyComponent>
        {/*<Header actualLink="/chat" title="Chat" previous={'/training'}/>*/}
        <Header actualLink="/chat" title = "Chat" previous={'/training'} contact={[]}/>
        
        <main className={this.classes.main} >
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal.bind(this)}
          style={customStyles}
          contentLabel="Example Modal"
          
        >
            {this.isMyMessage(this.state.item) ? 
            (
              <ListItem button onClick={()=>{
                if(this.state.messageType=="public")
                  this.archiveMessage(this); 
                if(this.state.messageType=="group")
                  this.archiveMessageGroup(this.state.item);
                if(this.state.messageType=="private")
                  this.archiveMessagePrivate(this);
                this.closeModal(this)}}>
                <ListItemIcon  >
                <DeleteIcon  />
                </ListItemIcon>
                <ListItemText primary="Supprimer" />
              </ListItem>
                
            ) 
            : 
            (<>
              <ListItem button onClick={()=>{this.answerTo(this); this.closeModal(this)}}>
                <ListItemIcon  >
                  <ReplyIcon />
                </ListItemIcon>
                <ListItemText primary="Répondre" />
              </ListItem>  
              <ListItem button onClick={()=>{this.sendMpTo(this); this.closeModal(this)}}>
                <ListItemIcon  >
                  <MessageIcon />
                </ListItemIcon>
                <ListItemText primary="EnvoyerMP" />
              </ListItem>   
              </>             
            )}
            
        </Modal>
        <Modal
          isOpen={this.state.pwdIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closePwd.bind(this)}
          style={customStyles}
          contentLabel = "Pwd Modal"
        ><div style={{display: "flex", flexDirection : "column" }}>
          <CloseIcon style={{color :"black", marginLeft: "auto",marginRight: "0px"}} onClick={this.closePwd.bind(this)}/>
          <TextField
            placeholder="MOT DE PASSE"
            style={{marginTop: "8px"}}
            name="pwd"
            type="password"
            onChange = {this.handlePwd.bind(this)}
          />
          {this.state.pwdError &&
            <span style={{fontSize: "10px", color: "red"}}>Mot de passe incorrecte</span>
          }
          <Button variant="primary" onClick={this.checkPwd.bind(this)}>Verifier</Button>
          </div>
        </Modal>
          <div className={this.classes.root} >
              <AppBar position="static" color="default" muiTheme={muiTheme} style={{marginBottom: "0"}}>
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  variant="fullWidth"
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                  classes={{ root: this.classes.tabsRoot, indicator: this.classes.tabsIndicator }}
                >
                  <Tab label="Public place" icon={<LocalCafeIcon />} onClick={()=>{this.setState({messageType:"public"}); this.props.history.push("/chat")}} classes={{ root: this.classes.tabRoot, selected: this.classes.tabSelected }}/>

                  <Tab label="Groups" icon={<GroupIcon />} onClick={()=>{this.setState({messageType:"group"});}} classes={{ root: this.classes.tabRoot, selected: this.classes.tabSelected }} />

                  <Tab label="Private messages" icon={<ForumIcon />} onClick={()=>{this.setState({messageType:"private"}); this.props.history.push("/chat")}} classes={{ root: this.classes.tabRoot, selected: this.classes.tabSelected }} />

                </Tabs>
              </AppBar>
              <div>

              {value === 0 && <TabContainer style={{margin:"0",padding: "0"}}>
                <div className={this.classes.container} style={{margin:"0",padding: "0"}}>


                          <div className={this.classes.chat} ref={() => { 
                            if(this.props.curPublic)
                            {
                              let el = document.getElementById(this.props.curPublic.id);
                              if(el)
                                el.scrollIntoView((false));
                            }
                          }} style={{margin:"0px",padding: "0px"}}>
                            <GiftedChat
                              messages={this.props.publicMessage}
                              self={this}
                              renderBubble={this.renderBubble.bind(this)}

                              renderInputToolbar={this.renderInputToolbar.bind(this)}
                              user={{
                                id: user._id, //Backend.getUid(),
                                _id:user._id,
                                name: `${firstnameToken} ${lastnameToken}`,
                              }}
                              onPressAvatar= {async user => {
                                if(await this.verifyUser(user.id) == true)
                                {
                                   this.setState({ redirectProfil: true, id_profil:'/profil/'+ user.id + '/chat' })}}
                                }
                               style={{margin:"0",padding: "0"}}
                      


                            />
                          </div>

                </div>

                </TabContainer>}
              {value === 1 &&
                <TabContainer style={{padding:"0"}} >
                  {!this.state.groupId ?
                    this.state.groupList.map((group,i) => {
                       return <div style={{marginTop: "10px"}}>
                         <Paper className={self.classes.card} elevation={5} >
                             <Grid container alignItems="center" spacing={24} >
                               <Grid xs={2}>
                                 <Fab aria-label="Add" className={self.classes.logo} >
                                   <svg viewBox="0 0 640 512" style={{width: "60%"}}>
                                       <g fill={"#fff"}>
                                           <path d="M320 64c57.99 0 105 47.01 105 105s-47.01 105-105 105-105-47.01-105-105S262.01 64 320 64zm113.463 217.366l-39.982-9.996c-49.168 35.365-108.766 27.473-146.961 0l-39.982 9.996C174.485 289.379 152 318.177 152 351.216V412c0 19.882 16.118 36 36 36h264c19.882 0 36-16.118 36-36v-60.784c0-33.039-22.485-61.837-54.537-69.85zM528 300c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70zm-416 0c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70zm24 112v-60.784c0-16.551 4.593-32.204 12.703-45.599-29.988 14.72-63.336 8.708-85.69-7.37l-26.655 6.664C14.99 310.252 0 329.452 0 351.477V392c0 13.255 10.745 24 24 24h112.169a52.417 52.417 0 0 1-.169-4zm467.642-107.09l-26.655-6.664c-27.925 20.086-60.89 19.233-85.786 7.218C499.369 318.893 504 334.601 504 351.216V412c0 1.347-.068 2.678-.169 4H616c13.255 0 24-10.745 24-24v-40.523c0-22.025-14.99-41.225-36.358-46.567z" />
                                       </g>
                                   </svg>
                                  </Fab>
                               </Grid>
                                <Grid xs={1}></Grid>
                                <Grid xs={6} container onClick={()=> {this.checkGroup(group)}}>
                                  <Grid xs={12}>
                                    <Typography align="left" className={self.classes.titlePrimary} >
                                      {group.name}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} container>
                                     <Grid xs={12}>
                                       <Typography align="left"  className={self.classes.text}>
                                        {group.lastMessage}
                                       </Typography>

                                       <Typography align="left" className={self.classes.text}>
                                        {group.createdAt}
                                       </Typography>


                                     </Grid>
                                  </Grid>
                               </Grid>
                               {!this.isGroupMember(group) ?
                                (<>
                               <Grid xs={2}>
                                  <Fab
                                  onClick={()=> {this.joinGroup(group)}} variant="extended" aria-label="Add" className={this.classes.button}>
                                    JOINDRE GROUPE
                                  </Fab>
                               </Grid>
                               <Grid xs={1}>
                                  <ChevronRightIcon style={{cursor:"pointer"}} className={self.classes.rightIcon}/>
                               </Grid>
                               </>
                                )
                                :
                                (
                                  <>
                                  <Grid xs={2}>
                                  </Grid>
                                  <Grid xs={1}>
                                      <ChevronRightIcon style={{cursor:"pointer"}} className={self.classes.rightIcon}/>
                                   </Grid>
                                   </>
                                )
                              }
                               
                             </Grid>


                    </Paper>
                       </div>
                    }

                  )

                    :
                    <div>
                      <Paper className={self.classes.card} elevation={5}>
                          <Grid container direction="row" alignItems="center" spacing={24}>
                            <Grid xs={2}>
                              <ChevronLeftIcon style={{cursor:"pointer"}} className={self.classes.rightIcon} onClick={() => this.setState({groupId: null})}/>
                            </Grid>
                            <Grid xs={2}>

                              <Fab aria-label="Add" className={self.classes.logo} >
                                <svg viewBox="0 0 640 512" style={{width: "60%"}}>
                                    <g fill={"#fff"}>
                                        <path d="M320 64c57.99 0 105 47.01 105 105s-47.01 105-105 105-105-47.01-105-105S262.01 64 320 64zm113.463 217.366l-39.982-9.996c-49.168 35.365-108.766 27.473-146.961 0l-39.982 9.996C174.485 289.379 152 318.177 152 351.216V412c0 19.882 16.118 36 36 36h264c19.882 0 36-16.118 36-36v-60.784c0-33.039-22.485-61.837-54.537-69.85zM528 300c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70zm-416 0c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70zm24 112v-60.784c0-16.551 4.593-32.204 12.703-45.599-29.988 14.72-63.336 8.708-85.69-7.37l-26.655 6.664C14.99 310.252 0 329.452 0 351.477V392c0 13.255 10.745 24 24 24h112.169a52.417 52.417 0 0 1-.169-4zm467.642-107.09l-26.655-6.664c-27.925 20.086-60.89 19.233-85.786 7.218C499.369 318.893 504 334.601 504 351.216V412c0 1.347-.068 2.678-.169 4H616c13.255 0 24-10.745 24-24v-40.523c0-22.025-14.99-41.225-36.358-46.567z" />
                                    </g>
                                </svg>
                               </Fab>
                            </Grid>
                            <Grid xs={1}>
                            </Grid>
                            <Grid xs={6} container direction="row">
                               <Grid xs={9}>
                                 <Typography align="left" className={self.classes.titlePrimary} style={{marginTop:"0"}}>

                                   {curGroup.name}

                                  </Typography>
                               </Grid>
                               <Grid xs={3}>
                                <div>
                                      <Button
                                        buttonRef={node => {
                                          this.anchorEl = node;
                                        }}
                                        aria-owns={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleToggle}
                                      >
                                        <MoreVertIcon/>
                                      </Button>
                                      <Popper open={open} anchorEl={this.anchorEl} transition disablePortal style={{zIndex: "9"}}>
                                        {({ TransitionProps, placement }) => (
                                          <Grow
                                            {...TransitionProps}
                                            id="menu-list-grow"
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                          >
                                            <Paper >
                                              <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList width={30}>
                                                  {/*<MenuItem >
                                                    <Button
                                                     onClick={()=>{

                                                       self.deleteGroup(self.state.idToken,curGroup.nameGroup)

                                                      }
                                                     }>

                                                      <Icon >delete</Icon>
                                                     </Button>
                                                  </MenuItem>
                                                  <MenuItem button>
                                                      <Button  onClick={()=>{this.ediGroup();}}>
                                                         <Icon style={{position: "relative",top: "0px",fontSize:"24px"}} >edit</Icon>
                                                      </Button>
                                                  </MenuItem>*/}
                                                  <MenuItem>
                                                    <Button  onClick={()=>{this.leaveGroup(this.state.curGroup);}}>
                                                         Quitter Groupe
                                                      </Button>
                                                  </MenuItem>
                                                </MenuList>
                                              </ClickAwayListener>
                                            </Paper>
                                          </Grow>
                                        )}
                                      </Popper>
                                    </div>
                              </Grid>

                            </Grid>


                          </Grid>


                        </Paper>
                      {this.state.openEdit ? <UpdateGroupeSlide group={this.state.curGroup} parent={this} handler={this.toogleEdit.bind(this)} users={this.state.users}/> : "" }
                      <div className={this.classes.container2} >


                                <div className={this.classes.chat} ref={() => 
                                  { 
                                    if(this.props.curGroup)
                                    {
                                      let el = document.getElementById(this.props.curGroup.id);
                                      if(el)
                                        el.scrollIntoView((false));
                                    }
                                  }}>
                                  <GiftedChat
                                    messages={this.state.groupMessages}
                                    self={this}
                                    renderBubble={this.renderBubble.bind(this)}
                                    text={this.state.text}
                                    renderInputToolbar={this.renderInputToolbarGroup.bind(this)}
                                    user={{
                                      id: user._id, //Backend.getUid(),
                                      _id:user._id,
                                      name: `${firstnameToken} ${lastnameToken}`,
                                    }}
                                    onPressAvatar= {async user => {
                                    if(await this.verifyUser(user.id) == true)
                                    {
                                       this.setState({ redirectProfil: true, id_profil:'/profil/'+ user.id + '/chat' })}}
                                    }
                                    />
                                  </div>

                      </div>
                    </div>
                  }






                </TabContainer>}
              {value === 2 && <TabContainer >

                {
                    !this.state.curIdUser &&
                    <Typography align="center" style={{marginTop: "10px"}}>

                      <ChooseFriendSlide users={this.state.users} mereProps={this}/>
                    </Typography>

                  }
                {!this.state.curIdUser ?
                    this.props.privateList.map((privateChat,i) => {
                       return <div>
                         <Paper className={self.classes.card} elevation={5} onClick={()=> this.selectPrivateChat(privateChat)}>
                             <Grid container spacing={24}>
                               <Grid xs={2}>
                                 <Fab aria-label="Add" className={self.classes.logo}>
                                   <svg width={20} height={20} viewBox="0 0 576 512" style={styles.iconSVG}>
                                      <g fill={"#fff"}>
                                          <path d="M224 358.857c-37.599 0-73.027-6.763-104.143-18.7-31.375 24.549-69.869 39.508-110.764 43.796a8.632 8.632 0 0 1-.89.047c-3.736 0-7.111-2.498-8.017-6.061-.98-3.961 2.088-6.399 5.126-9.305 15.017-14.439 33.222-25.79 40.342-74.297C17.015 266.886 0 232.622 0 195.429 0 105.16 100.297 32 224 32s224 73.159 224 163.429c-.001 90.332-100.297 163.428-224 163.428zm347.067 107.174c-13.944-13.127-30.849-23.446-37.46-67.543 68.808-64.568 52.171-156.935-37.674-207.065.031 1.334.066 2.667.066 4.006 0 122.493-129.583 216.394-284.252 211.222 38.121 30.961 93.989 50.492 156.252 50.492 34.914 0 67.811-6.148 96.704-17 29.134 22.317 64.878 35.916 102.853 39.814 3.786.395 7.363-1.973 8.27-5.467.911-3.601-1.938-5.817-4.759-8.459z" />
                                      </g>
                                  </svg>
                                 </Fab>
                               </Grid>
                                <Grid xs={1}></Grid>
                                <Grid xs={8} container >
                                  <Grid xs={12}>
                                    <Typography align="left" className={self.classes.titlePrimary}>
                                      {privateChat.header.userName}
                                     </Typography>
                                  </Grid>
                                  <Grid xs={12} container>
                                     <Grid xs={12}>
                                       <Typography align="left"  className={self.classes.text}>
                                        {privateChat.header.lastMessage}
                                       </Typography>

                                       <Typography align="left" className={self.classes.text}>
                                        {privateChat.header.createdAt}
                                       </Typography>

                                       <Typography  align="left" className={self.classes.text}>

                                       </Typography>
                                     </Grid>


                                  </Grid>

                               </Grid>

                               <Grid xs={1}>

                                     <ChevronRightIcon style={{cursor:"pointer"}} className={self.classes.rightIcon}/>


                               </Grid>
                             </Grid>


                    </Paper>
                       </div>
                    }

                  )

                    :
                    <div>
                      <Paper className={self.classes.card} elevation={5}>
                          <Grid container direction="row" alignItems="justify" spacing={24}>
                            <Grid xs={2}>

                                  <ChevronLeftIcon style={{cursor:"pointer"}} className={self.classes.rightIcon} onClick={() => {this.setState({curIdUser: false}); this.reloadPrivate();}}/>


                            </Grid>

                            <Grid xs={2}>
                              <Fab aria-label="Add" className={self.classes.logo}>
                                <svg width={20} height={20} viewBox="0 0 576 512" style={styles.iconSVG}>
                                  <g fill={"#fff"}>
                                    <path d="M224 358.857c-37.599 0-73.027-6.763-104.143-18.7-31.375 24.549-69.869 39.508-110.764 43.796a8.632 8.632 0 0 1-.89.047c-3.736 0-7.111-2.498-8.017-6.061-.98-3.961 2.088-6.399 5.126-9.305 15.017-14.439 33.222-25.79 40.342-74.297C17.015 266.886 0 232.622 0 195.429 0 105.16 100.297 32 224 32s224 73.159 224 163.429c-.001 90.332-100.297 163.428-224 163.428zm347.067 107.174c-13.944-13.127-30.849-23.446-37.46-67.543 68.808-64.568 52.171-156.935-37.674-207.065.031 1.334.066 2.667.066 4.006 0 122.493-129.583 216.394-284.252 211.222 38.121 30.961 93.989 50.492 156.252 50.492 34.914 0 67.811-6.148 96.704-17 29.134 22.317 64.878 35.916 102.853 39.814 3.786.395 7.363-1.973 8.27-5.467.911-3.601-1.938-5.817-4.759-8.459z" />
                                  </g>
                                </svg>
                               </Fab>
                            </Grid>
                            <Grid xs={1}></Grid>
                             <Grid xs={6} container >
                               <Grid  style={{alignSelf:"center"}} xs={12}>
                                 <Typography align="left"style={{margin:"0"}} className={self.classes.titlePrimary}>
                                   {this.state.curUserName}
                                  </Typography>
                               </Grid>



                            </Grid>


                          </Grid>


                        </Paper>
                      <div className={this.classes.container2}  >


                                <div className={this.classes.chat} ref={() => 
                                  {
                                    if(this.props.curPrivate)
                                    {
                                      let el = document.getElementById(this.props.curPrivate.id);
                                      if(el)
                                        el.scrollIntoView((false));
                                    } 
                                  }}>
                                  <GiftedChat
                                    messages={this.state.dataPrivateMess}
                                    self={this}
                                    renderBubble={this.renderBubble.bind(this)}
                                    text={this.state.text}
                                    renderInputToolbar={this.renderInputToolbarPrivate.bind(this)}
                                    user={{
                                      id: user._id, //Backend.getUid(),
                                      _id:user._id,
                                      name: `${firstnameToken} ${lastnameToken}`,
                                    }}
                                    onPressAvatar= {async user => {
                                    if(await this.verifyUser(user.id) == true)
                                    {
                                       this.setState({ redirectProfil: true, id_profil:'/profil/'+ user.id + '/chat' })}}
                                    }
                                    />
                                  </div>

                      </div>


                    </div>
                  }




              </TabContainer>}
              </div>
            </div>

        </main>
        </ MyComponent>
      </div>

    );
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Chat)));
