import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import logo from './../../assets/images/home/logo.jpeg';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import cookie from 'react-cookies'
import {
  Redirect, withRouter
} from 'react-router-dom';
const styles = {
  root:{
    color:"#ffd7b0"
  },
  paper: {
    background:"linear-gradient(#3B8598, #54AC7D )",
  },
  colorTitle:{
    color:"#ffd7b0 !important"
  },
  list: {
    width: 250,
    padding:0,
    // borderBottom:"outset 0.5px #42868b",
    borderTop:"outset 0.5px #6ab1a4"
  },
  fullList: {
    width: 'auto',
  },
  link:{
    textDecoration:"none !important",
    borderStyle: "solid 1px black",
    color:"#fffff !important"
  },
  logo:{
    width:"auto",
    margin:"0 auto",
    width:"72%",
    // border:"solid #6aad95 2px",
    boxShadow: "0px 3px 3px #291f1f"
  },

  icon:{
    color:"#fffff"
  },
  nested: {
    paddingLeft: "40px",
  },
  menuButton : {
    fontSize: '32px',
    marginRight: '12px',
    fontWeight: '800'
  }
};

const  urlPrev = window.location.href;
const encUrl = encodeURIComponent(urlPrev);
class SwipeableTemporaryDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
    open: false,
    redirect : false,
    redirectProfil : false
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
  logout() {
    this.setState({ redirect: true });
  }
  gotoProfil() {
    this.setState({ redirectProfil: true });
  }

  render() {
    //console.log(this.props.actualLink)
    const redirect = this.state.redirect;
    const redirectProfil = this.state.redirectProfil;
    const { classes } = this.props;
    const flexContainer = {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
    }
    const icon ={
      color:"#3c8799",
      margin:"auto",
      verticalAlign:"middle",

    }
    const adminButton={
      textAlign:"center",
      background:"#acd7c2",
      borderLeft:"outset 0.5px #c0e1d0",
      borderRight:"outset 0.5px #c0e1d0",
      padding:"auto"
    }
    const sideList = (
      <div className={classes.list}>
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </div>
    );
    const sideContact = (
      <div className={classes.list}>
        <List>
          <ListItem button key='contact'>
              <ListItemIcon>
                <svg fill={this.props.actualLink != "/contact"?'#FFFFFF' : '#59e099'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 0H4v2h16V0zM4 24h16v-2H4v2zM20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 2.75c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25S9.75 10.24 9.75 9 10.76 6.75 12 6.75zM17 17H7v-1.5c0-1.67 3.33-2.5 5-2.5s5 .83 5 2.5V17z"/></svg>


              </ListItemIcon>

              <ListItemText
               primary={<Typography  style={this.props.actualLink != "/contact"?{ color: '#FFFFFF' }: {color:'#59e099'}}>MES CONTACTS</Typography>}
              />
           </ListItem>
        </List>
      </div>
    );
    const sideConnection = (
      <div className={classes.list}>
        <List>
          <ListItem button key='connection'>
              <ListItemIcon>
              <svg fill={this.props.actualLink != "/chat"?'#FFFFFF' : '#59e099'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/></svg>
            
              </ListItemIcon>
              <ListItemText
               primary={<Typography  style={this.props.actualLink != "/chat"?{ color: '#FFFFFF' }: {color:'#59e099'}}>MES CONNECTIONS</Typography>}
              />
           </ListItem>
        </List>
      </div>
    );
    const sideAction = (
      <div className={classes.list}>
        <List>
          <ListItem button key='action'>
              <ListItemIcon><Icon style={{ color: '#FFFFFF' }}>swap_horiz</Icon></ListItemIcon>
              <ListItemText
               primary={<Typography  style={{ color: '#FFFFFF' }}>MES ACTIONS</Typography>}
              />
           </ListItem>
        </List>
      </div>
    );
    const sideImg = (
      <div className={classes.list}>
        <List>
          <ListItem button key='img'>
              <img src={logo} alt="Logo" className={classes.logo}/>
           </ListItem>
           <ListItemText
               primary={<Typography className={classes.colorTitle}  style={{display:"block",textAlign:"center",color:"white"}}>{localStorage.getItem("lastTraining")?localStorage.getItem("lastTraining"):""}</Typography>}
           />
        </List>


      </div>
    );
    const sideFormation = (
      <div className={classes.list}>
        <List>
          <ListItem button key='formation'>
              <ListItemIcon>
                  <svg fill={this.props.actualLink != "/training"? '#FFFFFF' : '#59e099'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"/></svg>

              </ListItemIcon>
               <ListItemText
               primary={<Typography  style={this.props.actualLink != "/training"?{ color: '#FFFFFF' }: {color:'#59e099'}}>MES FORMATIONS</Typography>}
              />
           </ListItem>
        </List>
      </div>
    );
    const sideListFormation = (
      <div className={classes.list}>
       <List>
        <ListItem button onClick={this.handleClick}>
          {this.state.open ?
            <svg fill={ '#FFFFFF' } xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/></svg>

             :
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 18 18"><path d="M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z"/></svg>}
        <ListItemText inset primary={<Typography  style={{ color: '#FFFFFF' }}>Liste de Formations</Typography>}  />
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon style={{ color: '#FFFFFF' }}>
                <StarBorder />
              </ListItemIcon>
              <ListItemText inset primary={<Typography  style={{ color: '#FFFFFF' }}>Stared</Typography>} />
            </ListItem>
          </List>
        </Collapse>
        </List>
      </div>
    );
    const sideAdmin = (
      <div className={classes.list}>
       <List style={flexContainer}>
          <ListItem onClick={()=>{
              this.setState({ redirectProfil: true });
           }}
           alignItems="center" style={adminButton}
            button >
              <svg style={{overflow:"hidden",margin:"auto"}} fill="#3c8799" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18"><path d="M9 8c1.66 0 2.99-1.34 2.99-3S10.66 2 9 2C7.34 2 6 3.34 6 5s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V16h14v-2.5c0-2.33-4.67-3.5-7-3.5z"/></svg>
          </ListItem>
          <ListItem alignItems="center" style={adminButton}
            button>
            <svg style={{overflow:"hidden",margin:"auto"}} fill="#3c8799" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>

          </ListItem>
          <ListItem onClick={()=>{
              localStorage.removeItem('user');
              cookie.remove('user', { path: '/' })
              this.setState({ redirect: true });
           }}
            alignItems="center" style={adminButton}
            button
          >
            <svg style={{overflow:"hidden",margin:"auto"}} fill="#3c8799" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 10v-5l8 7-8 7v-5h-8v-4h8zm-16-8v20h14v-2h-12v-16h12v-2h-14z"/></svg>

          </ListItem>
      </List>
      </div>
    );
    if(redirect)
    {
      return (<Redirect to='/'/>);
    }
    if(redirectProfil)
    {
      return (<Redirect to='/profil'/>);
    }
    return (

      <div>

        <IconButton onClick={this.toggleDrawer('left', true)} className={classes.menuButton} color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer

          classes={{ paper: classes.paper }}
          open={this.state.left}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}

        >

            <Link to="/" className={classes.link} >
              {sideImg}
            </Link>

            <Link to="/training" className={classes.link} style={{marginTop: "30%",textDecoration:"none",borderBottom : "outset 0.5px #6ab1a4"}}>
              {sideFormation}
            </Link>

            <Link to="/chat" className={classes.link} style={{textDecoration:"none",borderBottom : "outset 0.5px #6ab1a4"}}>
              {sideConnection}
            </Link>
            <Link className={classes.link} style={{textDecoration:"none", borderBottom : "outset 0.5px #6ab1a4"}} to ={'/contact/2'}>
              {sideContact}
            </Link>
            <div style={{position: "absolute" , top: "auto", bottom: 0}}>
              {sideListFormation}
              {sideAdmin}
            </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

SwipeableTemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SwipeableTemporaryDrawer));
