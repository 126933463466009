import React from 'react';
import Image from 'react-native';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import Icon from '@material-ui/core/Icon';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { createMuiTheme } from '@material-ui/core/styles';
import imageHeader from './../../assets/images/home/img-step.jpeg';
import logo from './../../assets/images/home/logo.jpeg';
import femme from './../../assets/images/avatar/profile.png';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import URL_API_base from '../../path';
import ChatService from "./Chat.service.js";
import config from '../../path';
import SendIcon from '@material-ui/icons/Send';
import PhoneIcon from '@material-ui/icons/Phone';
import LabelIcon from '@material-ui/icons/Label';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Header from '../Layout/Header';

import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';

import MessagesGroup from './MessagesGroup';
const theme = createMuiTheme({
  palette: {
    primary: { light: teal[300], main: teal[500], dark: teal[700] },
  },
  typography: {
    useNextVariants: true,
  },

});
const styles = theme =>  ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    //paddingTop: "30px",
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    // paddingBottom: theme.spacing.unit * 8
  },
  card: {
    // width: "100%",
    // marginTop: theme.spacing.unit * 2,
    padding: "10px",
    borderRadius: "12px",
    marginBottom:"30px !important"
  },

  logo: {
    width: "70%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius:"50%",
    border: "2px solid #6dd49d",
    boxShadow: "0px 7px 10px #aaa",
    marginTop: "25px",
    marginBottom: "25px"
  },
  center:{
    textAlign: 'center',
    marginTop: 0
  },
  list: {
    width: "auto",
    padding:0,
    borderBottom:"outset 1.5px #42868b",
    borderTop:"outset 1.5px #6ab1a4"
  },
  buttonSecond:{
    color: "#72E297",
    backgroundColor: "white",
    width:"300px",
    border: "2px solid #72E297",
    fontWeight: "bold",
    textDecoration: "none"
  },
  button:{
    color:"white",
    background: "linear-gradient(60deg,#429399, #6CD898)",
    width:"300px",
    fontWeight: "bold"
  },
  margin:{
    marginRight: 10,
    marginLeft: 10
  },
  link:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
    marginRight: "20px"
  },
  logout:{
    color: "white",
    fontWeight: "bold",
    textDecoration: "underline",
  },




  loading:{
    textAlign: 'center',
    marginTop: "190px",
    height: "300px"
  },
  circularProgress:{
    color: teal[300]
  },
  title:{
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  submit:{
    textAlign: 'center',
    marginTop: "20px",
  },
  icon:{
    position: "relative",
    top: "5px",
    marginRight: "5px",
    color: "#72E299"
  },
  error:{
    color: "#FF0000 !important",
    textAlign: "center"
  },
  titlePrimary:{
    color: "#70DF9D",
    fontSize: "1.2em",
    marginRight: "20px",
    marginTop: "15px",
    fontWeight: "bold"
  },

  text:{
    fontSize : "14px",
    color : '#3E8090',
    textTransform: "uppercase"
  },
  footer:{
    position: "relative",
    bottom: "0"
  },
  right:{
    textAlign:"right"
  },
  info:{
    backgroundColor: "#3B808E",
    color:"white",
    marginRight: "10px"
  },
  buttonSecondary:{
    backgroundColor: amber[500],
    color:"white"
  },
  buttonPlay:{
    backgroundColor: "#65C66A",
    color:"white"
  },
  iconMenu:{
    color: "#767676"
  }
});

class ChatGroup extends React.Component {
  
  static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.classes =  props.classes;
    this.state = {
      isLoading: true,
      contacts:[],
      training: {},
      anchorEl: null,
      groupParams:{}
    };
    console.log("main %o",this.classes);
  }

  /**
   * list TrainingDetail init
   */
  componentWillMount() {
    this.setState({
      messages: [
        {
          id: 1,
          text: 'Hello developer',
          createdAt: new Date(),
          user: {
            id: 2,
            name: 'React',
            avatar: 'https://facebook.github.io/react/img/logo_og.png',
          },
        },
      ],
    });
  }
 
  
  componentDidMount(){
    console.log("nom Groupe",this.props.match.params.nomGroupe);
    console.log("nom message",this.props.match.params.nameMessage);
    console.log("users",JSON.parse(this.props.match.params.users));
    console.log("idcreata",this.props.match.params.idcreata);
    let groupParams ={
      nameGroup:this.props.match.params.nomGroupe,
      nameMessage:this.props.match.params.nameMessage,
      users:JSON.parse(this.props.match.params.users),
      idcreata:this.props.match.params.idcreata
    }
    //localStorage.setItem("groupParams",JSON.stringify(groupParams))
    localStorage.setItem('groupParams', JSON.stringify(groupParams));
    console.log("seting params")
    this.setState({
      groupParams:groupParams
    })
    this.listContacts({
      "searchValue": localStorage.getItem('user')?JSON.parse(localStorage.getItem('user'))._id:null,
      "searchFields": [
        "userId"
      ]
    })
  }
  deleteCt(id){

  ChatService.deleteContact(id).then(function(){
     this.listContacts({
      "searchValue": localStorage.getItem('user')?JSON.parse(localStorage.getItem('user'))._id:null,
      "searchFields": [
        "userId"
      ]
  })
  })
}
componentDidUpdate(prevProps, prevState){
  this.listContacts({
      "searchValue": localStorage.getItem('user')?JSON.parse(localStorage.getItem('user'))._id:null,
      "searchFields": [
        "userId"
      ]
  })

  if (prevProps.data !== this.props.data) {

  }

}
  /**
     * list Training
     * @param {*} data
     */
    async listContacts(data){
        try{
        const responseJson = await ChatService.getContacts(data);
        console.log("responseJson %o", responseJson);
        if(responseJson){
            this.setState({
                contacts: responseJson.rows,
            }, function(){
              console.log("contacts %o",this.state.contacts)
          });
        }
        } catch (e) {
        console.error(e);
      }
    }

    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };


  render(){
    console.log("user %o",localStorage.getItem('user'));
    let self = this
    const { anchorEl } = this.state;
    
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const flexContainer = {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
    }
    const icon ={
      color:"#c3dbdd",
      margin:"auto",
      verticalAlign:"middle"
    }
    const adminButton={
      background:"#85b4bf",
      borderLeft:"outset 1.5px #c0e1d0",
      borderRight:"outset 1.5px #c0e1d0"
    }
    const sideAdmin = (
      <div className={this.classes.list}>
       <List style={flexContainer}>
          <ListItem style={adminButton}
            button>
            <ListItemIcon>
              <SvgIcon width={20} height={20} viewBox="0 0 640 512" style={icon}>
                
                <path d="M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z" />
               
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
               primary={<Typography  style={{ color: '#FFFFFF' }}>Public place</Typography>} 
              /> 
          </ListItem>
          <ListItem style={adminButton}
            button>
            <ListItemIcon>
                <SvgIcon width={20} height={20} viewBox="0 0 640 512" style={icon}>
                
                <path d="M320 64c57.99 0 105 47.01 105 105s-47.01 105-105 105-105-47.01-105-105S262.01 64 320 64zm113.463 217.366l-39.982-9.996c-49.168 35.365-108.766 27.473-146.961 0l-39.982 9.996C174.485 289.379 152 318.177 152 351.216V412c0 19.882 16.118 36 36 36h264c19.882 0 36-16.118 36-36v-60.784c0-33.039-22.485-61.837-54.537-69.85zM528 300c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70zm-416 0c38.66 0 70-31.34 70-70s-31.34-70-70-70-70 31.34-70 70 31.34 70 70 70zm24 112v-60.784c0-16.551 4.593-32.204 12.703-45.599-29.988 14.72-63.336 8.708-85.69-7.37l-26.655 6.664C14.99 310.252 0 329.452 0 351.477V392c0 13.255 10.745 24 24 24h112.169a52.417 52.417 0 0 1-.169-4zm467.642-107.09l-26.655-6.664c-27.925 20.086-60.89 19.233-85.786 7.218C499.369 318.893 504 334.601 504 351.216V412c0 1.347-.068 2.678-.169 4H616c13.255 0 24-10.745 24-24v-40.523c0-22.025-14.99-41.225-36.358-46.567z" />
               
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
               primary={<Typography  style={{ color: '#FFFFFF' }}>Groups</Typography>} 
              />
          </ListItem>
          <ListItem style={adminButton}
            button>
            <ListItemIcon>
              <SvgIcon width={20} height={20} viewBox="0 0 640 512" style={icon}>
                
                <path d="M224 358.857c-37.599 0-73.027-6.763-104.143-18.7-31.375 24.549-69.869 39.508-110.764 43.796a8.632 8.632 0 0 1-.89.047c-3.736 0-7.111-2.498-8.017-6.061-.98-3.961 2.088-6.399 5.126-9.305 15.017-14.439 33.222-25.79 40.342-74.297C17.015 266.886 0 232.622 0 195.429 0 105.16 100.297 32 224 32s224 73.159 224 163.429c-.001 90.332-100.297 163.428-224 163.428zm347.067 107.174c-13.944-13.127-30.849-23.446-37.46-67.543 68.808-64.568 52.171-156.935-37.674-207.065.031 1.334.066 2.667.066 4.006 0 122.493-129.583 216.394-284.252 211.222 38.121 30.961 93.989 50.492 156.252 50.492 34.914 0 67.811-6.148 96.704-17 29.134 22.317 64.878 35.916 102.853 39.814 3.786.395 7.363-1.973 8.27-5.467.911-3.601-1.938-5.817-4.759-8.459z" />
               
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
               primary={<Typography  style={{ color: '#FFFFFF' }}>Private Message</Typography>} 
              />
          </ListItem>
      </List>  
      </div>
    );
    return (
      <div>
        <Header title="Chat" actualLink={"/chat"} previous={'/training'}/>
        <main className={this.classes.main} theme={theme}>
          <MessagesGroup groupParam={this.state.groupParams}/>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(ChatGroup);
